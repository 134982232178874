export enum Colors {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
}

export enum Icons {
  Alert = 'ALERT',
}
