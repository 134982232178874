import hoistStatics from 'hoist-non-react-statics';
import React, { Component, ComponentType } from 'react';

import { UniqueIdConsumer } from './UniqueIdContext';

export interface WithUniqueIdProps {
  uniqueId: number;
}

function withUniqueId<T>(ComposedComponent: ComponentType<T & WithUniqueIdProps>) {
  class WithUniqueId extends Component<T> {
    static ComposedComponent = ComposedComponent;
    static displayName = `withUniqueId(${ComposedComponent.displayName || ComposedComponent.name})`;

    render() {
      return (
        <UniqueIdConsumer>
          {(uniqueId) => (
            <ComposedComponent
              uniqueId={uniqueId}
              {...this.props}
            />
          )}
        </UniqueIdConsumer>
      );
    }
  }

  return hoistStatics(WithUniqueId, ComposedComponent);
}

export default withUniqueId;
