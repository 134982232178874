export enum Sizes {
  XXXSmall = 'XXXSMALL',
  XXSmall = 'XXSMALL',
  XSmall = 'XSMALL',
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
  XLarge = 'XLARGE',
  XXLarge = 'XXLARGE',
  XXXLarge = 'XXXLARGE',
  Header = 'HEADER',
}
