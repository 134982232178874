export { Themes } from '~tools/react/hocs/withTheme/enums';

export enum Alignments {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum Colors {
  Brand = 'BRAND',
  Danger = 'DANGER',
  Dark = 'DARK',
  Facebook = 'FACEBOOK',
  Info = 'INFO',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum Icons {
  Bell = 'BELL',
  BrandAccess = 'BRAND_ACCESS',
  Check = 'CHECK',
  ChevronDown = 'CHEVRON_DOWN',
  ChevronLeft = 'CHEVRON_LEFT',
  ChevronRight = 'CHEVRON_RIGHT',
  ChevronUp = 'CHEVRON_UP',
  Cross = 'CROSS',
  Download = 'DOWNLOAD',
  Edit = 'EDIT',
  Ellipsis = 'ELLIPSIS',
  ExternalLink = 'EXTERNAL_LINK',
  Facebook = 'FACEBOOK',
  Filter = 'FILTER',
  MagnifyingGlass = 'MAGNIFYING_GLASS',
  Mail = 'MAIL',
  Message = 'MESSAGE',
  Paperclip = 'PAPERCLIP',
  Plus = 'PLUS',
  Twitter = 'TWITTER',
}

export enum Sizes {
  XLarge = 'XLARGE',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  XSmall = 'XSMALL',
}

export enum Styles {
  Fill = 'FILL',
  Link = 'LINK',
  Outline = 'OUTLINE',
}

export enum Types {
  Button = 'BUTTON',
  Reset = 'RESET',
  Submit = 'SUBMIT',
}

export enum Widths {
  Auto = 'AUTO',
  Full = 'FULL',
}
