import React, { ReactNode } from 'react';

import ApartmentStage from '~web-apartment/lib/common/stages/ApartmentStage';

import DashboardStageView from './components/DashboardStageView';

interface Props {
  children?: ReactNode;
}

function DashboardStage(props: Props) {
  return (
    <ApartmentStage
      isProtected
      navigation={{
        layout: ApartmentStage.enums.NavigationLayouts.Dashboard,
      }}>
      <DashboardStageView>
        {props.children}
      </DashboardStageView>
    </ApartmentStage>
  );
}

export default DashboardStage;
