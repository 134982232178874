import loadable from '@loadable/component';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Redirect from '~tools/react/components/utility/Redirect';

export default {
  //
  // Primary marketing routes
  '/': loadable(() => import(/* webpackChunkName: "home" */'~web-apartment/lib/common/scenes/Home')),

  //
  // Public app routes
  '/listing/:slugOrUuid': (props: RouteComponentProps<{ slugOrUuid: string }>) => <Redirect to={`/listings/${props.match.params.slugOrUuid}`} />,
  '/listings/:slugOrUuid': loadable(() => import(/* webpackChunkName: "listing" */'~web-apartment/lib/common/scenes/Listing')),
  '/s/:slug?': loadable(() => import(/* webpackChunkName: "search" */'~web-apartment/lib/common/scenes/Search')),
  '/help': loadable(() => import(/* webpackChunkName: "help" */'~web-apartment/lib/common/scenes/Help')),
  '/help/category/:slugOrUuid': loadable(() => import(/* webpackChunkName: "help-category" */'~web-apartment/lib/common/scenes/HelpCategory')),
  '/help/:slugOrUuid': loadable(() => import(/* webpackChunkName: "help-question" */'~web-apartment/lib/common/scenes/HelpQuestion')),

  //
  // Internal app routes
  '/rentals': loadable(() => import(/* webpackChunkName: "rentals" */'~web-apartment/lib/common/scenes/Rentals')),
  '/rentals/:leaseUuid': (props: RouteComponentProps<{ leaseUuid: string }>) => <Redirect to={`/rentals/${props.match.params.leaseUuid}/overview`} />,
  '/rentals/:leaseUuid/overview': loadable(() => import(/* webpackChunkName: "rental" */'~web-apartment/lib/common/scenes/Rental')),
  '/rentals/:leaseUuid/activity': loadable(() => import(/* webpackChunkName: "rental-activity" */'~web-apartment/lib/common/scenes/Rental/scenes/RentalActivity')),
  '/rentals/:leaseUuid/documents': loadable(() => import(/* webpackChunkName: "rental-documents" */'~web-apartment/lib/common/scenes/Rental/scenes/RentalDocuments')),
  '/rentals/:leaseUuid/documents/:documentUuid': loadable(() => import(/* webpackChunkName: "rental-document" */'~web-apartment/lib/common/scenes/Rental/scenes/RentalDocument')),
  '/rentals/:leaseUuid/payments': loadable(() => import(/* webpackChunkName: "rental-payments" */'~web-apartment/lib/common/scenes/Rental/scenes/RentalPayments')),
  '/rentals/:leaseUuid/issues': loadable(() => import(/* webpackChunkName: "rental-payments" */'~web-apartment/lib/common/scenes/Rental/scenes/RentalIssues')),
  '/rentals/:leaseUuid/rent-payments/:rentPaymentUuid': loadable(() => import(/* webpackChunkName: "rental-rent-payment" */'~web-apartment/lib/common/scenes/Rental/scenes/RentalRentPayment')),
  '/rentals/:leaseUuid/security-deposits/:securityDepositUuid': loadable(() => import(/* webpackChunkName: "rental-security-deposit" */'~web-apartment/lib/common/scenes/Rental/scenes/RentalSecurityDeposit')),

  '/applications': loadable(() => import(/* webpackChunkName: "applications" */'~web-apartment/lib/common/scenes/Applications')),
  '/applications/:applicationUuid': (props: RouteComponentProps<{ applicationUuid: string }>) => <Redirect to={`/applications/${props.match.params.applicationUuid}/overview`} />,
  '/applications/:applicationUuid/:tab/:tabUuid?': loadable(() => import(/* webpackChunkName: "application" */'~web-apartment/lib/common/scenes/Application')),

  '/account/:tab?': loadable(() => import(/* webpackChunkName: "account" */'~web-apartment/lib/common/scenes/Account')),
  '/profile/:tab?/:otherTab?/:otherOtherTab?': loadable(() => import(/* webpackChunkName: "profile" */'~web-apartment/lib/common/scenes/Profile')),
  '/questions/:status?': loadable(() => import(/* webpackChunkName: "questions" */'~web-apartment/lib/common/scenes/Questions')),

  //
  // Authentication routes
  '/login': loadable(() => import(/* webpackChunkName: "login" */'~web-apartment/lib/common/scenes/Login')),
  '/signup': loadable(() => import(/* webpackChunkName: "register" */'~web-apartment/lib/common/scenes/Register')),
  '/reset': loadable(() => import(/* webpackChunkName: "reset-password" */'~web-apartment/lib/common/scenes/ResetPassword')),
  '/reset/:token': loadable(() => import(/* webpackChunkName: "change-password" */'~web-apartment/lib/common/scenes/ChangePassword')),

  //
  // Redirects
  '/invites/:leaseInviteUuid': loadable(() => import(/* webpackChunkName: "lease-invite-redirect" */'~web-apartment/lib/common/scenes/_redirects/LeaseInviteRedirect')),
  '/messages/:threadUuid': loadable(() => import(/* webpackChunkName: "thread-redirect" */'~web-apartment/lib/common/scenes/_redirects/ThreadRedirect')),
  '/outbound/stripe/:accountLinkType': require(/* webpackChunkName: "stripe-connect-redirect" */'~web-apartment/lib/common/scenes/_redirects/StripeConnectRedirect').default,

  // Not found catchall
  '/*': loadable(() => import(/* webpackChunkName: "not-found" */'~web-apartment/lib/common/scenes/NotFound')),
};
