import loadable from '@loadable/component';
import _ from 'lodash';

import * as enums from './enums';

import * as cardAlertEnums from './components/CardAlert/enums';
import * as cardSectionEnums from './components/CardSection/enums';

const LoadableCardAlert = loadable(() => import(/* webpackChunkName: "card-alert" */'./components/CardAlert'));
const CardAlert = _.assign(LoadableCardAlert, { enums: cardAlertEnums });

const LoadableCardSection = loadable(() => import(/* webpackChunkName: "card-section" */'./components/CardSection'));
const CardSection = _.assign(LoadableCardSection, { enums: cardSectionEnums });

const LoadableCard = loadable(() => import(/* webpackChunkName: "card" */'./Card'));
const Card = _.assign(LoadableCard, {
  CardAlert,
  CardSection,
  enums,
});

export * from './types';
export default Card;
