export {
  Colors as ActionColors,
  Icons as ActionIcons,
} from '~web-apartment/lib/common/components/Button/enums';
export {
  Colors as TagColors,
  Icons as TagIcons,
} from '~web-apartment/lib/common/components/Tag/enums';
export { Themes } from '~tools/react/hocs/withTheme/enums';

export { Colors as AlertColors } from './components/CardAlert/enums';
export { Icons as AlertIcons } from './components/CardAlert/enums';

export enum OverflowValues {
  Hidden = 'HIDDEN',
}

export enum Shadows {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  None = 'NONE',
  Small = 'SMALL',
}

export enum Widths {
  Auto = 'AUTO',
  Full = 'FULL',
}
