export enum Colors {
  Black = 'BLACK',
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

export enum Icons {
  Check = 'CHECK',
  Clock = 'CLOCK',
  Cross = 'CROSS',
  Lock = 'LOCK',
  Refresh = 'REFRESH',
}

export enum Sizes {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
}

export enum Styles {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}
