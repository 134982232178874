import withMutation from '~tools/react/graphql/withMutation';
import { CreateStripeAccountLinkInput } from '~tools/types/graphqlSchema';

import CreateStripeAccountLinkMutation from './createStripeAccountLink.gql';

interface Data {
  createStripeAccountLink: string;
}

interface Variables {
  input: CreateStripeAccountLinkInput;
}

export interface CreateStripeAccountLinkProps {
  createStripeAccountLink: (input: CreateStripeAccountLinkInput) => Promise<string>;
}

export default withMutation<{}, Data, Variables, CreateStripeAccountLinkProps>(CreateStripeAccountLinkMutation, {
  props: props => ({
    createStripeAccountLink: async (input: CreateStripeAccountLinkInput) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating Stripe account link.');

      return res.data.createStripeAccountLink;
    },
  }),
});
