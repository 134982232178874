import { DocumentNode } from 'graphql';
import hoistStatics from 'hoist-non-react-statics';
import _ from 'lodash';
import React, { Component, ComponentType } from 'react';
import { Mutation, MutationFunction, MutationFunctionOptions } from 'react-apollo';

// import { removeNodesAndEdges } from '~tools/react/graphql';

function withMutation<TInputProps, TData, TVariables, TOuputProps>(
  mutation: DocumentNode,
  config: {
    options?: MutationFunctionOptions<TData, TVariables>;
    props: (props: { ownProps: TInputProps, mutate: MutationFunction<TData, TVariables>; }) => TOuputProps
  }
) {
  return function wrapWithMutation<T>(ComposedComponent: ComponentType<T & TInputProps & TOuputProps>) {
    const displayName = `withMutation(${ComposedComponent.displayName || ComposedComponent.name || 'Component'})`;

    class WithMutation extends Component<T & TInputProps> {
      static displayName = displayName;

      render() {
        // const options = _.isFunction(config.options) ? config.options(this.props) : config.options;
        return (
          <Mutation<TData, TVariables> mutation={mutation}>
            {(mutate) => {
              const passedProps = config.props({ mutate, ownProps: this.props, });
              return (
                <ComposedComponent {...passedProps} {...this.props} />
              );
            }}
          </Mutation>
        );
      }
    }

    return hoistStatics(WithMutation, ComposedComponent);
  };
}

export default withMutation;
