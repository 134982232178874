/* eslint-disable quotes */
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** UUID custom scalar type */
  UUID: string,
  /** Date custom scalar type */
  Date: string,
  /** Time custom scalar type */
  Time: any,
  /** JSON custom scalar type */
  JSON: object,
}

export interface AcceptServiceDispatchInput {
  appointmentEndsAt: Scalars['String'],
  appointmentStartsAt: Scalars['String'],
}

/** A set of access instructions for an address unit */
export interface AccessInstruction {
  __typename?: 'AccessInstruction',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
}

export enum AccessInstructionAccessorEntityTypes {
  SMART_LOCK = 'SMART_LOCK'
}

export enum AccessInstructionEntityTypes {
  LISTING = 'LISTING',
  TAKEOVER = 'TAKEOVER'
}

/** A Caretaker user's public profile. */
export interface Account {
  __typename?: 'Account',
  averageResponseTime?: Maybe<Scalars['Int']>,
  averageResponseTimeFormatted?: Maybe<Scalars['String']>,
  authorBio?: Maybe<Scalars['String']>,
  authorRole?: Maybe<Scalars['String']>,
  authorSocialLink?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  defaultPhotoUrl?: Maybe<Scalars['String']>,
  educationLevel?: Maybe<Scalars['String']>,
  educationLevelString?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  hasConnectedFacebook?: Maybe<Scalars['Boolean']>,
  hasConnectedLinkedIn?: Maybe<Scalars['Boolean']>,
  hasEmail?: Maybe<Scalars['Boolean']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  hasPhoneNumber?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  lastSeen?: Maybe<Scalars['Date']>,
  locationString?: Maybe<Scalars['String']>,
  photoUrl?: Maybe<Scalars['String']>,
  smokes?: Maybe<Scalars['Boolean']>,
  uuid: Scalars['UUID'],
  viewerHasReviewed?: Maybe<Scalars['Boolean']>,
  yearsRenting?: Maybe<Scalars['Int']>,
  photo?: Maybe<Photo>,
  listings?: Maybe<ListingConnection>,
  /** The organization associated with this profile */
  organization?: Maybe<Organization>,
  /** A list of reviews for this user. */
  reviews?: Maybe<ReviewConnection>,
}


/** A Caretaker user's public profile. */
export interface AccountListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A Caretaker user's public profile. */
export interface AccountReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

/** A user's unit */
export interface AccountAddressUnit {
  __typename?: 'AccountAddressUnit',
  tag?: Maybe<Scalars['String']>,
  uuid: Scalars['UUID'],
  addressUnit?: Maybe<AddressUnit>,
}

/** A connection to a list of items. */
export interface AccountConnection {
  __typename?: 'AccountConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface AccountEdge {
  __typename?: 'AccountEdge',
  /** The item at the end of the edge */
  node?: Maybe<Account>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export enum AccountIdentityVerificationStatuses {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED'
}

export interface AccountSettings {
  __typename?: 'AccountSettings',
  createdAt?: Maybe<Scalars['Date']>,
  receiveEmailNotifications?: Maybe<Scalars['Boolean']>,
  receiveSmsNotifications?: Maybe<Scalars['Boolean']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
}

export enum AccountStatuses {
  BANNED = 'BANNED',
  LEAD = 'LEAD',
  MANAGED = 'MANAGED',
  REGISTERED = 'REGISTERED',
  BOT = 'BOT',
  PLACEHOLDER = 'PLACEHOLDER'
}

export enum AccountTransportationMethods {
  BIKING = 'BIKING',
  DRIVING = 'DRIVING',
  PUBLIC_TRANSIT = 'PUBLIC_TRANSIT',
  WALKING = 'WALKING'
}

export interface ActivateAccountInputType {
  email: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  password: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
}

export interface AddDeviceInputType {
  deviceToken: Scalars['String'],
  identifier: Scalars['String'],
  type: DeviceTypes,
}

export interface AddPhotoToListingInputType {
  height?: Maybe<Scalars['Float']>,
  isPrimary?: Maybe<Scalars['Boolean']>,
  key: Scalars['String'],
  listingUuid: Scalars['UUID'],
  tagUuids?: Maybe<Array<Scalars['UUID']>>,
  position: Scalars['Int'],
  width?: Maybe<Scalars['Float']>,
}

export interface AddPropertyManagerContractToServicePreferenceInput {
  propertyManagerContractUuid: Scalars['UUID'],
}

/** An address */
export interface Address {
  __typename?: 'Address',
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  googlePlaceId?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  neighborhood?: Maybe<Scalars['String']>,
  standardizedAddress?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  streetAddress1?: Maybe<Scalars['String']>,
  timezone?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  zipCode?: Maybe<Scalars['String']>,
  /** Building that currently exist at this address. */
  building?: Maybe<Building>,
  /** Buildings that have existed or currently exist at this address. */
  historicBuildings?: Maybe<Array<Maybe<Building>>>,
}

export interface AddressUnit {
  __typename?: 'AddressUnit',
  createdAt?: Maybe<Scalars['Date']>,
  isRoot?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  unitType?: Maybe<AddressUnitUnitTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  accessInstruction?: Maybe<AccessInstruction>,
  /** The active landlord contract for this address unit */
  activeLandlordContract?: Maybe<LandlordContract>,
  /** The active property manager contracts for this address unit */
  activePropertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  address?: Maybe<Address>,
  /** The amenities of the address unit */
  addressUnitAmenities?: Maybe<Array<Maybe<AddressUnitAmenityType>>>,
  /** The rooms of the address unit */
  addressUnitRooms?: Maybe<Array<Maybe<AddressUnitRoom>>>,
  /** The building for this address unit */
  building?: Maybe<Building>,
  /** The calendar associated with this address unit */
  calendar?: Maybe<Calendar>,
  keybox?: Maybe<SmartLock>,
  smartLock?: Maybe<SmartLock>,
  /** The list of landlord contracts for this address unit */
  landlordContracts?: Maybe<Array<Maybe<LandlordContract>>>,
  /** The list of property manager contracts for this address unit */
  propertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** A list of reviews for this unit. */
  reviews?: Maybe<ReviewConnection>,
  /** The current viewer's property manager contract for this address unit */
  viewerPropertyManagerContract?: Maybe<PropertyManagerContract>,
}


export interface AddressUnitReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

/** An amenity for an address unit */
export interface AddressUnitAmenityType {
  __typename?: 'AddressUnitAmenityType',
  createdAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The address unit of the amenity */
  addressUnit?: Maybe<AddressUnit>,
  /** The amenity of the address unit */
  amenity?: Maybe<Amenity>,
  /** The viewer's vote on the addressUnitAmenity */
  viewerVote?: Maybe<VoteType>,
}

/** A room in an addressunit */
export interface AddressUnitRoom {
  __typename?: 'AddressUnitRoom',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  name: Scalars['String'],
  roomType: AddressUnitRoomTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The address unit for this room */
  addressUnit?: Maybe<AddressUnit>,
  /** Condition reports made on this room */
  conditionReports?: Maybe<Array<Maybe<ConditionReport>>>,
  /** The most recent photo of this room */
  photo?: Maybe<Photo>,
  /** All photos of this room */
  photos?: Maybe<Array<Maybe<Photo>>>,
}


/** A room in an addressunit */
export interface AddressUnitRoomConditionReportsArgs {
  filter?: Maybe<ConditionReportFilterInput>
}

export enum AddressUnitRoomTypes {
  BEDROOM = 'BEDROOM',
  LIVING_AREA = 'LIVING_AREA',
  KITCHEN = 'KITCHEN',
  DINING_ROOM = 'DINING_ROOM',
  BATHROOM = 'BATHROOM',
  BASEMENT = 'BASEMENT',
  OUTDOOR_AREA = 'OUTDOOR_AREA'
}

export enum AddressUnitUnitTypes {
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  TOWNHOUSE = 'TOWNHOUSE'
}

export interface AmenitiesFilterType {
  /** The type of amenity */
  type?: Maybe<AmenityTypes>,
}

/** An amenity */
export interface Amenity {
  __typename?: 'Amenity',
  enum?: Maybe<AmenitySlugs>,
  icon?: Maybe<IconResource>,
  iconUrl?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  type?: Maybe<AmenityTypes>,
  uuid: Scalars['UUID'],
}

export enum AmenitySlugs {
  BALCONY = 'BALCONY',
  BIKE_STORAGE = 'BIKE_STORAGE',
  CENTRAL_AC = 'CENTRAL_AC',
  COUPLES_OK = 'COUPLES_OK',
  DISHWASHER = 'DISHWASHER',
  DOORMAN = 'DOORMAN',
  ELEVATOR = 'ELEVATOR',
  FIBER_INTERNET = 'FIBER_INTERNET',
  GARDEN = 'GARDEN',
  GREEN_BUILDING = 'GREEN_BUILDING',
  GYM = 'GYM',
  LAUNDRY_IN_BUILDING = 'LAUNDRY_IN_BUILDING',
  OUTDOOR_SPACE = 'OUTDOOR_SPACE',
  PARKING = 'PARKING',
  PATIO = 'PATIO',
  PETS_ALLOWED = 'PETS_ALLOWED',
  PRIVATE_ROOF_DECK = 'PRIVATE_ROOF_DECK',
  RECREATIONAL_AREA = 'RECREATIONAL_AREA',
  RENT_STABILIZED = 'RENT_STABILIZED',
  ROOFTOP_ACCESS = 'ROOFTOP_ACCESS',
  STORAGE = 'STORAGE',
  SWIMMING_POOL = 'SWIMMING_POOL',
  WALK_IN_CLOSET = 'WALK_IN_CLOSET',
  WASHER_DRYER_IN_UNIT = 'WASHER_DRYER_IN_UNIT'
}

export enum AmenityTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

/** Constants for Apartment App */
export interface ApartmentAppConstants {
  __typename?: 'ApartmentAppConstants',
  minimumiOSVersion: Scalars['String'],
}

/** An application tracks a user through the leasing process for a given listing */
export interface Application {
  __typename?: 'Application',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
}

/** An account's application profile */
export interface ApplicationAccount {
  __typename?: 'ApplicationAccount',
  averageResponseTime?: Maybe<Scalars['Int']>,
  averageResponseTimeFormatted?: Maybe<Scalars['String']>,
  authorBio?: Maybe<Scalars['String']>,
  authorRole?: Maybe<Scalars['String']>,
  authorSocialLink?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  defaultPhotoUrl?: Maybe<Scalars['String']>,
  educationLevel?: Maybe<Scalars['String']>,
  educationLevelString?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  hasConnectedFacebook?: Maybe<Scalars['Boolean']>,
  hasConnectedLinkedIn?: Maybe<Scalars['Boolean']>,
  hasEmail?: Maybe<Scalars['Boolean']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  hasPhoneNumber?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  lastSeen?: Maybe<Scalars['Date']>,
  locationString?: Maybe<Scalars['String']>,
  photoUrl?: Maybe<Scalars['String']>,
  smokes?: Maybe<Scalars['Boolean']>,
  uuid: Scalars['UUID'],
  viewerHasReviewed?: Maybe<Scalars['Boolean']>,
  yearsRenting?: Maybe<Scalars['Int']>,
  photo?: Maybe<Photo>,
  listings?: Maybe<ListingConnection>,
  /** The organization associated with this profile */
  organization?: Maybe<Organization>,
  /** A list of reviews for this user. */
  reviews?: Maybe<ReviewConnection>,
  facebookId?: Maybe<Scalars['String']>,
  activeCreditReport?: Maybe<ApplicationCreditReport>,
  creditReports?: Maybe<Array<Maybe<ApplicationCreditReport>>>,
  incomeSources?: Maybe<Array<Maybe<ApplicationIncomeSource>>>,
  uploads?: Maybe<Array<Maybe<ApplicationUpload>>>,
}


/** An account's application profile */
export interface ApplicationAccountListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** An account's application profile */
export interface ApplicationAccountReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

export interface ApplicationCreditReport {
  __typename?: 'ApplicationCreditReport',
  uuid: Scalars['UUID'],
  creditScore?: Maybe<Scalars['Int']>,
  hasCriminalRecords?: Maybe<Scalars['Boolean']>,
  hasEvictionRecords?: Maybe<Scalars['Boolean']>,
}

/** An income source */
export interface ApplicationIncomeSource {
  __typename?: 'ApplicationIncomeSource',
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  active?: Maybe<Scalars['Boolean']>,
  annualIncome?: Maybe<Scalars['Float']>,
  company?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  source?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  type?: Maybe<Scalars['String']>,
  verified?: Maybe<Scalars['Boolean']>,
  verifiedAt?: Maybe<Scalars['Date']>,
  /** A list of uploads attached to this incomesource. */
  uploads?: Maybe<Array<Maybe<Upload>>>,
}

/** A lease agreement */
export interface ApplicationLease {
  __typename?: 'ApplicationLease',
  createdAt?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  startDate?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  voidedAt?: Maybe<Scalars['Date']>,
  addressUnit?: Maybe<PrivateAddressUnit>,
  childLease?: Maybe<PrivateLease>,
  childLeases?: Maybe<Array<Maybe<PrivateLease>>>,
  landlord?: Maybe<Account>,
  listing?: Maybe<PrivateListing>,
  checksContext?: Maybe<ChecksContextType>,
  defaultPaymentMethodId?: Maybe<Scalars['String']>,
  deltaPaymentDaysFromDueDate?: Maybe<Scalars['Int']>,
  hasAutopayEnabled?: Maybe<Scalars['Boolean']>,
  nextRentPaymentAmountInCents?: Maybe<Scalars['Int']>,
  nextRentPaymentMonthEnding?: Maybe<Scalars['Date']>,
  nextRentPaymentMonthStarting?: Maybe<Scalars['Date']>,
  rentDebitPlatformFeeMultiplier?: Maybe<Scalars['Float']>,
  rentInCents?: Maybe<Scalars['Int']>,
  securityDepositInCents?: Maybe<Scalars['Int']>,
  type?: Maybe<LeaseTypes>,
  upfrontRentInCents?: Maybe<Scalars['Int']>,
  application?: Maybe<PrivateApplication>,
  defaultPaymentMethod?: Maybe<StripePaymentMethod>,
  checksPayableToAddressUnit?: Maybe<PrivateAddressUnit>,
  document?: Maybe<PrivateDocument>,
  flip?: Maybe<Flip>,
  leaseInvite?: Maybe<LeaseInvite>,
  lessee?: Maybe<Account>,
  lessor?: Maybe<Account>,
  rentPayment?: Maybe<RentPayment>,
  rentPayments?: Maybe<RentPaymentConnection>,
  securityDeposit?: Maybe<SecurityDepositType>,
  securityDeposits?: Maybe<SecurityDepositTypeConnection>,
  takeover?: Maybe<Takeover>,
  /** The thread for this lease */
  thread?: Maybe<Thread>,
  deltaPaymentDaysFromDueDay?: Maybe<Scalars['Int']>,
  isCurrent?: Maybe<Scalars['Boolean']>,
  landlordSignedAt?: Maybe<Scalars['Date']>,
  lesseeSignedAt?: Maybe<Scalars['Date']>,
  lessorSignedAt?: Maybe<Scalars['Date']>,
  nextRentPaymentAmount?: Maybe<Scalars['Float']>,
  rentDebitPlatformFeeInCents?: Maybe<Scalars['Int']>,
  upfrontRentDebitPlatformFeeInCents?: Maybe<Scalars['Int']>,
  autopaySourceId?: Maybe<Scalars['String']>,
}


/** A lease agreement */
export interface ApplicationLeaseChildLeaseArgs {
  filter?: Maybe<ChildLeasesFilterInput>
}


/** A lease agreement */
export interface ApplicationLeaseChildLeasesArgs {
  filter?: Maybe<ChildLeasesFilterInput>
}


/** A lease agreement */
export interface ApplicationLeaseRentPaymentArgs {
  uuid: Scalars['UUID']
}


/** A lease agreement */
export interface ApplicationLeaseRentPaymentsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A lease agreement */
export interface ApplicationLeaseSecurityDepositArgs {
  uuid: Scalars['UUID']
}

export interface ApplicationRoleInput {
  eq?: Maybe<ApplicationRoles>,
  in?: Maybe<Array<ApplicationRoles>>,
}

/** Roles in the context of an application */
export enum ApplicationRoles {
  RENTER = 'RENTER',
  LISTER = 'LISTER'
}

export interface ApplicationsFilterInput {
  role?: Maybe<ApplicationRoleInput>,
  status?: Maybe<ApplicationStatusInput>,
}

export enum ApplicationStatuses {
  /** Tenant has accepted the user's application */
  ACCEPTED = 'ACCEPTED',
  /** User has began the application process */
  APPLIED = 'APPLIED',
  /** Deposit/paperwork complete, user "probably" moved in to listing */
  FLIPPED = 'FLIPPED',
  IN_REVIEW = 'IN_REVIEW',
  LEAD = 'LEAD',
  LEGACY = 'LEGACY',
  /** Application has been opened */
  OPENED = 'OPENED',
  /** Tenant has rejected the user's application */
  REJECTED = 'REJECTED',
  /** User has withdrawn their application */
  WITHDRAWN = 'WITHDRAWN'
}

export interface ApplicationStatusInput {
  eq?: Maybe<ApplicationStatuses>,
  in?: Maybe<Array<ApplicationStatuses>>,
}

/** A user upload */
export interface ApplicationUpload {
  __typename?: 'ApplicationUpload',
  createdAt: Scalars['Date'],
  filename?: Maybe<Scalars['String']>,
  fileType?: Maybe<Scalars['String']>,
  tag: UploadTags,
  updatedAt: Scalars['Date'],
  url: Scalars['String'],
  uuid: Scalars['UUID'],
  /** The document type of this upload */
  documentType?: Maybe<DocumentType>,
}

export interface Article {
  __typename?: 'Article',
  archivedAt?: Maybe<Scalars['Date']>,
  brief?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  lastVerifiedAt?: Maybe<Scalars['Date']>,
  markdown?: Maybe<Scalars['String']>,
  metaDescription?: Maybe<Scalars['String']>,
  metaTitle?: Maybe<Scalars['String']>,
  postedAt?: Maybe<Scalars['Date']>,
  slug?: Maybe<Scalars['String']>,
  subtype?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The author of this article */
  author?: Maybe<Account>,
  /** The building this article is related to */
  building?: Maybe<Building>,
  /** The city this article is related to */
  city?: Maybe<City>,
  /** Photos related to this article */
  photos?: Maybe<Array<Maybe<Photo>>>,
  /** The photo related to this article in position 0 */
  primaryPhoto?: Maybe<Photo>,
  /** The property manager this article is related to */
  propertyManager?: Maybe<PropertyManager>,
  /** The state this article is related to */
  state?: Maybe<State>,
}

/** A connection to a list of items. */
export interface ArticleConnection {
  __typename?: 'ArticleConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ArticleEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface ArticleEdge {
  __typename?: 'ArticleEdge',
  /** The item at the end of the edge */
  node?: Maybe<Article>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface ArticleFilterType {
  /** Has been archived (Admin only) */
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Has been posted (Admin only) */
  isPosted?: Maybe<Scalars['Boolean']>,
}

export interface AskContentQuestionInputType {
  email?: Maybe<Scalars['String']>,
  location?: Maybe<Scalars['String']>,
  question: Scalars['String'],
  url?: Maybe<Scalars['String']>,
}

export interface AssignInstantAgreementDocumentToTakeoverInput {
  agreementDocumentUuid: Scalars['String'],
}

/** Outsourced requests */
export interface AssistantRequest {
  __typename?: 'AssistantRequest',
  bidInCents: Scalars['Int'],
  createdAt: Scalars['Date'],
  messages?: Maybe<Scalars['JSON']>,
  provider: AssistantRequestProviders,
  providerId: Scalars['String'],
  request: Scalars['String'],
  response?: Maybe<Scalars['JSON']>,
  status: AssistantRequestStatuses,
  title: Scalars['String'],
  type: AssistantRequestTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The building this request is associated to */
  building?: Maybe<Building>,
}

export enum AssistantRequestProviders {
  FANCYHANDS = 'FANCYHANDS'
}

export enum AssistantRequestStatuses {
  AWAITING_RESPONSE = 'AWAITING_RESPONSE',
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED',
  NEW = 'NEW',
  OPEN = 'OPEN',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED'
}

export enum AssistantRequestTypes {
  COLLECT_BUILDING_CONTACT_INFO = 'COLLECT_BUILDING_CONTACT_INFO',
  COLLECT_REQUIREMENTS_FROM_BUILDING = 'COLLECT_REQUIREMENTS_FROM_BUILDING',
  COLLECT_REQUIREMENTS_FROM_BROKER = 'COLLECT_REQUIREMENTS_FROM_BROKER'
}

export interface AttachEvidenceInputType {
  /** The uuids of the evidence uploads. */
  evidenceUuids: Array<Maybe<Scalars['UUID']>>,
}

export interface AttachPhoneNumberToOrganizationInput {
  organizationUuid: Scalars['UUID'],
}

export interface AttachPhoneNumberToPersonInput {
  personUuid: Scalars['UUID'],
}

export interface AttachPrimaryPhotoToAccountInput {
  /** The uuid of the photo to attach */
  photoUuid: Scalars['UUID'],
}

export interface AttachRentalFormToEntityInputType {
  entityType: RentalFormEntityTypes,
  entityUuid: Scalars['UUID'],
}

export interface AttachTimeSlotsToCalendarInput {
  daysOfWeek: Array<Scalars['Int']>,
  eligibleEntityTypes?: Maybe<Array<Maybe<CalendarTimeSlotEntityTypes>>>,
  endingHour: Scalars['Int'],
  startingHour: Scalars['Int'],
}

export interface AttachVerificationMethodToRequirementInput {
  verificationMethodUuid: Scalars['UUID'],
}

export interface AvailableTimeSlot {
  __typename?: 'AvailableTimeSlot',
  dayOfWeek: Scalars['Int'],
  endsAt: Scalars['Time'],
  eligibleEntityTypes?: Maybe<Array<Maybe<CalendarTimeSlotEntityTypes>>>,
  startsAt: Scalars['Time'],
  uuid: Scalars['UUID'],
}

export interface AvailableViewingSlotsDateInput {
  gte?: Maybe<Scalars['Date']>,
}

export interface AvailableViewingSlotsInput {
  startsAt: AvailableViewingSlotsDateInput,
}

/** A signed S3 upload request. */
export interface AwsSignature {
  __typename?: 'AWSSignature',
  accessKeyId?: Maybe<Scalars['String']>,
  acl?: Maybe<Scalars['String']>,
  bucket?: Maybe<Scalars['String']>,
  contentType?: Maybe<Scalars['String']>,
  key?: Maybe<Scalars['String']>,
  policy?: Maybe<Scalars['String']>,
  signature?: Maybe<Scalars['String']>,
  bucketUrl?: Maybe<Scalars['String']>,
  fullUrl?: Maybe<Scalars['String']>,
}

/** A balance */
export interface Balance {
  __typename?: 'Balance',
  availableAmountInCents: Scalars['Int'],
  createdAt: Scalars['Date'],
  defaultTopupSourceId?: Maybe<Scalars['String']>,
  nextTopupDueAt: Scalars['Date'],
  targetAmountInCents: Scalars['Int'],
  type: BalanceTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The owner account of this balance */
  account: Account,
  /** The balance transactions related to this balance */
  balanceTransactions?: Maybe<BalanceTransactionConnection>,
  defaultTopupSource?: Maybe<StripeExternalAccount>,
}


/** A balance */
export interface BalanceBalanceTransactionsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<BalanceTransactionsFilterInput>
}

/** A balance transaction */
export interface BalanceTransaction {
  __typename?: 'BalanceTransaction',
  amountInCents: Scalars['Int'],
  createdAt: Scalars['Date'],
  description?: Maybe<Scalars['String']>,
  status: BalanceTransactionStatuses,
  type: BalanceTransactionTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The balance related to this balance transaction */
  balance?: Maybe<Balance>,
  /** The issued card authorization related to this balance transaction */
  issuedCardAuthorization?: Maybe<IssuedCardAuthorization>,
}

/** A connection to a list of items. */
export interface BalanceTransactionConnection {
  __typename?: 'BalanceTransactionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BalanceTransactionEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface BalanceTransactionEdge {
  __typename?: 'BalanceTransactionEdge',
  /** The item at the end of the edge */
  node?: Maybe<BalanceTransaction>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface BalanceTransactionEntityTypeInput {
  eq?: Maybe<BalanceTransactionSourceEntityTypes>,
  in?: Maybe<Array<BalanceTransactionSourceEntityTypes>>,
}

export interface BalanceTransactionsFilterInput {
  createdAt?: Maybe<DateInputType>,
  sourceEntityType?: Maybe<BalanceTransactionEntityTypeInput>,
  status?: Maybe<BalanceTransactionStatusInput>,
  type?: Maybe<BalanceTransactionTypeInput>,
}

export enum BalanceTransactionSourceEntityTypes {
  ISSUED_CARD_AUTHORIZATION = 'ISSUED_CARD_AUTHORIZATION'
}

export enum BalanceTransactionStatuses {
  AVAILABLE = 'AVAILABLE',
  PENDING = 'PENDING'
}

export interface BalanceTransactionStatusInput {
  eq?: Maybe<BalanceTransactionStatuses>,
  in?: Maybe<Array<BalanceTransactionStatuses>>,
}

export interface BalanceTransactionTypeInput {
  eq?: Maybe<BalanceTransactionTypes>,
  in?: Maybe<Array<BalanceTransactionTypes>>,
}

export enum BalanceTransactionTypes {
  ADJUSTMENT = 'ADJUSTMENT',
  ADVANCE = 'ADVANCE',
  ADVANCE_FUNDING = 'ADVANCE_FUNDING',
  ANTICIPATION_REPAYMENT = 'ANTICIPATION_REPAYMENT',
  APPLICATION_FEE = 'APPLICATION_FEE',
  APPLICATION_FEE_REFUND = 'APPLICATION_FEE_REFUND',
  CHARGE = 'CHARGE',
  CONNECT_COLLECTION_TRANSFER = 'CONNECT_COLLECTION_TRANSFER',
  CONTRIBUTION = 'CONTRIBUTION',
  ISSUING_AUTHORIZATION_HOLD = 'ISSUING_AUTHORIZATION_HOLD',
  ISSUING_AUTHORIZATION_RELEASE = 'ISSUING_AUTHORIZATION_RELEASE',
  ISSUING_DISPUTE = 'ISSUING_DISPUTE',
  ISSUING_TRANSACTION = 'ISSUING_TRANSACTION',
  PAYMENT = 'PAYMENT',
  PAYMENT_FAILURE_REFUND = 'PAYMENT_FAILURE_REFUND',
  PAYMENT_REFUND = 'PAYMENT_REFUND',
  PAYOUT = 'PAYOUT',
  PAYOUT_CANCEL = 'PAYOUT_CANCEL',
  PAYOUT_FAILURE = 'PAYOUT_FAILURE',
  REFUND = 'REFUND',
  REFUND_FAILURE = 'REFUND_FAILURE',
  RESERVED_FUNDS = 'RESERVED_FUNDS',
  RESERVE_TRANSACTION = 'RESERVE_TRANSACTION',
  STRIPE_FEE = 'STRIPE_FEE',
  STRIPE_FX_FEE = 'STRIPE_FX_FEE',
  TAX_FEE = 'TAX_FEE',
  TOPUP = 'TOPUP',
  TOPUP_REVERSAL = 'TOPUP_REVERSAL',
  TRANSFER = 'TRANSFER',
  TRANSFER_CANCEL = 'TRANSFER_CANCEL',
  TRANSFER_FAILURE = 'TRANSFER_FAILURE',
  TRANSFER_REFUND = 'TRANSFER_REFUND'
}

export enum BalanceTypes {
  ACCOUNT = 'ACCOUNT',
  ISSUING = 'ISSUING'
}

export interface BreadcrumbType {
  __typename?: 'BreadcrumbType',
  name?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
}

export interface Building {
  __typename?: 'Building',
  averageRatingValue?: Maybe<Scalars['Float']>,
  averageUnitRentInCents?: Maybe<Scalars['Float']>,
  createdAt?: Maybe<Scalars['Date']>,
  description?: Maybe<Scalars['String']>,
  internalNotes?: Maybe<Scalars['String']>,
  isFlipApproved: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
  postedAt?: Maybe<Scalars['Date']>,
  slug?: Maybe<Scalars['String']>,
  transferNotes?: Maybe<Scalars['String']>,
  unitCount?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  website?: Maybe<Scalars['String']>,
  /** The active landlord contract for this building */
  activeLandlordContract?: Maybe<LandlordContract>,
  /** The active public property manager contracts for this building */
  activePropertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** The address for this building */
  address?: Maybe<Address>,
  /** The address unit for this building */
  addressUnit?: Maybe<AddressUnit>,
  /** The articles written about this building */
  articles?: Maybe<Array<Maybe<Article>>>,
  /** The assistant requests associated to this building */
  assistantRequests?: Maybe<Array<Maybe<AssistantRequest>>>,
  /** The amenities for this building */
  buildingAmenities?: Maybe<Array<Maybe<BuildingAmenityType>>>,
  /** The landlord for this building */
  landlord?: Maybe<Landlord>,
  /** The list of landlord contracts for this building */
  landlordContracts?: Maybe<Array<Maybe<LandlordContract>>>,
  /** The list of listings in this building */
  listings?: Maybe<ListingConnection>,
  /** Policies associated to this building */
  policies?: Maybe<Array<Maybe<Policy>>>,
  /** The property manager for this building */
  propertyManager?: Maybe<PropertyManager>,
  /** The list of property manager contracts for this building */
  propertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** The recommend rental policy for this building */
  recommendedRentalPolicy?: Maybe<RentalPolicy>,
  rentalPolicies?: Maybe<Array<Maybe<RentalPolicy>>>,
  /** A list of reviews for this building. */
  reviews?: Maybe<ReviewConnection>,
  /** Photos people have uploaded of this building */
  userPhotos?: Maybe<PhotoConnection>,
  /** Utility connections that exist at this building. */
  utilityConnections?: Maybe<Array<Maybe<UtilityConnection>>>,
}


export interface BuildingListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ListingsFilterInput>
}


export interface BuildingRentalPoliciesArgs {
  type_in?: Maybe<Array<Maybe<RentalPolicyTypes>>>
}


export interface BuildingReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


export interface BuildingUserPhotosArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

/** An amenity for a building */
export interface BuildingAmenityType {
  __typename?: 'BuildingAmenityType',
  createdAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The amenity of the building */
  amenity?: Maybe<Amenity>,
  /** The building of the amenity */
  building?: Maybe<Building>,
  /** The viewer's vote on the building amenity */
  viewerVote?: Maybe<VoteType>,
}

/** A connection to a list of items. */
export interface BuildingConnection {
  __typename?: 'BuildingConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BuildingEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface BuildingEdge {
  __typename?: 'BuildingEdge',
  /** The item at the end of the edge */
  node?: Maybe<Building>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface BuildingsFilterType {
  addressUuid?: Maybe<Scalars['String']>,
  createdAt?: Maybe<DateInputType>,
  name?: Maybe<SearchStringInput>,
  smart?: Maybe<SearchStringInput>,
  updatedAt?: Maybe<DateInputType>,
}

export enum BuildingsSortByEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum BuildingsSortDirectionEnum {
  /** Ascending */
  ASC = 'ASC',
  /** Descending */
  DESC = 'DESC'
}

export interface Calendar {
  __typename?: 'Calendar',
  createdAt?: Maybe<Scalars['Date']>,
  entityType?: Maybe<CalendarEntityTypes>,
  uuid: Scalars['UUID'],
  addressUnit?: Maybe<AddressUnit>,
  /** A list of available time slots on this calendar. */
  availableTimeSlots?: Maybe<Array<Maybe<AvailableTimeSlot>>>,
  /** The viewer's notification requests for this calendar. */
  viewerNotificationRequests?: Maybe<Array<Maybe<NotificationRequest>>>,
}


export interface CalendarViewerNotificationRequestsArgs {
  filter?: Maybe<NotificationRequestsFilterInput>
}

export interface CalendarEntityInput {
  type: CalendarEntityTypes,
  uuid: Scalars['UUID'],
}

export enum CalendarEntityTypes {
  ADDRESS_UNIT = 'ADDRESS_UNIT'
}

export interface CalendarEvent {
  __typename?: 'CalendarEvent',
  createdAt?: Maybe<Scalars['Date']>,
  endsAt?: Maybe<Scalars['Date']>,
  startsAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  calendar?: Maybe<Calendar>,
}

export enum CalendarTimeSlotEntityTypes {
  VIEWING = 'VIEWING',
  SERVICE_FULFILLMENT = 'SERVICE_FULFILLMENT'
}

export interface ChangePasswordInputType {
  /** The resetToken sent to your email. */
  resetToken?: Maybe<Scalars['String']>,
  /** Your current password */
  currentPassword?: Maybe<Scalars['String']>,
  /** Your new password */
  password: Scalars['String'],
}

/** A Stripe charge */
export interface ChargeType {
  __typename?: 'ChargeType',
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  amountRefunded?: Maybe<Scalars['Int']>,
  captured?: Maybe<Scalars['Boolean']>,
  created?: Maybe<Scalars['Date']>,
  currency?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  failureCode?: Maybe<Scalars['String']>,
  failureMessage?: Maybe<Scalars['String']>,
  invoice?: Maybe<Scalars['String']>,
  paid?: Maybe<Scalars['Boolean']>,
  paymentMethodType?: Maybe<StripePaymentMethodTypes>,
  card?: Maybe<StripeCard>,
  usBankAccount?: Maybe<StripeUsBankAccount>,
}

export interface ChecksContextInputType {
  memo?: Maybe<Scalars['String']>,
  payableToCompany?: Maybe<Scalars['String']>,
  payableToName?: Maybe<Scalars['String']>,
}

/** Check payment context */
export interface ChecksContextType {
  __typename?: 'ChecksContextType',
  memo?: Maybe<Scalars['String']>,
  payableToCompany?: Maybe<Scalars['String']>,
  payableToName?: Maybe<Scalars['String']>,
}

export interface ChildLeasesFilterInput {
  isFullyExecuted?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  type?: Maybe<LeaseTypes>,
}

export interface City {
  __typename?: 'City',
  createdAt?: Maybe<Scalars['Date']>,
  googlePlaceId?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  name?: Maybe<Scalars['String']>,
  uuid: Scalars['UUID'],
  /** The state this city is within */
  state?: Maybe<State>,
}

/** A claim against a security deposit. */
export interface ClaimType {
  __typename?: 'ClaimType',
  amountInCents?: Maybe<Scalars['Int']>,
  /** The timestamp when the funds were released to the claimer. */
  approvedAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  /** The reason the claim was filed. */
  description?: Maybe<Scalars['String']>,
  /** The timestamp when the claim was rejected. */
  rejectedAt?: Maybe<Scalars['Date']>,
  /** The timestamp when the claim was requested. */
  requestedAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid?: Maybe<Scalars['UUID']>,
  evidence?: Maybe<UploadConnection>,
}

export enum CleaningDayTypeEnum {
  FRIDAY = 'Friday',
  MONDAY = 'Monday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
  THURSDAY = 'Thursday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday'
}

export interface Comment {
  __typename?: 'Comment',
  createdAt?: Maybe<Scalars['Date']>,
  headline?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  replies?: Maybe<CommentConnection>,
  /** The user who wrote this review */
  user?: Maybe<Account>,
}


export interface CommentRepliesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

/** A connection to a list of items. */
export interface CommentConnection {
  __typename?: 'CommentConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommentEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface CommentEdge {
  __typename?: 'CommentEdge',
  /** The item at the end of the edge */
  node?: Maybe<Comment>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A condition inspection */
export interface ConditionInspection {
  __typename?: 'ConditionInspection',
  createdAt: Scalars['Date'],
  lockedAt?: Maybe<Scalars['Date']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The address unit for this inspection */
  addressUnit?: Maybe<AddressUnit>,
  /** The reports contained within this inspection */
  conditionReports?: Maybe<Array<Maybe<ConditionReport>>>,
  /** The creator of this inspection */
  inspectorAccount?: Maybe<Account>,
}

/** A condition report */
export interface ConditionReport {
  __typename?: 'ConditionReport',
  createdAt: Scalars['Date'],
  description: Scalars['String'],
  lockedAt?: Maybe<Scalars['Date']>,
  reportType: ConditionReportTypes,
  requiredServiceType?: Maybe<ConditionReportRequiredServiceTypes>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The room for this report */
  addressUnitRoom: AddressUnitRoom,
  /** The (optional) inspection for this report */
  conditionInspection?: Maybe<ConditionInspection>,
  /** The report this report fixed */
  fixedConditionReport?: Maybe<ConditionReport>,
  /** The report that this report was fixed by */
  fixedByConditionReport?: Maybe<ConditionReport>,
  /** The original report that this report confirms */
  originConditionReport?: Maybe<ConditionReport>,
  /** Reports that confirm the current report */
  confirmingConditionReports?: Maybe<Array<Maybe<ConditionReport>>>,
  /** The primary photo related to this report */
  photo?: Maybe<Photo>,
  /** The photos related to this report */
  photos?: Maybe<Array<Maybe<Photo>>>,
  /** The creator of this report */
  reporterAccount: Account,
}

export interface ConditionReportFilterInput {
  isOrigin?: Maybe<Scalars['Boolean']>,
  status?: Maybe<ConditionReportStatusInput>,
}

export enum ConditionReportRequiredServiceTypes {
  ELECTRICAL = 'ELECTRICAL',
  OTHER = 'OTHER',
  PLUMBING = 'PLUMBING',
  SURFACE = 'SURFACE'
}

export interface ConditionReportStatusInput {
  eq?: Maybe<ReportStatuses>,
}

export enum ConditionReportTypes {
  DAMAGE = 'DAMAGE',
  REPAIR = 'REPAIR'
}

export interface ConnectFacebookInputType {
  fbAccessToken: Scalars['String'],
}

export interface ConnectLinkedinInputType {
  authorizationCode: Scalars['String'],
  redirectUrl: Scalars['String'],
}

/** App wide constants */
export interface Constants {
  __typename?: 'Constants',
  apartmentApp: ApartmentAppConstants,
  paymentProcessorCardFeeMultiplier: Scalars['Float'],
  paymentProcessorCardFeePercentage: Scalars['Float'],
}

/** A content answer */
export interface ContentAnswer {
  __typename?: 'ContentAnswer',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  entityType?: Maybe<ContentAnswerEntityTypes>,
  markdown?: Maybe<Scalars['String']>,
  metaDescription?: Maybe<Scalars['String']>,
  metaTitle?: Maybe<Scalars['String']>,
  postedAt?: Maybe<Scalars['Date']>,
  shortAnswer?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The author of this content answer */
  author?: Maybe<Account>,
  /** The building this content answer is related to */
  building?: Maybe<Building>,
  /** The city this content answer is related to */
  city?: Maybe<City>,
  /** The question this content answer is for */
  contentQuestion?: Maybe<ContentQuestion>,
  originContentAnswer?: Maybe<ContentAnswer>,
  /** The property manager this content answer is related to */
  propertyManager?: Maybe<PropertyManager>,
  /** The state this content answer is related to */
  state?: Maybe<State>,
  /** Organization that verified this content answer */
  verifiedByOrganization?: Maybe<Organization>,
}

export interface ContentAnswerEntityType {
  type: ContentAnswerEntityTypes,
  uuid: Scalars['UUID'],
}

export enum ContentAnswerEntityTypes {
  BUILDING = 'BUILDING',
  CITY = 'CITY',
  PROPERTYMANAGER = 'PROPERTYMANAGER',
  STATE = 'STATE'
}

export interface ContentAnswerFilterType {
  entity?: Maybe<ContentAnswerEntityType>,
  slugOrUuid?: Maybe<Scalars['String']>,
}

/** A content category */
export interface ContentCategory {
  __typename?: 'ContentCategory',
  archivedAt?: Maybe<Scalars['Date']>,
  description?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  position?: Maybe<Scalars['Int']>,
  postedAt?: Maybe<Scalars['Date']>,
  /** The section this category should appear in */
  section?: Maybe<ContentCategorySections>,
  slug?: Maybe<Scalars['String']>,
  uuid: Scalars['UUID'],
  contentQuestions?: Maybe<ContentQuestionConnection>,
  contentQuestion?: Maybe<ContentQuestion>,
  contentAnswer?: Maybe<ContentAnswer>,
  contentSection?: Maybe<ContentSection>,
  originContentCategory?: Maybe<ContentCategory>,
}


/** A content category */
export interface ContentCategoryContentQuestionsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ContentQuestionFilterType>
}


/** A content category */
export interface ContentCategoryContentQuestionArgs {
  slugOrUuid?: Maybe<Scalars['String']>
}


/** A content category */
export interface ContentCategoryContentAnswerArgs {
  slugOrUuid?: Maybe<Scalars['String']>
}

export interface ContentCategoryFilterInput {
  /** Filter categories based on content section */
  contentSection?: Maybe<ContentSectionFilterInput>,
  /** Has been archived */
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Section of categories */
  section?: Maybe<ContentCategorySectionInput>,
}

export interface ContentCategorySectionInput {
  eq?: Maybe<ContentCategorySections>,
  in?: Maybe<Array<ContentCategorySections>>,
}

export enum ContentCategorySections {
  LEARN = 'LEARN',
  MANUAL = 'MANUAL',
  SUPPORT = 'SUPPORT'
}

/** A content guide */
export interface ContentGuide {
  __typename?: 'ContentGuide',
  createdAt?: Maybe<Scalars['Date']>,
  tag?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The steps in this content guide */
  contentSteps?: Maybe<Array<Maybe<ContentStep>>>,
  /** The author of this content guide */
  author?: Maybe<Account>,
}

/** A connection to a list of items. */
export interface ContentGuideConnection {
  __typename?: 'ContentGuideConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContentGuideEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface ContentGuideEdge {
  __typename?: 'ContentGuideEdge',
  /** The item at the end of the edge */
  node?: Maybe<ContentGuide>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A content question */
export interface ContentQuestion {
  __typename?: 'ContentQuestion',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  defaultEntityMetaTitle?: Maybe<Scalars['String']>,
  defaultEntityTitle?: Maybe<Scalars['String']>,
  menuTitle?: Maybe<Scalars['String']>,
  position?: Maybe<Scalars['Int']>,
  postedAt?: Maybe<Scalars['Date']>,
  section?: Maybe<ContentQuestionSections>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The author of this content question */
  author?: Maybe<Account>,
  /** The building this content question is related to */
  building?: Maybe<Building>,
  /** The children questions of this question */
  childrenContentQuestions?: Maybe<Array<Maybe<ContentQuestion>>>,
  /** The city this content question is related to */
  city?: Maybe<City>,
  /** An answer to this content question. No match on filter falls back to primary answer. */
  contentAnswer?: Maybe<ContentAnswer>,
  /** The answers to this content question */
  contentAnswers?: Maybe<Array<Maybe<ContentAnswer>>>,
  /** The category this content question is related to */
  contentCategory?: Maybe<ContentCategory>,
  /** The section this content question is related to (as the default content question) */
  contentSection?: Maybe<ContentSection>,
  originContentQuestion?: Maybe<ContentQuestion>,
  /** The parent question this content question is related to */
  parentContentQuestion?: Maybe<ContentQuestion>,
  /** The property manager this content question is related to */
  propertyManager?: Maybe<PropertyManager>,
  /** The state this content question is related to */
  state?: Maybe<State>,
}


/** A content question */
export interface ContentQuestionContentAnswerArgs {
  filter?: Maybe<ContentAnswerFilterType>
}

export interface ContentQuestionArgType {
  /** Has no parents */
  isOrphan?: Maybe<Scalars['Boolean']>,
  /** Has been archived */
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Has been posted */
  isPosted?: Maybe<Scalars['Boolean']>,
  /** Filter by set of UUIDs */
  uuid?: Maybe<Array<Maybe<Scalars['UUID']>>>,
}

/** A connection to a list of items. */
export interface ContentQuestionConnection {
  __typename?: 'ContentQuestionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ContentQuestionEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface ContentQuestionEdge {
  __typename?: 'ContentQuestionEdge',
  /** The item at the end of the edge */
  node?: Maybe<ContentQuestion>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface ContentQuestionFilterType {
  /** Has been archived */
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Has no parents */
  isOrphan?: Maybe<Scalars['Boolean']>,
  /** Has been posted */
  isPosted?: Maybe<Scalars['Boolean']>,
  /** Section of questions */
  section?: Maybe<ContentQuestionSectionInput>,
}

export interface ContentQuestionSectionInput {
  eq?: Maybe<ContentQuestionSections>,
  in?: Maybe<Array<ContentQuestionSections>>,
}

export enum ContentQuestionSections {
  LEARN = 'LEARN',
  MANUAL = 'MANUAL',
  SUPPORT = 'SUPPORT'
}

/** A content rule */
export interface ContentRule {
  __typename?: 'ContentRule',
  createdAt?: Maybe<Scalars['Date']>,
  entityType?: Maybe<ContentRuleEntityTypes>,
  markdown?: Maybe<Scalars['String']>,
  position?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The building this content rule is related to */
  building?: Maybe<Building>,
  /** The city this content rule is related to */
  city?: Maybe<City>,
  /** The property manager this content rule is related to */
  propertyManager?: Maybe<PropertyManager>,
  /** The state this content rule is related to */
  state?: Maybe<State>,
}

export enum ContentRuleEntityTypes {
  BUILDING = 'BUILDING',
  CITY = 'CITY',
  PROPERTYMANAGER = 'PROPERTYMANAGER',
  STATE = 'STATE'
}

/** A content section */
export interface ContentSection {
  __typename?: 'ContentSection',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  name?: Maybe<Scalars['String']>,
  position?: Maybe<Scalars['Int']>,
  postedAt?: Maybe<Scalars['Date']>,
  slug?: Maybe<Scalars['String']>,
  /** The type of section this is */
  type?: Maybe<ContentSectionTypes>,
  uuid: Scalars['UUID'],
  contentCategories?: Maybe<Array<Maybe<ContentCategory>>>,
  originContentSection?: Maybe<ContentSection>,
  /** This is the content question that'll appear as the default section view. i.e /learn/slug */
  defaultContentQuestion?: Maybe<ContentQuestion>,
}


/** A content section */
export interface ContentSectionContentCategoriesArgs {
  filter?: Maybe<ContentSectionContentCategoryFilterType>
}

export interface ContentSectionContentCategoryFilterType {
  /** Has been archived */
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Has been posted */
  isPosted?: Maybe<Scalars['Boolean']>,
}

export interface ContentSectionFilterInput {
  /** Slug or UUID of an answer in the desired content section */
  contentAnswerSlugOrUuid?: Maybe<Scalars['String']>,
  /** Has been archived */
  isArchived?: Maybe<Scalars['Boolean']>,
  /** Product filter for content sections */
  product?: Maybe<ContentSectionProductInput>,
  /** Slug filter for content sections */
  slug?: Maybe<StringInput>,
  /** Type filter for content sections */
  type?: Maybe<ContentSectionTypeInput>,
  /** UUID filter for content sections */
  uuid?: Maybe<UuidInput>,
}

export interface ContentSectionProductInput {
  eq?: Maybe<Products>,
  in?: Maybe<Array<Products>>,
}

export interface ContentSectionTypeInput {
  eq?: Maybe<ContentSectionTypes>,
  in?: Maybe<Array<ContentSectionTypes>>,
}

export enum ContentSectionTypes {
  LEARN = 'LEARN',
  MANUAL = 'MANUAL',
  SUPPORT = 'SUPPORT'
}

/** A content step */
export interface ContentStep {
  __typename?: 'ContentStep',
  createdAt?: Maybe<Scalars['Date']>,
  entityType?: Maybe<ContentStepEntityTypes>,
  position?: Maybe<Scalars['Int']>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The building this content step is related to */
  building?: Maybe<Building>,
  /** The city this content step is related to */
  city?: Maybe<City>,
  /** The rules in this content step */
  contentRules?: Maybe<Array<Maybe<ContentRule>>>,
  /** The property manager this content step is related to */
  propertyManager?: Maybe<PropertyManager>,
  /** The state this content step is related to */
  state?: Maybe<State>,
}

export enum ContentStepEntityTypes {
  BUILDING = 'BUILDING',
  CITY = 'CITY',
  PROPERTYMANAGER = 'PROPERTYMANAGER',
  STATE = 'STATE'
}

/** A conversation between two Caretaker users */
export interface Conversation {
  __typename?: 'Conversation',
  createdAt?: Maybe<Scalars['Date']>,
  supportRequestedAt?: Maybe<Scalars['Date']>,
  token?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The related users conversation data */
  conversationUsers?: Maybe<Array<Maybe<ConversationUser>>>,
  flip?: Maybe<Flip>,
  /** The listing associated with this thread */
  listing?: Maybe<Listing>,
  messages?: Maybe<MessageConnection>,
  users?: Maybe<Array<Maybe<Account>>>,
}


/** A conversation between two Caretaker users */
export interface ConversationMessagesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  type_in?: Maybe<Array<Maybe<MessageTypes>>>
}

/** A connection to a list of items. */
export interface ConversationConnection {
  __typename?: 'ConversationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ConversationEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface ConversationEdge {
  __typename?: 'ConversationEdge',
  /** The item at the end of the edge */
  node?: Maybe<Conversation>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A Caretaker user's additonal conversation metadata. */
export interface ConversationUser {
  __typename?: 'ConversationUser',
  isOwner?: Maybe<Scalars['Boolean']>,
  readUpTo?: Maybe<Scalars['Date']>,
  user?: Maybe<Account>,
}

export interface CoordinatePairType {
  __typename?: 'CoordinatePairType',
  lat?: Maybe<Scalars['Float']>,
  lng?: Maybe<Scalars['Float']>,
}

export interface CopyPhotoToListingInputType {
  listingUuid: Scalars['UUID'],
}

export interface CreateAccessInstructionInput {
  accessorEntityType?: Maybe<AccessInstructionAccessorEntityTypes>,
  accessorEntityUuid?: Maybe<Scalars['UUID']>,
  entityType: AccessInstructionEntityTypes,
  entityUuid: Scalars['UUID'],
  notes?: Maybe<Scalars['String']>,
}

export interface CreateAccountAddressUnitInputType {
  addressUuid: Scalars['UUID'],
  unitName?: Maybe<Scalars['String']>,
  tag: Scalars['String'],
}

export interface CreateAddressUnitAmenityInput {
  amenityUuid: Scalars['UUID'],
  addressUnitUuid: Scalars['UUID'],
}

export interface CreateAddressUnitRoomInput {
  addressUnitUuid: Scalars['UUID'],
  roomType: AddressUnitRoomTypes,
}

export interface CreateApplicationInput {
  /** The "uuid" of listing this application belongs to. */
  listingUuid: Scalars['UUID'],
  /** The draft lease to be created */
  lease: CreateApplicationLeaseInput,
}

export interface CreateApplicationLeaseInput {
  startsAt?: Maybe<Scalars['Date']>,
  endsAt?: Maybe<Scalars['Date']>,
}

export interface CreateBuildingAmenityInput {
  amenityUuid: Scalars['UUID'],
  buildingUuid: Scalars['UUID'],
}

export interface CreateCalendarInput {
  /** The entity this calendar is related to */
  entity?: Maybe<CalendarEntityInput>,
}

export interface CreateClaimInputType {
  /** The uuid of the security deposit. */
  securityDepositUuid: Scalars['UUID'],
  /** A description of what this claim is for. */
  description: Scalars['String'],
  /** The amount being claimed. */
  amountInCents: Scalars['Float'],
}

export interface CreateConditionReportInput {
  addressUnitRoomUuid: Scalars['UUID'],
  conditionInspectionUuid?: Maybe<Scalars['UUID']>,
  description: Scalars['String'],
  fixedConditionReportUuid?: Maybe<Scalars['String']>,
  originConditionReportUuid?: Maybe<Scalars['String']>,
  reportType: ConditionReportTypes,
  requiredServiceType: ConditionReportRequiredServiceTypes,
}

export interface CreateConversationInputType {
  /** The "uuid" of listing this conversation belongs to. */
  listingUuid?: Maybe<Scalars['UUID']>,
  /** The message text to send in the created conversation */
  messageText?: Maybe<Scalars['String']>,
}

export interface CreateCreditReportInputType {
  city: Scalars['String'],
  income: Scalars['String'],
  ssn: Scalars['String'],
  state: Scalars['String'],
  streetAddress1: Scalars['String'],
  zipCode: Scalars['String'],
}

export interface CreateDocumentInput {
  bucket?: Maybe<Scalars['String']>,
  displayName?: Maybe<Scalars['String']>,
  filename?: Maybe<Scalars['String']>,
  flipUuid?: Maybe<Scalars['UUID']>,
  fullyExecutedAt?: Maybe<Scalars['Date']>,
  isSigningEnabled?: Maybe<Scalars['Boolean']>,
  key?: Maybe<Scalars['String']>,
  tag: DocumentTags,
  uploadUuid: Scalars['UUID'],
}

export interface CreateIncomeSourceInputType {
  active?: Maybe<Scalars['Boolean']>,
  annualIncome?: Maybe<Scalars['Float']>,
  bonus?: Maybe<Scalars['Float']>,
  displayCompany?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  days?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  type?: Maybe<Scalars['String']>,
}

export interface CreateIssueInput {
  description: Scalars['String'],
  addressUnitUuid: Scalars['UUID'],
  serviceType: IssueServiceTypes,
}

export interface CreateIssueResolutionInput {
  notes: Scalars['String'],
  issueUuid: Scalars['UUID'],
  resolutionType: IssueResolutionTypes,
  serviceType?: Maybe<IssueResolutionServiceTypes>,
}

export interface CreateKeyboxOrderForAddressUnitInput {
  associatedEntityType?: Maybe<OrderAssociatedEntityTypes>,
  associatedEntityUuid?: Maybe<Scalars['UUID']>,
  shippingAddressUnitUuid?: Maybe<Scalars['UUID']>,
  shouldCreateShipment?: Maybe<Scalars['Boolean']>,
}

export interface CreateLeaseInput {
  /** The uuid of the address unit this lease is for */
  addressUnitUuid: Scalars['UUID'],
  /** The uuid of the associated lease document */
  documentUuid?: Maybe<Scalars['UUID']>,
  /** Lease end date */
  endDate?: Maybe<Scalars['Date']>,
  /** The uuid of the associated landlord */
  landlordUuid?: Maybe<Scalars['UUID']>,
  /** The uuid of the associated lessor */
  lessorUuid?: Maybe<Scalars['UUID']>,
  /** The uuid of the associated lessee */
  lesseeUuid?: Maybe<Scalars['UUID']>,
  /** Notes associated with this lease */
  notes?: Maybe<Scalars['String']>,
  /** The rent amount in cents */
  rentInCents: Scalars['Int'],
  /** The security deposit amount in cents */
  securityDepositInCents: Scalars['Int'],
  /** Lease start date */
  startDate?: Maybe<Scalars['Date']>,
  /** The type of this lease */
  type?: Maybe<LeaseTypes>,
}

export interface CreateLeaseInviteInput {
  /** The UUID of the lease */
  leaseUuid: Scalars['String'],
  /** The lessee's email. */
  email: Scalars['String'],
}

export interface CreateLeasesSettingInput {
  isAutopayRequired?: Maybe<Scalars['Boolean']>,
  propertyManagerContractUuid?: Maybe<Scalars['UUID']>,
}

export interface CreateLegacyAddressInputType {
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  state?: Maybe<Scalars['String']>,
  streetAddress1?: Maybe<Scalars['String']>,
  streetAddress2?: Maybe<Scalars['String']>,
  tag?: Maybe<Scalars['String']>,
  zipCode?: Maybe<Scalars['String']>,
}

export interface CreateListingAnswerInput {
  questionUuid: Scalars['UUID'],
  shouldPost: Scalars['Boolean'],
  text: Scalars['String'],
}

export interface CreateListingInputType {
  addressUnitUuid?: Maybe<Scalars['UUID']>,
  apartmentNumber?: Maybe<Scalars['String']>,
  areaCode?: Maybe<Scalars['String']>,
  bathrooms?: Maybe<Scalars['Float']>,
  bedrooms?: Maybe<Scalars['Int']>,
  city?: Maybe<Scalars['String']>,
  defaultSecurityDepositInCents?: Maybe<Scalars['Int']>,
  defaultUpfrontRentInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  depositInCents?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  directContact?: Maybe<Scalars['Boolean']>,
  earliestMoveInDate?: Maybe<Scalars['Date']>,
  /** Used for Caretaker team members to manually add extended viewing notes */
  extendedViewingNotes?: Maybe<Scalars['String']>,
  furnishingType?: Maybe<ListingFurnishingTypes>,
  /** Used for Caretaker team members to manually review listings with high quality photos */
  hasQualityPhotos?: Maybe<Scalars['Boolean']>,
  headline?: Maybe<Scalars['String']>,
  isLeaseExtendableWithLandlord?: Maybe<Scalars['Boolean']>,
  /** DEPRECATED: Dead. */
  isRepostable?: Maybe<Scalars['Boolean']>,
  /** Used for Caretaker team members to manually associate a keycafe key */
  keycafeKeyId?: Maybe<Scalars['Int']>,
  latitude?: Maybe<Scalars['Float']>,
  leaseEndDate?: Maybe<Scalars['Date']>,
  leaseType?: Maybe<ListingLeaseTypes>,
  listingType?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['Float']>,
  /** DEPRECATED: Dead. */
  managerEmail?: Maybe<Scalars['String']>,
  maxLeaseTerm?: Maybe<Scalars['Int']>,
  minLeaseTerm?: Maybe<Scalars['Int']>,
  neighborhood?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  priceInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  pricingType?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  propertyType?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  referralEmail?: Maybe<Scalars['String']>,
  rentInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  requiresDeposit?: Maybe<Scalars['Boolean']>,
  /** DEPRECATED: Dead. */
  rewardInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Moved to `defaultSecurityDepositInCents`. */
  securityDepositInCents?: Maybe<Scalars['Int']>,
  shouldRequestLandlordApproval?: Maybe<Scalars['Boolean']>,
  state?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  subleaseReviewedAt?: Maybe<Scalars['Date']>,
  totalRooms?: Maybe<Scalars['Int']>,
  /** Used for Caretaker team members to manually add viewing notes */
  viewingNotes?: Maybe<Scalars['String']>,
}

export interface CreateListingQuestionInput {
  listingUuid: Scalars['UUID'],
  prompt: Scalars['String'],
}

export interface CreateMaintenanceSettingInput {
  propertyManagerContractUuid?: Maybe<Scalars['UUID']>,
}

export interface CreateNotificationRequestForAddressUnitInput {
  type: NotificationRequestTypes,
}

export interface CreateOrganizationInput {
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
}

export interface CreateOrganizationPersonInput {
  organizationUuid: Scalars['UUID'],
  personUuid: Scalars['UUID'],
  title?: Maybe<Scalars['String']>,
  workAddressUnitUuid?: Maybe<Scalars['UUID']>,
}

export interface CreatePaymentScheduleForTakeoverInput {
  paymentMethodId: Scalars['String'],
}

export interface CreatePaymentSubscriptionInput {
  paymentMethodId?: Maybe<Scalars['String']>,
  plan: PaymentSubscriptionPlans,
  quantity: Scalars['Int'],
}

export interface CreatePersonInput {
  bio?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName: Scalars['String'],
  lastName?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
}

export interface CreatePhotoInputType {
  height?: Maybe<Scalars['Float']>,
  key: Scalars['String'],
  entityUuid: Scalars['UUID'],
  /** The type of the associated entity */
  entityType: PhotoEntityTypes,
  tagUuids?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  position?: Maybe<Scalars['Int']>,
  width?: Maybe<Scalars['Float']>,
}

export interface CreatePropertyManagerContractInput {
  addressUnitUuid: Scalars['UUID'],
  propertyManagerUuid: Scalars['UUID'],
  startsAt?: Maybe<Scalars['Date']>,
}

export interface CreatePropertyManagerInputType {
  organizationUuid: Scalars['UUID'],
}

export interface CreateQualificationInput {
  subtype?: Maybe<QualificationSubtypes>,
  thresholdModifier: QualificationThresholdModifiers,
  thresholdType?: Maybe<QualificationThresholdTypes>,
  thresholdUnits?: Maybe<QualificationThresholdUnits>,
  thresholdValue: Scalars['Int'],
  thresholdVariable?: Maybe<QualificationThresholdVariables>,
  type: QualificationTypes,
}

export interface CreateRentalFormInputType {
  accessUrl?: Maybe<Scalars['String']>,
  isCertifiedMail?: Maybe<Scalars['Boolean']>,
  isGeneric?: Maybe<Scalars['Boolean']>,
  isThirdParty?: Maybe<Scalars['Boolean']>,
  medium?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  thirdPartyName?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  uploadUuid?: Maybe<Scalars['String']>,
}

export interface CreateRentalPolicyInputType {
  entityType: RentalPolicyEntityTypes,
  entityUuid: Scalars['UUID'],
  isAllowed?: Maybe<Scalars['Boolean']>,
  isRecommended?: Maybe<Scalars['Boolean']>,
  type: RentalPolicyTypes,
}

export interface CreateRentPaymentInput {
  amountInCents?: Maybe<Scalars['Int']>,
  leaseUuid: Scalars['UUID'],
  paymentMethodId: Scalars['String'],
}

export interface CreateRequirementInput {
  entityType: RequirementEntityTypes,
  entityUuid: Scalars['UUID'],
  qualificationUuid: Scalars['UUID'],
}

export interface CreateReviewInputType {
  entityType: ReviewEntityTypes,
  entityUuid: Scalars['UUID'],
  headline?: Maybe<Scalars['String']>,
  rating: Scalars['Float'],
  text: Scalars['String'],
}

export interface CreateSavedSearchInputType {
  name: Scalars['String'],
  shouldSendDailyEmail?: Maybe<Scalars['Boolean']>,
  shouldSendPushNotifications?: Maybe<Scalars['Boolean']>,
  shouldSendSmsNotifications?: Maybe<Scalars['Boolean']>,
  alertFrequency?: Maybe<SavedSearchAlertFrequencyTypes>,
  searchFilters: SearchResultFilterType,
}

export interface CreateSecurityDepositInputType {
  /** The flip this security deposit corresponds to. */
  leaseUuid: Scalars['UUID'],
  /** The payment method id */
  paymentMethodId: Scalars['String'],
}

export interface CreateServicePreferenceInput {
  notes?: Maybe<Scalars['String']>,
  propertyManagerContractUuids?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  serviceProviderUuid: Scalars['UUID'],
  serviceType: ServiceTypes,
}

export interface CreateServiceProviderInput {
  organizationUuid: Scalars['UUID'],
  /** The bounding box that this service provider services */
  serviceAreaBoundingBox?: Maybe<GeoPolygonInput>,
  serviceType: ServiceTypes,
}

export interface CreateSmartLockOrderForAddressUnitInput {
  associatedEntityType?: Maybe<OrderAssociatedEntityTypes>,
  associatedEntityUuid?: Maybe<Scalars['UUID']>,
  productUuid: Scalars['UUID'],
  shippingAddressUnitUuid?: Maybe<Scalars['UUID']>,
  shouldCreateShipment?: Maybe<Scalars['Boolean']>,
}

export interface CreateSpendingThresholdInput {
  maintenanceSettingUuid: Scalars['UUID'],
  issueThresholdType?: Maybe<SpendingThresholdTypes>,
  issueThresholdAmount?: Maybe<Scalars['Int']>,
  annualThresholdType?: Maybe<SpendingThresholdTypes>,
  annualThresholdAmount?: Maybe<Scalars['Int']>,
}

export interface CreateStripeAccountLinkInput {
  /** The URL to send the user to if the link created from this mutation expires. */
  refreshUrl: Scalars['String'],
  /** The URL to send the user to when they leave or complete the linked flow. */
  returnUrl: Scalars['String'],
  /** The type of Stripe account link to create. */
  type: StripeAccountLinkTypes,
}

export interface CreateStripeFinancialConnectionSessionInput {
  /** The type of Stripe account holder to create a financial connection for. */
  accountHolderType: StripeAccountHolderTypes,
}

export interface CreateStripeSetupIntentInput {
  /** The type of Stripe account holder to create a setup intent for. */
  accountHolderType: StripeAccountHolderTypes,
  /** The payment methods that can be setup with this setup intent (defaults to card) */
  paymentMethodTypes?: Maybe<Array<Maybe<StripePaymentMethodTypes>>>,
}

export interface CreateTakeoverRequestInputType {
  addressUuid: Scalars['UUID'],
  availabilityType: TakeoverRequestAvailabilityTypes,
  bedroomCount: Scalars['Int'],
  email?: Maybe<Scalars['String']>,
  furnishingType?: Maybe<ListingFurnishingTypes>,
  leaseEndDate: Scalars['Date'],
  listingUuid?: Maybe<Scalars['UUID']>,
  note?: Maybe<Scalars['String']>,
  rentInCents: Scalars['Int'],
  unitName: Scalars['String'],
}

export interface CreateThreadInputType {
  /** The "uuid" of listing this thread belongs to. */
  listingUuid?: Maybe<Scalars['UUID']>,
  /** The message text to send in the created thread */
  messageText?: Maybe<Scalars['String']>,
}

export interface CreateUploadInputType {
  documentTypeUuid?: Maybe<Scalars['UUID']>,
  fileType?: Maybe<Scalars['String']>,
  filename: Scalars['String'],
  tag: UploadTags,
  url: Scalars['String'],
}

export interface CreateUserAddressUnitInputType {
  addressUuid: Scalars['UUID'],
  unitName?: Maybe<Scalars['String']>,
  tag: Scalars['String'],
}

export interface CreateVerificationForListingInput {
  listingUuid: Scalars['String'],
  method: Scalars['String'],
  paymentMethodId?: Maybe<Scalars['String']>,
  ssn?: Maybe<Scalars['String']>,
}

export interface CreateVerificationInput {
  verificationMethodUuid: Scalars['UUID'],
  verifiedEntityType: VerificationVerifiedEntityTypes,
  verifiedEntityUuid: Scalars['UUID'],
}

export interface CreateViewingForListingInput {
  listingUuid: Scalars['UUID'],
  timeSlotUuid: Scalars['UUID'],
}

export interface CreateVoteInputType {
  /** The associated entity */
  entity: VoteEntityType,
  /** Sentiment associated to the entity */
  sentiment: Scalars['Int'],
}

export interface CreditReport {
  __typename?: 'CreditReport',
  uuid: Scalars['UUID'],
}


export interface DateInputType {
  eq?: Maybe<Scalars['Date']>,
  lt?: Maybe<Scalars['Date']>,
  lte?: Maybe<Scalars['Date']>,
  gt?: Maybe<Scalars['Date']>,
  gte?: Maybe<Scalars['Date']>,
}

export interface Deposit {
  __typename?: 'Deposit',
  authorizedAt?: Maybe<Scalars['Date']>,
  cancelledAt?: Maybe<Scalars['Date']>,
  capturedAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  depositAmountInCents?: Maybe<Scalars['Int']>,
  depositAmountInDollars?: Maybe<Scalars['Float']>,
  expiredAt?: Maybe<Scalars['Date']>,
  inReviewAt?: Maybe<Scalars['Date']>,
  note?: Maybe<Scalars['String']>,
  referenceId?: Maybe<Scalars['String']>,
  refundedAmountInCents?: Maybe<Scalars['Int']>,
  refundedAmountInDollars?: Maybe<Scalars['Float']>,
  refundedAt?: Maybe<Scalars['Date']>,
  rejectedAt?: Maybe<Scalars['Date']>,
  releaseApprovedAt?: Maybe<Scalars['Date']>,
  releasedAmountInCents?: Maybe<Scalars['Int']>,
  releasedAmountInDollars?: Maybe<Scalars['Float']>,
  releasedAt?: Maybe<Scalars['Date']>,
  releaseRequestedAt?: Maybe<Scalars['Date']>,
  serviceFeeInCents?: Maybe<Scalars['Int']>,
  serviceFeeInDollars?: Maybe<Scalars['Float']>,
  status?: Maybe<DepositStatuses>,
  totalInCents?: Maybe<Scalars['Int']>,
  totalInDollars?: Maybe<Scalars['Float']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  flip?: Maybe<Flip>,
  transaction?: Maybe<Transaction>,
  user?: Maybe<Account>,
}

export enum DepositStatuses {
  /** Renter has authorized their deposit */
  AUTHORIZED = 'AUTHORIZED',
  /** Renter has cancelled their deposit */
  CANCELLED = 'CANCELLED',
  /** Lister has accepted renter's deposit */
  CAPTURED = 'CAPTURED',
  /** Renter's deposit has expired */
  EXPIRED = 'EXPIRED',
  /** Renter's deposit charge failed to go through */
  FAILED = 'FAILED',
  /** Deposit was refunded to renter */
  REFUNDED = 'REFUNDED',
  /** Renter's deposit was rejected by the lister */
  REJECTED = 'REJECTED',
  /** Deposit has been released to lister */
  RELEASED = 'RELEASED',
  /** Deposit has been approved for release by both the renter and the lister */
  RELEASING = 'RELEASING',
  /** Deposit is under review by the team at Caretaker */
  REVIEWING = 'REVIEWING'
}

export interface DetachPhoneNumberFromOrganizationInput {
  organizationUuid: Scalars['UUID'],
}

export interface DetachPhoneNumberFromPersonInput {
  personUuid: Scalars['UUID'],
}

export interface DetachTimeSlotsFromCalendarInput {
  daysOfWeek: Array<Scalars['Int']>,
  eligibleEntityTypes?: Maybe<Array<Maybe<CalendarTimeSlotEntityTypes>>>,
  endingHour: Scalars['Int'],
  startingHour: Scalars['Int'],
}

export interface DetachVerificationMethodFromRequirementInput {
  verificationMethodUuid: Scalars['UUID'],
}

export interface Device {
  __typename?: 'Device',
  createdAt?: Maybe<Scalars['Date']>,
  deviceToken?: Maybe<Scalars['String']>,
  identifier?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
}

/** Types of devices */
export enum DeviceTypes {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export interface Document {
  __typename?: 'Document',
  createdAt?: Maybe<Scalars['Date']>,
  displayName?: Maybe<Scalars['String']>,
  filename?: Maybe<Scalars['String']>,
  fileType?: Maybe<Scalars['String']>,
  tag?: Maybe<DocumentTags>,
  type?: Maybe<DocumentTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
}

export interface DocumentEsignedUrl {
  __typename?: 'DocumentEsignedUrl',
  fileUrl?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
}

/** Esignature embed properties */
export interface DocumentEsignEmbedUrl {
  __typename?: 'DocumentEsignEmbedUrl',
  clientId?: Maybe<Scalars['String']>,
  skipDomainVerification?: Maybe<Scalars['Boolean']>,
  signUrl?: Maybe<Scalars['String']>,
}

/** Tags for documents */
export enum DocumentTags {
  FREE_SUBLET_AGREEMENT = 'FREE_SUBLET_AGREEMENT',
  GENERIC = 'GENERIC',
  INSTANT_AGREEMENT = 'INSTANT_AGREEMENT',
  LEASE_AGREEMENT = 'LEASE_AGREEMENT',
  PREQUAL = 'PREQUAL',
  REQUEST_FOR_CONSENT = 'REQUEST_FOR_CONSENT',
  SUBLET_AGREEMENT = 'SUBLET_AGREEMENT',
  TEST = 'TEST',
  UPLOAD = 'UPLOAD'
}

/** A document type */
export interface DocumentType {
  __typename?: 'DocumentType',
  createdAt: Scalars['Date'],
  description?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  slug: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
}

/** A connection to a list of items. */
export interface DocumentTypeConnection {
  __typename?: 'DocumentTypeConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentTypeEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface DocumentTypeEdge {
  __typename?: 'DocumentTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<DocumentType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** Types of documents */
export enum DocumentTypes {
  GENERATED = 'GENERATED',
  UPLOAD = 'UPLOAD'
}

export interface DocumentTypesFilterType {
  createdAt?: Maybe<DateInputType>,
  name?: Maybe<SearchStringInput>,
  smart?: Maybe<SearchStringInput>,
  updatedAt?: Maybe<DateInputType>,
}

export enum DocumentTypesSortByEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum DocumentTypesSortDirectionEnum {
  /** Ascending */
  ASC = 'ASC',
  /** Descending */
  DESC = 'DESC'
}

/** A document user's additional metadata */
export interface DocumentUser {
  __typename?: 'DocumentUser',
  signedAt?: Maybe<Scalars['Date']>,
  user?: Maybe<Account>,
}

export interface EnableAutopayInputType {
  /** The Stripe payment method id */
  autopayPaymentMethodId: Scalars['String'],
  /** The delta in days between charge day and due date */
  deltaPaymentDaysFromDueDate?: Maybe<Scalars['Int']>,
}

export interface FetchOrCreateAddressInputType {
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  googlePlaceId?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  state?: Maybe<Scalars['String']>,
  streetAddress1?: Maybe<Scalars['String']>,
  zipCode?: Maybe<Scalars['String']>,
}

export interface FetchOrCreateAddressUnitInputType {
  addressUuid: Scalars['UUID'],
  name?: Maybe<Scalars['String']>,
  unitType?: Maybe<AddressUnitUnitTypes>,
}

export interface FetchOrCreateConditionInspectionInput {
  addressUnitUuid: Scalars['UUID'],
}

export interface FetchOrCreateListingForPropertyManagerContractInput {
  addressUnitUuid?: Maybe<Scalars['UUID']>,
  apartmentNumber?: Maybe<Scalars['String']>,
  areaCode?: Maybe<Scalars['String']>,
  bathrooms?: Maybe<Scalars['Float']>,
  bedrooms?: Maybe<Scalars['Int']>,
  city?: Maybe<Scalars['String']>,
  defaultSecurityDepositInCents?: Maybe<Scalars['Int']>,
  defaultUpfrontRentInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  depositInCents?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  directContact?: Maybe<Scalars['Boolean']>,
  earliestMoveInDate?: Maybe<Scalars['Date']>,
  /** Used for Caretaker team members to manually add extended viewing notes */
  extendedViewingNotes?: Maybe<Scalars['String']>,
  furnishingType?: Maybe<ListingFurnishingTypes>,
  /** Used for Caretaker team members to manually review listings with high quality photos */
  hasQualityPhotos?: Maybe<Scalars['Boolean']>,
  headline?: Maybe<Scalars['String']>,
  isLeaseExtendableWithLandlord?: Maybe<Scalars['Boolean']>,
  /** DEPRECATED: Dead. */
  isRepostable?: Maybe<Scalars['Boolean']>,
  /** Used for Caretaker team members to manually associate a keycafe key */
  keycafeKeyId?: Maybe<Scalars['Int']>,
  latitude?: Maybe<Scalars['Float']>,
  leaseEndDate?: Maybe<Scalars['Date']>,
  leaseType?: Maybe<ListingLeaseTypes>,
  listingType?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['Float']>,
  /** DEPRECATED: Dead. */
  managerEmail?: Maybe<Scalars['String']>,
  maxLeaseTerm?: Maybe<Scalars['Int']>,
  minLeaseTerm?: Maybe<Scalars['Int']>,
  neighborhood?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  priceInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  pricingType?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  propertyType?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  referralEmail?: Maybe<Scalars['String']>,
  rentInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  requiresDeposit?: Maybe<Scalars['Boolean']>,
  /** DEPRECATED: Dead. */
  rewardInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Moved to `defaultSecurityDepositInCents`. */
  securityDepositInCents?: Maybe<Scalars['Int']>,
  shouldRequestLandlordApproval?: Maybe<Scalars['Boolean']>,
  state?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  subleaseReviewedAt?: Maybe<Scalars['Date']>,
  totalRooms?: Maybe<Scalars['Int']>,
  /** Used for Caretaker team members to manually add viewing notes */
  viewingNotes?: Maybe<Scalars['String']>,
  /** The "uuid" of the property manager contract */
  propertyManagerContractUuid: Scalars['UUID'],
}

export interface FetchOrCreatePhoneNumberInput {
  phoneNumber: Scalars['String'],
  type: PhoneNumberTypes,
}

/** A Caretaker tracks a user through the application and sublet process for a given listing */
export interface Flip {
  __typename?: 'Flip',
  rentGuaranteed?: Maybe<Scalars['Boolean']>,
  depositInCents?: Maybe<Scalars['Float']>,
  depositInDollars?: Maybe<Scalars['Float']>,
  depositRequestedAt?: Maybe<Scalars['Date']>,
  prequalReportUrl?: Maybe<Scalars['String']>,
  securityDepositAmountInCents?: Maybe<Scalars['Float']>,
  serviceFeeInCents?: Maybe<Scalars['Int']>,
  serviceFeeInDollars?: Maybe<Scalars['Float']>,
  skippedDepositAt?: Maybe<Scalars['Date']>,
  shouldForwardToLandlord?: Maybe<Scalars['Boolean']>,
  /** A list of deposits associated with this flip */
  deposits?: Maybe<Array<Maybe<Deposit>>>,
  payments?: Maybe<PaymentConnection>,
  /** The security deposit most recently associated with a lease on this Flip */
  securityDeposit?: Maybe<SecurityDepositType>,
  acceptedAt?: Maybe<Scalars['Date']>,
  applicationRequestedAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  creditScoreRequestedAt?: Maybe<Scalars['Date']>,
  employmentRequestedAt?: Maybe<Scalars['Date']>,
  flippedAt?: Maybe<Scalars['Date']>,
  isEligibleForBuildingReview?: Maybe<Scalars['Boolean']>,
  isArchivedByLister?: Maybe<Scalars['Boolean']>,
  isArchivedByRenter?: Maybe<Scalars['Boolean']>,
  isRentGuaranteed?: Maybe<Scalars['Boolean']>,
  listerLastViewedAt?: Maybe<Scalars['Date']>,
  renterLastViewedAt?: Maybe<Scalars['Date']>,
  rejectedAt?: Maybe<Scalars['Date']>,
  socialRequestedAt?: Maybe<Scalars['Date']>,
  status?: Maybe<FlipStatuses>,
  submittedAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  upfrontRentRequestedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The conversation for this flip */
  conversation?: Maybe<Conversation>,
  documents?: Maybe<Array<Maybe<FlipDocument>>>,
  document?: Maybe<FlipDocument>,
  /** A list of leases associated with this flip */
  leases?: Maybe<Array<Maybe<PrivateLease>>>,
  /** The lease associated with this flip. */
  lease?: Maybe<PrivateLease>,
  /** The listers profile */
  lister?: Maybe<FlipProfile>,
  /** The listing associated with this flip */
  listing?: Maybe<Listing>,
  /** The applicants/renters profile */
  renter?: Maybe<FlipProfile>,
  /** The thread for this flip */
  thread?: Maybe<Thread>,
}


/** A Caretaker tracks a user through the application and sublet process for a given listing */
export interface FlipPaymentsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A Caretaker tracks a user through the application and sublet process for a given listing */
export interface FlipDocumentsArgs {
  isArchived?: Maybe<Scalars['Boolean']>
}


/** A Caretaker tracks a user through the application and sublet process for a given listing */
export interface FlipDocumentArgs {
  uuid: Scalars['UUID']
}


/** A Caretaker tracks a user through the application and sublet process for a given listing */
export interface FlipLeasesArgs {
  leaseType?: Maybe<LeaseTypes>
}

/** A connection to a list of items. */
export interface FlipConnection {
  __typename?: 'FlipConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FlipEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

export interface FlipDocument {
  __typename?: 'FlipDocument',
  createdAt?: Maybe<Scalars['Date']>,
  displayName?: Maybe<Scalars['String']>,
  filename?: Maybe<Scalars['String']>,
  fileType?: Maybe<Scalars['String']>,
  tag?: Maybe<DocumentTags>,
  type?: Maybe<DocumentTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  accessToken?: Maybe<Scalars['String']>,
  accessUrl?: Maybe<Scalars['String']>,
  /** This field indicates if the document has been activated for esignatures with Hellosign */
  areEsignaturesEnabled?: Maybe<Scalars['Boolean']>,
  downloadUrl?: Maybe<Scalars['String']>,
  eSignedUrl?: Maybe<Scalars['String']>,
  eSigningEmbedUrl?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  fullyExecutedAt?: Maybe<Scalars['Date']>,
  /** This field indicates if the user has marked the document as something they would like to sign */
  isSigningEnabled?: Maybe<Scalars['Boolean']>,
  viewerHasSigned?: Maybe<Scalars['Boolean']>,
  documentUsers?: Maybe<Array<Maybe<DocumentUser>>>,
  flip?: Maybe<Flip>,
  lease?: Maybe<PrivateLease>,
  upload?: Maybe<Upload>,
  user?: Maybe<Account>,
  users?: Maybe<Array<Maybe<Account>>>,
}

/** An edge in a connection. */
export interface FlipEdge {
  __typename?: 'FlipEdge',
  /** The item at the end of the edge */
  node?: Maybe<Flip>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A Caretaker user's application profile. */
export interface FlipProfile {
  __typename?: 'FlipProfile',
  averageResponseTime?: Maybe<Scalars['Int']>,
  averageResponseTimeFormatted?: Maybe<Scalars['String']>,
  authorBio?: Maybe<Scalars['String']>,
  authorRole?: Maybe<Scalars['String']>,
  authorSocialLink?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  defaultPhotoUrl?: Maybe<Scalars['String']>,
  educationLevel?: Maybe<Scalars['String']>,
  educationLevelString?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  hasConnectedFacebook?: Maybe<Scalars['Boolean']>,
  hasConnectedLinkedIn?: Maybe<Scalars['Boolean']>,
  hasEmail?: Maybe<Scalars['Boolean']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  hasPhoneNumber?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  lastSeen?: Maybe<Scalars['Date']>,
  locationString?: Maybe<Scalars['String']>,
  photoUrl?: Maybe<Scalars['String']>,
  smokes?: Maybe<Scalars['Boolean']>,
  uuid: Scalars['UUID'],
  viewerHasReviewed?: Maybe<Scalars['Boolean']>,
  yearsRenting?: Maybe<Scalars['Int']>,
  photo?: Maybe<Photo>,
  listings?: Maybe<ListingConnection>,
  /** The organization associated with this profile */
  organization?: Maybe<Organization>,
  /** A list of reviews for this user. */
  reviews?: Maybe<ReviewConnection>,
  facebookId?: Maybe<Scalars['String']>,
  activeCreditReport?: Maybe<ApplicationCreditReport>,
  creditReports?: Maybe<Array<Maybe<ApplicationCreditReport>>>,
  incomeSources?: Maybe<Array<Maybe<ApplicationIncomeSource>>>,
}


/** A Caretaker user's application profile. */
export interface FlipProfileListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A Caretaker user's application profile. */
export interface FlipProfileReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

export enum FlipStatuses {
  /** Tenant has accepted the user's application */
  ACCEPTED = 'ACCEPTED',
  /** User has began the application process */
  APPLIED = 'APPLIED',
  /** Deposit/paperwork complete, user "probably" moved in to listing */
  FLIPPED = 'FLIPPED',
  /** Application is in third party review */
  IN_REVIEW = 'IN_REVIEW',
  LEAD = 'LEAD',
  LEGACY = 'LEGACY',
  /** Application has been opened */
  OPENED = 'OPENED',
  /** Tenant has rejected the user's application */
  REJECTED = 'REJECTED',
  /** User has withdrawn their application */
  WITHDRAWN = 'WITHDRAWN'
}

export interface FurnishingInputType {
  eq?: Maybe<ListingFurnishingTypes>,
  in?: Maybe<Array<ListingFurnishingTypes>>,
}

/** A min/max set of lat/long coordinates. */
export interface GeoBoxInput {
  min: GeoPointInputType,
  max: GeoPointInputType,
}

/** A set of lat/long coordinates. */
export interface GeoPointInputType {
  lat: Scalars['Float'],
  long: Scalars['Float'],
}

/** A set of lat/long coordinates. */
export interface GeoPolygonInput {
  points: Array<GeoPointInputType>,
}

/** A Google Hires department */
export interface GoogleDepartment {
  __typename?: 'GoogleDepartment',
  name?: Maybe<Scalars['String']>,
  jobPostings?: Maybe<Array<Maybe<GoogleJobPosting>>>,
}

/** A Google Hires job posting */
export interface GoogleJobPosting {
  __typename?: 'GoogleJobPosting',
  datePosted?: Maybe<Scalars['Date']>,
  department?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  employmentType?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['String']>,
  shortDescription?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  estimatedSalary?: Maybe<GoogleMonetaryAmount>,
  jobLocation?: Maybe<GooglePostalAddress>,
}

/** A Google Hires postal address */
export interface GoogleMonetaryAmount {
  __typename?: 'GoogleMonetaryAmount',
  currency?: Maybe<Scalars['String']>,
  maxValue?: Maybe<Scalars['Int']>,
  minValue?: Maybe<Scalars['Int']>,
}

/** A Google Hires postal address */
export interface GooglePostalAddress {
  __typename?: 'GooglePostalAddress',
  addressCountry?: Maybe<Scalars['String']>,
  addressLocality?: Maybe<Scalars['String']>,
  addressRegion?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
}

/** HTML content */
export interface HtmlContent {
  __typename?: 'HtmlContent',
  content?: Maybe<Scalars['String']>,
}

/** A platform independent icon resource for mobile and web display */
export interface IconResource {
  __typename?: 'IconResource',
  apartmentApp?: Maybe<IconResourceUri>,
  caretaker?: Maybe<IconResourceUri>,
}

/** An icon's URIs for potential different themes */
export interface IconResourceUri {
  __typename?: 'IconResourceURI',
  primaryUrl?: Maybe<Scalars['String']>,
}

/** An income source */
export interface IncomeSource {
  __typename?: 'IncomeSource',
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
}

export interface IndividualAddressInput {
  city?: Maybe<Scalars['String']>,
  line1?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
}

export interface IndividualDobInput {
  day: Scalars['Int'],
  month: Scalars['Int'],
  year: Scalars['Int'],
}

export interface IndividualInput {
  address?: Maybe<IndividualAddressInput>,
  dob?: Maybe<IndividualDobInput>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  ssnLast4?: Maybe<Scalars['String']>,
  verification?: Maybe<IndividualVerificationInput>,
}

export interface IndividualVerificationInput {
  /** Stripe file id of the verification document */
  document?: Maybe<Scalars['String']>,
}

export interface IntegerInput {
  eq?: Maybe<Scalars['Int']>,
  lt?: Maybe<Scalars['Int']>,
  lte?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  gte?: Maybe<Scalars['Int']>,
  in?: Maybe<Array<Scalars['Int']>>,
}

/** An inventory item */
export interface InventoryItem {
  __typename?: 'InventoryItem',
  barcode?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The invoice items related to this inventory item */
  invoiceItems?: Maybe<InvoiceItem>,
  /** The keybox associated with this inventory item */
  keybox?: Maybe<SmartLock>,
  /** The smart lock associated with this inventory item */
  smartLock?: Maybe<SmartLock>,
  /** Inventory item product type */
  product?: Maybe<Product>,
}

export interface InventoryItemsFilterInput {
  isAvailable?: Maybe<Scalars['Boolean']>,
}

/** An invoice */
export interface Invoice {
  __typename?: 'Invoice',
  createdAt: Scalars['Date'],
  description?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  provider: InvoiceProviders,
  providerId: Scalars['String'],
  status: InvoiceStatuses,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The billed items listen on this invoice */
  invoiceItems?: Maybe<Array<Maybe<InvoiceItem>>>,
  providerInvoice?: Maybe<StripeInvoice>,
  /** Account receiving the invoice */
  recipientAccount?: Maybe<Account>,
  /** Transactions related to the invoice */
  transactions?: Maybe<Array<Maybe<Transaction>>>,
}

/** An invoice item */
export interface InvoiceItem {
  __typename?: 'InvoiceItem',
  amountInCents: Scalars['Int'],
  createdAt: Scalars['Date'],
  description?: Maybe<Scalars['String']>,
  entityTable?: Maybe<InvoiceItemEntityTables>,
  providerId: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The invoice linked to this invoice item */
  invoice?: Maybe<Invoice>,
  /** The inventory item related to this invoice item */
  inventoryItem?: Maybe<InventoryItem>,
  /** The payment subscription related to this invoice item */
  paymentSubscription?: Maybe<PaymentSubscription>,
}

export enum InvoiceItemEntityTables {
  INVENTORYITEMS = 'INVENTORYITEMS',
  PAYMENTSUBSCRIPTIONS = 'PAYMENTSUBSCRIPTIONS'
}

export enum InvoiceProviders {
  STRIPE = 'STRIPE'
}

export enum InvoiceStatuses {
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID'
}

/** The geolocation of the requester's IP address */
export interface IpGeolocation {
  __typename?: 'IpGeolocation',
  city: Scalars['String'],
  countryCode: Scalars['String'],
  countryName: Scalars['String'],
  latitude: Scalars['Float'],
  longitude: Scalars['Float'],
  regionCode: Scalars['String'],
  regionName: Scalars['String'],
  timeZone: Scalars['String'],
  zipCode: Scalars['String'],
}

/** An issue */
export interface Issue {
  __typename?: 'Issue',
  createdAt: Scalars['Date'],
  description: Scalars['String'],
  serviceType?: Maybe<IssueServiceTypes>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The address unit related to this issue */
  addressUnit?: Maybe<PrivateAddressUnit>,
  /** The issued card related to this issue */
  issuedCard?: Maybe<IssuedCard>,
  /** The resolution of this issue */
  issueResolution?: Maybe<IssueResolution>,
  /** The primary photo related to this report */
  photo?: Maybe<Photo>,
  /** The photos related to this report */
  photos?: Maybe<Array<Maybe<Photo>>>,
  /** The reporter of this issue */
  reporterAccount: Account,
}

/** A connection to a list of items. */
export interface IssueConnection {
  __typename?: 'IssueConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IssueEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An issued card */
export interface IssuedCard {
  __typename?: 'IssuedCard',
  createdAt: Scalars['Date'],
  status: IssuedCardStatuses,
  type: IssuedCardTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The issue related to this issued card */
  issue?: Maybe<Issue>,
  /** The issued card authorizations related to this issued card */
  issuedCardAuthorizations?: Maybe<Array<Maybe<IssuedCardAuthorization>>>,
  providerCard?: Maybe<StripeIssuingCard>,
  /** The transactions related to this issued card */
  transactions?: Maybe<Array<Maybe<Transaction>>>,
}

/** An issued card authorization */
export interface IssuedCardAuthorization {
  __typename?: 'IssuedCardAuthorization',
  amountInCents: Scalars['Int'],
  createdAt: Scalars['Date'],
  response: IssuedCardAuthorizationResponses,
  statementDescriptor?: Maybe<Scalars['String']>,
  status: IssuedCardAuthorizationStatuses,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The balance transaction related to this issuing authorization */
  balanceTransactions?: Maybe<Array<Maybe<BalanceTransaction>>>,
  /** The issued card related to this issuing authorization */
  issuedCard?: Maybe<IssuedCard>,
}

export enum IssuedCardAuthorizationResponses {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED'
}

export enum IssuedCardAuthorizationStatuses {
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
  REVERSED = 'REVERSED'
}

export enum IssuedCardStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED'
}

export enum IssuedCardTypes {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL'
}

/** An edge in a connection. */
export interface IssueEdge {
  __typename?: 'IssueEdge',
  /** The item at the end of the edge */
  node?: Maybe<Issue>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** An issue resolution */
export interface IssueResolution {
  __typename?: 'IssueResolution',
  createdAt: Scalars['Date'],
  notes: Scalars['String'],
  resolutionType?: Maybe<IssueResolutionTypes>,
  serviceType?: Maybe<IssueResolutionServiceTypes>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The issue of this resolution */
  issue?: Maybe<Issue>,
  /** The primary photo related to this resolution */
  photo?: Maybe<Photo>,
  /** The photos related to this resolution */
  photos?: Maybe<Array<Maybe<Photo>>>,
  /** The creator of this issue resolution */
  resolverAccount: Account,
}

export enum IssueResolutionServiceTypes {
  ELECTRICAL = 'ELECTRICAL',
  GENERAL = 'GENERAL',
  OTHER = 'OTHER',
  PLUMBING = 'PLUMBING'
}

export enum IssueResolutionTypes {
  IGNORED = 'IGNORED',
  REPAIRED = 'REPAIRED'
}

export enum IssueServiceTypes {
  ELECTRICAL = 'ELECTRICAL',
  GENERAL = 'GENERAL',
  OTHER = 'OTHER',
  PLUMBING = 'PLUMBING'
}

export interface IssuesFilterInput {
  propertyManagerContractUuid?: Maybe<Scalars['UUID']>,
  status?: Maybe<IssueStatusInput>,
}

/** Calculated status of the issue */
export enum IssueStatuses {
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED'
}

export interface IssueStatusInput {
  eq?: Maybe<IssueStatuses>,
}

export interface JoinMailjetListInput {
  /** The company name of the contact being added */
  companyName?: Maybe<Scalars['String']>,
  /** The first name of the contact being added */
  firstName?: Maybe<Scalars['String']>,
  /** The last name of the contact being added */
  lastName?: Maybe<Scalars['String']>,
  /** Additional context for this contact */
  context?: Maybe<Scalars['String']>,
  /** The email address being added */
  emailAddress: Scalars['String'],
  /** The Mailjet contact list ID */
  listId: Scalars['String'],
  /** The phone number of the contact */
  phoneNumber?: Maybe<Scalars['String']>,
  /** The property ownership stage of the contact */
  propertyOwnershipStage?: Maybe<Scalars['String']>,
  /** The locations of units in the portfolio */
  unitLocations?: Maybe<Scalars['String']>,
}


export interface Landlord {
  __typename?: 'Landlord',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The buildings associated with this landlord */
  buildings?: Maybe<Array<Maybe<Building>>>,
  /** The landlord contracts associated with this landlord */
  landlordContracts?: Maybe<Array<Maybe<LandlordContract>>>,
  /** The organization associated with this landlord */
  organization?: Maybe<Organization>,
  /** The user for this landlord */
  user?: Maybe<Account>,
}

/** A connection to a list of items. */
export interface LandlordConnection {
  __typename?: 'LandlordConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LandlordEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

export interface LandlordContract {
  __typename?: 'LandlordContract',
  createdAt: Scalars['Date'],
  endsAt?: Maybe<Scalars['Date']>,
  startsAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The building associated with this landlord contract */
  building?: Maybe<Building>,
  /** The landlord associated with this landlord contract */
  landlord?: Maybe<Landlord>,
}

/** An edge in a connection. */
export interface LandlordEdge {
  __typename?: 'LandlordEdge',
  /** The item at the end of the edge */
  node?: Maybe<Landlord>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface LandlordsFilterType {
  createdAt?: Maybe<DateInputType>,
  name?: Maybe<SearchStringInput>,
  smart?: Maybe<SearchStringInput>,
  updatedAt?: Maybe<DateInputType>,
}

export enum LandlordsSortByEnum {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export enum LandlordsSortDirectionEnum {
  /** Ascending */
  ASC = 'ASC',
  /** Descending */
  DESC = 'DESC'
}

/** A lease agreement */
export interface Lease {
  __typename?: 'Lease',
  createdAt?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  startDate?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  voidedAt?: Maybe<Scalars['Date']>,
  addressUnit?: Maybe<AddressUnit>,
  childLease?: Maybe<Lease>,
  childLeases?: Maybe<Array<Maybe<Lease>>>,
  landlord?: Maybe<Account>,
  listing?: Maybe<Listing>,
}


/** A lease agreement */
export interface LeaseChildLeaseArgs {
  filter?: Maybe<ChildLeasesFilterInput>
}


/** A lease agreement */
export interface LeaseChildLeasesArgs {
  filter?: Maybe<ChildLeasesFilterInput>
}

export interface LeaseContextInput {
  /** The addendum to add to the end of the lease */
  addendumText?: Maybe<Scalars['String']>,
  /** The deadline that the lessor may cancel the contract by */
  cancellationDeadlineAt?: Maybe<Scalars['String']>,
  /** The start date of the lease */
  leaseStartsAt?: Maybe<Scalars['Date']>,
  /** The end date of the lease */
  leaseEndsAt?: Maybe<Scalars['Date']>,
  /** The rent amount in cents */
  rentInCents?: Maybe<Scalars['Int']>,
  /** The security deposit amount in cents */
  securityDepositInCents?: Maybe<Scalars['Int']>,
  /** The lessee's name */
  lessee?: Maybe<Scalars['String']>,
  /** The lessor's name */
  lessor?: Maybe<Scalars['String']>,
  /** The text to describe utilities payments */
  utilitiesText?: Maybe<Scalars['String']>,
}

/** An invitation for a lessee to join a lease agreement. */
export interface LeaseInvite {
  __typename?: 'LeaseInvite',
  acceptedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  email: Scalars['String'],
  expiresAt?: Maybe<Scalars['Date']>,
  isInviteeRegistered: Scalars['Boolean'],
  revokedAt?: Maybe<Scalars['Date']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** Viewer is the one being invited */
  viewerIsInvitee?: Maybe<Scalars['Boolean']>,
  /** The account that accepted this invitation */
  accepterAccount?: Maybe<Account>,
  /** The account that created this invitation */
  inviterAccount?: Maybe<Account>,
  /** The associated lease */
  lease?: Maybe<Lease>,
}

export interface LeaseRoleInput {
  eq?: Maybe<LeaseRoles>,
  in?: Maybe<Array<LeaseRoles>>,
}

/** Roles in the context of a lease agreement */
export enum LeaseRoles {
  LESSEE = 'LESSEE',
  LESSOR = 'LESSOR'
}

export interface LeasesFilterInput {
  isFullyExecuted?: Maybe<Scalars['Boolean']>,
  isActive?: Maybe<Scalars['Boolean']>,
  role?: Maybe<LeaseRoleInput>,
  type?: Maybe<LeaseTypeInput>,
}

/** A maintenance setting */
export interface LeasesSetting {
  __typename?: 'LeasesSetting',
  autopayRequiredAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The owner of this leases setting */
  account?: Maybe<Account>,
  /** The property manager contract associated with this leases setting */
  propertyManagerContract?: Maybe<PrivatePropertyManagerContract>,
}

export interface LeaseTypeInput {
  eq?: Maybe<LeaseTypes>,
  in?: Maybe<Array<LeaseTypes>>,
}

/** Types of leases */
export enum LeaseTypes {
  MASTER = 'MASTER',
  SUBLEASE = 'SUBLEASE'
}

/** A public listing on Caretaker. */
export interface Listing {
  __typename?: 'Listing',
  depositInCents?: Maybe<Scalars['Int']>,
  depositInDollars?: Maybe<Scalars['Float']>,
  securityDepositInCents?: Maybe<Scalars['Int']>,
  areaCode?: Maybe<Scalars['String']>,
  bathrooms?: Maybe<Scalars['Float']>,
  bedrooms?: Maybe<Scalars['Int']>,
  city?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  defaultUpfrontRentInCents?: Maybe<Scalars['Int']>,
  defaultUpfrontRentInDollars?: Maybe<Scalars['Float']>,
  defaultSecurityDepositInCents?: Maybe<Scalars['Int']>,
  defaultSecurityDepositInDollars?: Maybe<Scalars['Float']>,
  description?: Maybe<Scalars['String']>,
  earliestMoveInDate?: Maybe<Scalars['Date']>,
  earliestMoveInInDays?: Maybe<Scalars['Int']>,
  furnishingType?: Maybe<ListingFurnishingTypes>,
  referenceId?: Maybe<Scalars['String']>,
  /** This listing grants access to visitors via a keybox */
  hasKeyboxAccess?: Maybe<Scalars['Boolean']>,
  /** This listing grants access to visitors via a smart lock */
  hasSmartLockAccess?: Maybe<Scalars['Boolean']>,
  hasQualityPhotos?: Maybe<Scalars['Boolean']>,
  headline?: Maybe<Scalars['String']>,
  /** Less than 24 hours old */
  isNew?: Maybe<Scalars['Boolean']>,
  isLeaseExtendableWithLandlord?: Maybe<Scalars['Boolean']>,
  isManagedByFlip: Scalars['Boolean'],
  isVerified?: Maybe<Scalars['Boolean']>,
  latitude?: Maybe<Scalars['Float']>,
  leaseEndDate?: Maybe<Scalars['Date']>,
  leaseType?: Maybe<ListingLeaseTypes>,
  listedAt?: Maybe<Scalars['Date']>,
  bumpedAt?: Maybe<Scalars['Date']>,
  listingType?: Maybe<ListingTypes>,
  listingTypeString?: Maybe<Scalars['String']>,
  locationString?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['Float']>,
  maxLeaseTerm?: Maybe<Scalars['Int']>,
  minLeaseTerm?: Maybe<Scalars['Int']>,
  neighborhood?: Maybe<Scalars['String']>,
  photosReviewedAt?: Maybe<Scalars['Date']>,
  propertyType?: Maybe<Scalars['String']>,
  rentInCents?: Maybe<Scalars['Int']>,
  rentInDollars?: Maybe<Scalars['Float']>,
  requiresDeposit?: Maybe<Scalars['Boolean']>,
  shouldRequestLandlordApproval?: Maybe<Scalars['Boolean']>,
  slug?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<ListingPrivateStatuses>,
  timeRemainingInDays?: Maybe<Scalars['Int']>,
  timeRemainingString?: Maybe<Scalars['String']>,
  totalRooms?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['Date']>,
  url?: Maybe<Scalars['String']>,
  uuid: Scalars['UUID'],
  viewerHasBookmarked?: Maybe<Scalars['Boolean']>,
  viewerHasMessagedLister?: Maybe<Scalars['Boolean']>,
  /** Viewer is owner of listing */
  viewerIsOwner?: Maybe<Scalars['Boolean']>,
  viewerHasReviewed?: Maybe<Scalars['Boolean']>,
  viewerMeetsRequirements?: Maybe<Scalars['Boolean']>,
  views?: Maybe<Scalars['Int']>,
  /** The account that owns this listing */
  account?: Maybe<Account>,
  /** The addressUnit of the listing. */
  addressUnit?: Maybe<AddressUnit>,
  /** A list of amenities available at this listing. */
  amenities?: Maybe<Array<Maybe<Amenity>>>,
  /** A list of available viewings for this listing. */
  availableViewings?: Maybe<Array<Maybe<AvailableTimeSlot>>>,
  /** A list of available viewing slots for this listing. */
  availableViewingSlots?: Maybe<Array<Maybe<AvailableTimeSlot>>>,
  /** Information about the building in general. */
  building?: Maybe<Building>,
  /** Answered questions for a listing. */
  listingQuestions?: Maybe<Array<Maybe<ListingQuestion>>>,
  /** The next available viewing slot for this listing */
  nextAvailableViewingSlot?: Maybe<AvailableTimeSlot>,
  /** A list of photos of this listing. */
  photos?: Maybe<Array<Maybe<Photo>>>,
  /** The primary photo for a listing. */
  primaryPhoto?: Maybe<Photo>,
  /** The property manager contract associated with this listing */
  propertyManagerContract?: Maybe<PropertyManagerContract>,
  /** Leasing settings set by the landlord of this listing */
  resolvedLeasesSetting?: Maybe<LeasesSetting>,
  /** The resolved rental policy used for this listing */
  resolvedRentalPolicy?: Maybe<RentalPolicy>,
  /** A list of reviews for this listing. */
  reviews?: Maybe<ReviewConnection>,
  /** The viewer's upcoming viewing for this listing. */
  upcomingViewerViewing?: Maybe<PrivateViewing>,
  /** The owner of the listing */
  user?: Maybe<Account>,
  /** A list of verifications for this listing. */
  verifications?: Maybe<Array<Maybe<Verification>>>,
  /** The viewer's flip for this listing. */
  viewerFlip?: Maybe<Flip>,
  /** The viewer's upcoming viewing for this listing. */
  viewerViewing?: Maybe<PrivateViewing>,
  /** The viewer's viewings for this listing. */
  viewerViewings?: Maybe<Array<Maybe<PrivateViewing>>>,
  /** The viewer's referral link for this listing. */
  viewerReferral?: Maybe<Referral>,
}


/** A public listing on Caretaker. */
export interface ListingPhotosArgs {
  position?: Maybe<Scalars['Int']>
}


/** A public listing on Caretaker. */
export interface ListingReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

/** A listing answer */
export interface ListingAnswer {
  __typename?: 'ListingAnswer',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  postedAt?: Maybe<Scalars['Date']>,
  text: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
}

/** Marketing Boost for Listing */
export interface ListingBoost {
  __typename?: 'ListingBoost',
  adsetId?: Maybe<Scalars['String']>,
  amountInCents?: Maybe<Scalars['Int']>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  status?: Maybe<ListingBoostStatusTypes>,
  isActive: Scalars['Boolean'],
  dailyAmountInCents: Scalars['Int'],
  boostType?: Maybe<ListingBoostTypes>,
  autopaySource?: Maybe<Scalars['String']>,
  lastCharged?: Maybe<Scalars['Date']>,
  deactivatedDailyAt?: Maybe<Scalars['Date']>,
  subsidizedAmountInCents: Scalars['Int'],
  dailySubsidizedAmountInCents: Scalars['Int'],
  insights?: Maybe<ListingBoostInsightType>,
  /** The listing associated to this Boost */
  listing?: Maybe<Listing>,
  /** The offer associated to this Boost */
  offer?: Maybe<OfferType>,
  /** The user associated to this Boost */
  user?: Maybe<FlipProfile>,
}

/** A connection to a list of items. */
export interface ListingBoostConnection {
  __typename?: 'ListingBoostConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ListingBoostEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface ListingBoostEdge {
  __typename?: 'ListingBoostEdge',
  /** The item at the end of the edge */
  node?: Maybe<ListingBoost>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** Boost Insights by Listing */
export interface ListingBoostInsightType {
  __typename?: 'ListingBoostInsightType',
  impressions: Scalars['Int'],
  clicks: Scalars['Int'],
  reach: Scalars['Int'],
  spendInCents: Scalars['Int'],
  endDate?: Maybe<Scalars['Date']>,
  startDate?: Maybe<Scalars['Date']>,
  isDaily: Scalars['Boolean'],
  isLifetime: Scalars['Boolean'],
}

export enum ListingBoostStatusTypes {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  REFUNDED = 'REFUNDED',
  ERROR = 'ERROR',
  PARTIAL_REFUND = 'PARTIAL_REFUND'
}

export enum ListingBoostTypes {
  LIFETIME = 'LIFETIME',
  DAILY = 'DAILY'
}

/** A connection to a list of items. */
export interface ListingConnection {
  __typename?: 'ListingConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ListingEdge>>>,
  total?: Maybe<Scalars['Int']>,
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbType>>>,
  viewport?: Maybe<ViewportType>,
}

/** An edge in a connection. */
export interface ListingEdge {
  __typename?: 'ListingEdge',
  /** The item at the end of the edge */
  node?: Maybe<Listing>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** The available furnishing types for a listing. */
export enum ListingFurnishingTypes {
  /** The listing is fully furnished. */
  FURNISHED = 'FURNISHED',
  /** The listing is partially furnished. */
  PARTIALLY_FURNISHED = 'PARTIALLY_FURNISHED',
  /** The listing is not furnished. */
  UNFURNISHED = 'UNFURNISHED'
}

export enum ListingLeaseTypes {
  LEASE_BREAK = 'LEASE_BREAK',
  RENTAL = 'RENTAL',
  SUBLET = 'SUBLET'
}

export enum ListingOrderByEnum {
  /** Sort listings by recently listed. */
  NEWEST = 'NEWEST',
  /** Sort listings by price, low to high. */
  PRICE = 'PRICE',
  /** Sort listings by views, high to low. */
  VIEWS = 'VIEWS',
  /** Sort listings by most relevant, active results */
  SMART = 'SMART'
}

export enum ListingOrderDirectionEnum {
  /** Ascending */
  ASC = 'ASC',
  /** Descending */
  DESC = 'DESC'
}

export interface ListingPhotoInputType {
  /** The position */
  position?: Maybe<Scalars['Int']>,
  /** The photo uuid */
  uuid?: Maybe<Scalars['UUID']>,
}

export enum ListingPrivateStatuses {
  /** Listing that has been archived and is no longer active. */
  ARCHIVED = 'ARCHIVED',
  /** Listing was created but not yet listed */
  DRAFT = 'DRAFT',
  /** Listing was published then taken back off the marketplace */
  HIDDEN = 'HIDDEN',
  IMPORT = 'IMPORT',
  /** Listing is live on the marketplace */
  LISTED = 'LISTED',
  /** Listing is privately listed and can only be viewed with an access token */
  PRIVATE = 'PRIVATE',
  REMOVED = 'REMOVED',
  /** A deposit has been accepted for this listing and it's pending release */
  SECURED = 'SECURED',
  /** Listing was published then taken back off the marketplace */
  UNLISTED = 'UNLISTED'
}

/** The available property types for a listing. */
export enum ListingPropertyTypes {
  /** The listing is commercially zoned. */
  COMMERCIAL = 'COMMERCIAL',
  /** The listing is residentially zoned. */
  RESIDENTIAL = 'RESIDENTIAL'
}

/** A listing question */
export interface ListingQuestion {
  __typename?: 'ListingQuestion',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  prompt: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  askerAccount?: Maybe<Account>,
  listing?: Maybe<Listing>,
  listingAnswer?: Maybe<ListingAnswer>,
}

export interface ListingQuestionRoleInput {
  eq?: Maybe<ListingQuestionRoles>,
  in?: Maybe<Array<ListingQuestionRoles>>,
}

/** Roles in the context of a listing question. */
export enum ListingQuestionRoles {
  ANSWERER = 'ANSWERER',
  ASKER = 'ASKER'
}

export interface ListingQuestionsFilterInput {
  role?: Maybe<ListingQuestionRoleInput>,
}

export interface ListingsFilterInput {
  status?: Maybe<ListingStatusInput>,
}

/** The status of the listing */
export enum ListingStatuses {
  /** A listing that has been created but not yet listed/unlisted. */
  DRAFT = 'DRAFT',
  /** A listing that is publically listed on Caretaker. */
  LISTED = 'LISTED',
  /** A listing that is privately listed on Caretaker. */
  PRIVATE = 'PRIVATE',
  /** A listing that has been secured with a deposit. */
  SECURED = 'SECURED',
  /** A listing that is no longer publically listed on Caretaker. */
  UNLISTED = 'UNLISTED'
}

export interface ListingStatusInput {
  eq?: Maybe<ListingStatuses>,
  in?: Maybe<Array<ListingStatuses>>,
}

/** The available listing types. */
export enum ListingTypes {
  /** The listing is an entire place. */
  ENTIRE_PLACE = 'ENTIRE_PLACE',
  /** The listing is a private room. */
  PRIVATE_ROOM = 'PRIVATE_ROOM'
}

export interface ListListingInputType {
  status: ListListingStatusEnum,
}

export enum ListListingStatusEnum {
  /** List this listing as a private listing. */
  PRIVATE = 'PRIVATE',
  /** List this listing on the public marketplace. */
  PUBLIC = 'PUBLIC'
}

export interface LiveVideoCredentials {
  __typename?: 'LiveVideoCredentials',
  accessToken?: Maybe<Scalars['String']>,
  roomName?: Maybe<Scalars['String']>,
}

export interface LoginInputType {
  /** The email address to log in with. */
  email: Scalars['String'],
  /** The password associated with your Caretaker account. */
  password: Scalars['String'],
}

export interface LoginWithFacebookInputType {
  /** Token used for activating new accounts (optional) */
  activationToken?: Maybe<Scalars['UUID']>,
  /** Facebook access token generated for authenticating with Facebook. */
  fbAccessToken: Scalars['String'],
  product?: Maybe<Products>,
  /** Referral token for setting the referrer for the new user (optional) */
  referralToken?: Maybe<Scalars['UUID']>,
}

/** A maintenance setting */
export interface MaintenanceSetting {
  __typename?: 'MaintenanceSetting',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The owner of this maintenance setting */
  account?: Maybe<Account>,
  /** The property manager contract associated with this maintenance setting */
  propertyManagerContract?: Maybe<PrivatePropertyManagerContract>,
  /** The spending threshold for this maintenance setting */
  spendingThreshold?: Maybe<SpendingThreshold>,
}

export interface ManagedAddressUnit {
  __typename?: 'ManagedAddressUnit',
  createdAt?: Maybe<Scalars['Date']>,
  isRoot?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  unitType?: Maybe<AddressUnitUnitTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  accessInstruction?: Maybe<AccessInstruction>,
  /** The active landlord contract for this address unit */
  activeLandlordContract?: Maybe<LandlordContract>,
  /** The active property manager contracts for this address unit */
  activePropertyManagerContracts?: Maybe<Array<Maybe<PrivatePropertyManagerContract>>>,
  address?: Maybe<Address>,
  /** The amenities of the address unit */
  addressUnitAmenities?: Maybe<Array<Maybe<AddressUnitAmenityType>>>,
  /** Rooms that are part of this address unit */
  addressUnitRooms?: Maybe<Array<Maybe<AddressUnitRoom>>>,
  /** The building for this address unit */
  building?: Maybe<Building>,
  /** The calendar associated with this address unit */
  calendar?: Maybe<Calendar>,
  keybox?: Maybe<PrivateSmartLockType>,
  smartLock?: Maybe<PrivateSmartLockType>,
  /** The list of landlord contracts for this address unit */
  landlordContracts?: Maybe<Array<Maybe<LandlordContract>>>,
  /** The list of property manager contracts for this address unit */
  propertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** A list of reviews for this unit. */
  reviews?: Maybe<ReviewConnection>,
  /** The current viewer's property manager contract for this address unit */
  viewerPropertyManagerContract?: Maybe<PropertyManagerContract>,
  /** Latest cumulative inspection made on this address unit */
  conditionInspection?: Maybe<ConditionInspection>,
  /** Inspections made on this address unit */
  conditionInspections?: Maybe<Array<Maybe<ConditionInspection>>>,
  /** All issues reported on this address unit */
  issues?: Maybe<IssueConnection>,
  lease?: Maybe<PrivateLease>,
  leases?: Maybe<Array<Maybe<PrivateLease>>>,
  takeover?: Maybe<Takeover>,
  takeovers?: Maybe<Array<Maybe<Takeover>>>,
  utilityServices?: Maybe<Array<Maybe<UtilityService>>>,
}


export interface ManagedAddressUnitReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


export interface ManagedAddressUnitLeaseArgs {
  filter?: Maybe<LeasesFilterInput>
}


export interface ManagedAddressUnitLeasesArgs {
  filter?: Maybe<LeasesFilterInput>
}


export interface ManagedAddressUnitTakeoverArgs {
  filter?: Maybe<TakeoversFilterInput>
}


export interface ManagedAddressUnitTakeoversArgs {
  filter?: Maybe<TakeoversFilterInput>
}

/** A tag for media such as photos and videos */
export interface MediaTag {
  __typename?: 'MediaTag',
  name?: Maybe<Scalars['String']>,
  uuid: Scalars['UUID'],
}

/** A message in a thread */
export interface Message {
  __typename?: 'Message',
  context?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  icon?: Maybe<IconResource>,
  iconUrl?: Maybe<Scalars['String']>,
  isReadByViewer?: Maybe<Scalars['Boolean']>,
  subtype: MessageSubtypes,
  template?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  unfilteredText?: Maybe<Scalars['String']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The account that this message belongs to */
  account?: Maybe<Account>,
  /** The attachments for this message */
  attachments?: Maybe<Array<Maybe<MessageAttachment>>>,
  /** The conversation for this message */
  conversation?: Maybe<Conversation>,
  /** The recipient of the message if it is intended for only one user */
  recipient?: Maybe<Account>,
  /** The thread for this message */
  thread?: Maybe<Thread>,
  /** The user who wrote the message */
  user?: Maybe<Account>,
}

/** A message attachment */
export interface MessageAttachment {
  __typename?: 'MessageAttachment',
  createdAt: Scalars['Date'],
  entityType: MessageAttachmentEntityTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The thread action attachment */
  action?: Maybe<ThreadAction>,
  /** The photo attachment */
  photo?: Maybe<Photo>,
  /** A rent payment atachment */
  rentPayment?: Maybe<RentPayment>,
  /** A security deposit attachment */
  securityDeposit?: Maybe<SecurityDepositType>,
  /** The photo attachment */
  video?: Maybe<State>,
  /** The content answer attachment */
  contentAnswer?: Maybe<ContentAnswer>,
  /** The content question attachment */
  contentQuestion?: Maybe<ContentQuestion>,
}

export enum MessageAttachmentEntityTypes {
  CONTENT_ANSWER = 'CONTENT_ANSWER',
  PHOTO = 'PHOTO',
  RENT_PAYMENT = 'RENT_PAYMENT',
  SECURITY_DEPOSIT = 'SECURITY_DEPOSIT',
  SHIPMENT = 'SHIPMENT',
  THREAD_ACTION = 'THREAD_ACTION'
}

export interface MessageAttachmentInputType {
  entityType: MessageAttachmentEntityTypes,
  entityUuid: Scalars['UUID'],
}

/** A connection to a list of items. */
export interface MessageConnection {
  __typename?: 'MessageConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MessageEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface MessageEdge {
  __typename?: 'MessageEdge',
  /** The item at the end of the edge */
  node?: Maybe<Message>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export enum MessageSubtypes {
  ACCESS_INSTRUCTION_CREATED = 'ACCESS_INSTRUCTION_CREATED',
  BOOKING_ACCEPTED = 'BOOKING_ACCEPTED',
  BOOKING_CREATED = 'BOOKING_CREATED',
  BOOKING_REJECTED = 'BOOKING_REJECTED',
  BOOKING_REQUESTED = 'BOOKING_REQUESTED',
  BOOKING_SUBMITTED = 'BOOKING_SUBMITTED',
  BOOKING_WITHDRAWN = 'BOOKING_WITHDRAWN',
  CREDIT_SCORE_REQUESTED = 'CREDIT_SCORE_REQUESTED',
  EMPLOYMENT_REQUESTED = 'EMPLOYMENT_REQUESTED',
  KEYBOX_DELIVERED = 'KEYBOX_DELIVERED',
  KEYBOX_SHIPPED = 'KEYBOX_SHIPPED',
  KEYBOX_SHIPPING_SOON = 'KEYBOX_SHIPPING_SOON',
  SMART_LOCK_DELIVERED = 'SMART_LOCK_DELIVERED',
  SMART_LOCK_SHIPPED = 'SMART_LOCK_SHIPPED',
  SMART_LOCK_SHIPPING_SOON = 'SMART_LOCK_SHIPPING_SOON',
  LEASE_FULLY_EXECUTED = 'LEASE_FULLY_EXECUTED',
  LEASE_SIGNED = 'LEASE_SIGNED',
  RENT_PAYMENT_PAID = 'RENT_PAYMENT_PAID',
  SECURITY_DEPOSIT_PAID = 'SECURITY_DEPOSIT_PAID',
  SECURITY_DEPOSIT_RELEASED = 'SECURITY_DEPOSIT_RELEASED',
  SECURITY_DEPOSIT_REQUESTED = 'SECURITY_DEPOSIT_REQUESTED',
  SOCIAL_REQUESTED = 'SOCIAL_REQUESTED',
  SUPPORT_REQUESTED = 'SUPPORT_REQUESTED',
  TAKEOVER_CREATED = 'TAKEOVER_CREATED',
  UPFRONT_RENT_REQUESTED = 'UPFRONT_RENT_REQUESTED',
  USER_JOINED = 'USER_JOINED',
  USER_LEFT = 'USER_LEFT',
  VIEWING_SCHEDULED = 'VIEWING_SCHEDULED',
  DEFAULT = 'DEFAULT',
  EMAIL_PROXY = 'EMAIL_PROXY',
  PROXIED = 'PROXIED',
  SMS_PROXY = 'SMS_PROXY'
}

/** The status of the listing */
export enum MessageTypes {
  PEER = 'PEER',
  ACTIVITY = 'ACTIVITY',
  BOT = 'BOT'
}

/** A move from one place to another */
export interface Move {
  __typename?: 'Move',
  cancelledAt?: Maybe<Scalars['Date']>,
  completedAt?: Maybe<Scalars['Date']>,
  confirmedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  startsAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  account?: Maybe<Account>,
  fromAddressUnit?: Maybe<AddressUnit>,
  toAddressUnit?: Maybe<AddressUnit>,
}

/** The root of all... mutations */
export interface Mutations {
  __typename?: 'Mutations',
  /** Creates a set of access instructions */
  createAccessInstruction?: Maybe<PrivateAccessInstruction>,
  /** Updates a set of access instructions */
  updateAccessInstruction?: Maybe<PrivateAccessInstruction>,
  /** Add a new unit to your Caretaker account */
  createAccountAddressUnit?: Maybe<AccountAddressUnit>,
  /** Deletes a user address unit */
  deleteAccountAddressUnit?: Maybe<Scalars['Boolean']>,
  /** Update a user unit */
  updateAccountAddressUnit?: Maybe<AccountAddressUnit>,
  /** Add a new unit to your Caretaker account */
  createUserAddressUnit?: Maybe<AccountAddressUnit>,
  /** Deletes a user address unit */
  deleteUserAddressUnit?: Maybe<Scalars['Boolean']>,
  /** Update a user unit */
  updateUserAddressUnit?: Maybe<AccountAddressUnit>,
  /** Activates an account */
  activateAccount?: Maybe<Viewer>,
  /** Attaches a photo as your primary profile picture */
  attachPrimaryPhotoToAccount?: Maybe<Viewer>,
  /** Change your password */
  changePassword?: Maybe<Scalars['Boolean']>,
  /** Connect your Caretaker account to Facebook */
  connectFacebook?: Maybe<Viewer>,
  /** Connect your Caretaker account to Linkedin */
  connectLinkedin?: Maybe<Viewer>,
  /** Logs a user in */
  login?: Maybe<Viewer>,
  /** Logs a user in */
  loginWithFacebook?: Maybe<Viewer>,
  /** Register an account on Caretaker */
  register?: Maybe<Viewer>,
  /** Request a new SMS verification code */
  requestSmsVerification?: Maybe<Viewer>,
  /** Sends a reset password request to the provided email */
  resetPassword?: Maybe<Scalars['Boolean']>,
  /** Resync your Linkedin to your Caretaker account */
  resyncLinkedin?: Maybe<Viewer>,
  /** Sends an email verification email to the current user */
  sendDownloadLinkViaSms?: Maybe<Scalars['String']>,
  /** Sends an email verification email to the current user */
  sendEmailVerificationEmail?: Maybe<Scalars['String']>,
  /** Update your Caretaker account */
  updateAccount?: Maybe<Viewer>,
  /** Verify the current user's email with the given token */
  verifyEmail?: Maybe<Viewer>,
  /** Verify the current user's phone number with the given code */
  verifySms?: Maybe<Viewer>,
  /** Update your Caretaker account */
  updateUser?: Maybe<Viewer>,
  /** Update your settings for your Caretaker account */
  updateUserSettings?: Maybe<AccountSettings>,
  /** Update your settings for your Caretaker account */
  updateAccountSettings?: Maybe<AccountSettings>,
  /** Fetches or creates an address */
  fetchOrCreateAddress?: Maybe<Address>,
  /** Add a new address to your Caretaker account */
  createLegacyAddress?: Maybe<AccountAddressUnit>,
  /** Deletes an address */
  deleteLegacyAddress?: Maybe<Scalars['Boolean']>,
  /** Create an addressUnitAmenity entry */
  createAddressUnitAmenity?: Maybe<AddressUnitAmenityType>,
  /** Archive address unit room */
  archiveAddressUnitRoom?: Maybe<AddressUnitRoom>,
  /** Create address unit room */
  createAddressUnitRoom?: Maybe<AddressUnitRoom>,
  /** Delete address unit room */
  deleteAddressUnitRoom?: Maybe<Scalars['Boolean']>,
  /** Fetches or creates an address unit */
  fetchOrCreateAddressUnit?: Maybe<AddressUnit>,
  /** Creates a keybox order for an address unit */
  createKeyboxOrderForAddressUnit?: Maybe<ManagedAddressUnit>,
  /** Creates a smart lock order for an address unit */
  createSmartLockOrderForAddressUnit?: Maybe<ManagedAddressUnit>,
  /** Updates an address unit */
  updateAddressUnit?: Maybe<AddressUnit>,
  /** Unaccepts an application */
  unacceptApplication?: Maybe<PrivateApplication>,
  /** Returns an auth exchange token for a valid viewer */
  createAuthExchangeToken?: Maybe<Scalars['String']>,
  /** Returns a JWT auth token for a valid authExchangeToken */
  exchangeAuthExchangeToken?: Maybe<Scalars['String']>,
  /** Top up a balance */
  topupBalance?: Maybe<Balance>,
  /** Updates a balance */
  updateBalance?: Maybe<Balance>,
  /** Create a bookmark for a listing */
  createBookmark?: Maybe<Listing>,
  /** Remove a bookmark for a listing */
  deleteBookmark?: Maybe<Listing>,
  /** Bookmark a listing */
  addBookmark?: Maybe<Listing>,
  /** Remove a listing from your bookmarks */
  removeBookmark?: Maybe<Scalars['Boolean']>,
  /** Create a building amenity */
  createBuildingAmenity?: Maybe<BuildingAmenityType>,
  /** Attaches a set of viewing slots to a calendar */
  attachTimeSlotsToCalendar?: Maybe<Calendar>,
  /** Fetches or creates a calendar */
  createCalendar?: Maybe<Calendar>,
  /** Detaches a set of viewing slots from a calendar */
  detachTimeSlotsFromCalendar?: Maybe<Calendar>,
  /** Approve the given claim */
  approveClaim?: Maybe<ClaimType>,
  /** Add evidence to a claim */
  attachEvidence?: Maybe<ClaimType>,
  /** Create a new claim */
  createClaim?: Maybe<ClaimType>,
  /** Reject the given claim */
  rejectClaim?: Maybe<ClaimType>,
  /** Update a claim's description or amount */
  updateClaim?: Maybe<ClaimType>,
  /** Fetch or create condition inspection */
  fetchOrCreateConditionInspection?: Maybe<ConditionInspection>,
  /** Lock condition inspection */
  lockConditionInspection?: Maybe<ConditionInspection>,
  /** Create condition report */
  createConditionReport?: Maybe<ConditionReport>,
  /** Update condition report */
  updateConditionReport?: Maybe<ConditionReport>,
  /** Lock condition report */
  lockConditionReport?: Maybe<ConditionReport>,
  /** Delete condition report */
  deleteConditionReport?: Maybe<Scalars['Boolean']>,
  /** Asks a question to be answered. */
  askContentQuestion?: Maybe<Scalars['Boolean']>,
  /** Renders html for a custom lease agreement */
  renderRoommateAgreement?: Maybe<HtmlContent>,
  /** Renders html for a custom sublet agreement */
  renderSubleaseAgreement?: Maybe<HtmlContent>,
  /** Creates a new conversation */
  createConversation?: Maybe<Conversation>,
  /** Creates a new credit report */
  createCreditReport?: Maybe<PrivateCreditReport>,
  /** Add a new device to your Caretaker account */
  addDevice?: Maybe<Device>,
  /** Remove a device from your Caretaker account */
  removeDevice?: Maybe<Scalars['Boolean']>,
  /** Update new device on your Caretaker account */
  updateDevice?: Maybe<Device>,
  /** Creates a new document */
  createDocument?: Maybe<PrivateDocument>,
  /** Deletes a document */
  deleteDocument?: Maybe<Scalars['Boolean']>,
  /** Enables esigning on a document */
  enableEsignaturesOnDocument?: Maybe<PrivateDocument>,
  /** Gets embed URL for eSigning */
  getDocumentEsignEmbedUrl?: Maybe<DocumentEsignEmbedUrl>,
  /** Fetches the file url of the esigned document */
  getEsignedDocumentUrl?: Maybe<DocumentEsignedUrl>,
  /** Syncs esignature status on a document */
  syncEsignaturesOnDocument?: Maybe<PrivateDocument>,
  /** Update a document */
  updateDocument?: Maybe<PrivateDocument>,
  /** Sends an email regarding a building partnership. */
  sendPartnerBuildingLead?: Maybe<Scalars['Boolean']>,
  /** Generates a custom roommate agreement and sends it as an email */
  sendRoommateAgreementEmail?: Maybe<Scalars['Boolean']>,
  /** Generates a custom sublease agreement and sends it as an email */
  sendSubleaseAgreementEmail?: Maybe<Scalars['Boolean']>,
  /** Accepts an application */
  acceptApplication?: Maybe<Flip>,
  /** Archives a flip */
  archiveFlip?: Maybe<Flip>,
  /** Creates a new application */
  createApplication?: Maybe<Flip>,
  /** Generates a lease agreement for a flip */
  generateLeaseAgreement?: Maybe<PrivateDocument>,
  /** Generates a sublet agreement for a flip */
  generateSubletAgreement?: Maybe<PrivateDocument>,
  /** Rejects an application */
  rejectApplication?: Maybe<Flip>,
  /** Request an applicant submit an application */
  requestApplication?: Maybe<Flip>,
  /** Request an applicant submit a credit score */
  requestCreditScore?: Maybe<Flip>,
  /** Request an applicant put down a deposit */
  requestDeposit?: Maybe<Flip>,
  /** Request an applicant submit employment data */
  requestEmployment?: Maybe<Flip>,
  /** Request an applicant submit social data */
  requestSocial?: Maybe<Flip>,
  /** Submits an application */
  submitApplication?: Maybe<Flip>,
  /** Updates the deposit amount for a specific flip. */
  updateDepositForFlip?: Maybe<Flip>,
  /** Updates the security deposit amount for a specific flip. */
  updateSecurityDepositForFlip?: Maybe<Flip>,
  /** Withdraws an application */
  withdrawApplication?: Maybe<Flip>,
  /** Attachs an upload to an income source */
  attachUploadToIncomeSource?: Maybe<PrivateIncomeSource>,
  /** Creates a new income source */
  createIncomeSource?: Maybe<PrivateIncomeSource>,
  /** Deletes an IncomeSource */
  deleteIncomeSource?: Maybe<Scalars['Boolean']>,
  /** Removes an upload from an income source */
  detachUploadFromIncomeSource?: Maybe<PrivateIncomeSource>,
  /** Requests verification for an income source */
  requestIncomeSourceVerification?: Maybe<PrivateIncomeSource>,
  /** Updates an income source */
  updateIncomeSource?: Maybe<PrivateIncomeSource>,
  /** Sends an engineering application to the team */
  sendJobApplication?: Maybe<Scalars['Boolean']>,
  /** Create issue resolution */
  createIssueResolution?: Maybe<IssueResolution>,
  /** Create issue */
  createIssue?: Maybe<Issue>,
  /** Generates a smart lock one time code */
  generateSmartLockOneTimeCode: Scalars['String'],
  /** Generates a keybox one time code */
  generateKeyboxOneTimeCode: Scalars['String'],
  /** Lessee accepts the invitation */
  acceptLeaseInvite?: Maybe<LeaseInvite>,
  /** Invites a lessee to join a lease agreement. */
  createLeaseInvite?: Maybe<LeaseInvite>,
  /** Re-send a lease invite */
  resendLeaseInvite?: Maybe<Scalars['Boolean']>,
  /** Inviter revokes the invitation */
  revokeLeaseInvite?: Maybe<LeaseInvite>,
  /** Creates a new lease */
  createLease?: Maybe<PrivateLease>,
  /** Disables autopay on a lease */
  disableAutopayForLease?: Maybe<PrivateLease>,
  /** Enables/updates autopay on a lease */
  enableAutopay?: Maybe<PrivateLease>,
  /** Sets the default payment source on a Lease */
  setDefaultPaymentMethodForLease?: Maybe<PrivateLease>,
  /** Updates a lease */
  updateLease?: Maybe<PrivateLease>,
  /** Void a lease agreement */
  voidLease?: Maybe<Lease>,
  /** Create a leases setting. */
  createLeasesSetting?: Maybe<LeasesSetting>,
  /** Update a leases setting. */
  updateLeasesSetting?: Maybe<LeasesSetting>,
  /** Add an answer to a listing question */
  createListingAnswer?: Maybe<ListingAnswer>,
  /** Archive an answer to a listing question */
  archiveListingAnswer?: Maybe<Scalars['Boolean']>,
  /** Update an answer to a listing question */
  updateListingAnswer?: Maybe<ListingAnswer>,
  /** Pauses a Boost */
  pauseListingBoost?: Maybe<ListingBoost>,
  /** Purchases a Boost for a listing */
  purchaseListingBoost?: Maybe<ListingBoost>,
  /** Archive a listing question */
  archiveListingQuestion?: Maybe<Scalars['Boolean']>,
  /** Ask a question for a listing */
  createListingQuestion?: Maybe<PrivateListingQuestion>,
  /** Update an unanswered question for a listing */
  updateListingQuestion?: Maybe<PrivateListingQuestion>,
  /** Archives a listing */
  archiveListing?: Maybe<PrivateListing>,
  /** Bumps a listing to the top of search. */
  bumpListing?: Maybe<PrivateListing>,
  /** Creates a new listing */
  createListing?: Maybe<PrivateListing>,
  /** Deletes a listing */
  deleteListing?: Maybe<Scalars['Boolean']>,
  /** Fetches or creates a listing for a property manager contract */
  fetchOrCreateListingForPropertyManagerContract?: Maybe<PrivateListing>,
  /** Lists a listing on the public Caretaker marketplace or privately */
  listListing?: Maybe<PrivateListing>,
  /** Track a view on a listing */
  trackListingView?: Maybe<Scalars['Boolean']>,
  /** Unlists the listing */
  unlistListing?: Maybe<PrivateListing>,
  /** Updates a listing */
  updateListing?: Maybe<PrivateListing>,
  /** Adds an email to a Mailjet mailing list */
  joinMailjetList?: Maybe<Scalars['Boolean']>,
  /** Create a maintenance setting */
  createMaintenanceSetting?: Maybe<MaintenanceSetting>,
  /** Sends a new message */
  sendMessage?: Maybe<Message>,
  /** Updates a move */
  updateMove?: Maybe<Move>,
  /** Create a notification request for an addressunit */
  createNotificationRequestForAddressUnit?: Maybe<NotificationRequest>,
  /** Updates readAt for a given notification. */
  markManyNotificationsAsRead?: Maybe<Scalars['Boolean']>,
  /** Updates readAt for a given notification. */
  markNotificationAsRead?: Maybe<Notification>,
  /** Marks all notifications as read. */
  markNotificationsAsRead?: Maybe<Scalars['Boolean']>,
  /** Creates an organization person */
  createOrganizationPerson?: Maybe<OrganizationPerson>,
  /** Updates an organization person */
  updateOrganizationPerson?: Maybe<OrganizationPerson>,
  /** Creates an organization */
  createOrganization?: Maybe<Organization>,
  /** Updates an organization */
  updateOrganization?: Maybe<Organization>,
  /** Add an payout account to your Stripe account */
  addPayoutAccount?: Maybe<StripeExternalAccount>,
  /** Creates a Stripe connect account */
  createStripeAccount?: Maybe<StripeConnectAccount>,
  /** Create one-time Stripe account link */
  createStripeAccountLink?: Maybe<Scalars['String']>,
  /** Create Stripe financial connection session */
  createStripeFinancialConnectionSession?: Maybe<Scalars['String']>,
  /** Creates a Stripe customer account */
  createStripeCustomer?: Maybe<StripeCustomer>,
  /** Create a Stripe setup intent */
  createStripeSetupIntent?: Maybe<Scalars['String']>,
  /** Remove a payment method from your account */
  removePaymentMethod?: Maybe<Scalars['Boolean']>,
  /** Remove a payout account from your Stripe account */
  removePayoutAccount?: Maybe<Scalars['Boolean']>,
  /** Updates your default payment method */
  setDefaultPaymentMethod?: Maybe<StripePaymentMethod>,
  /** Sets a payout account as your primary on Stripe */
  setPrimaryPayoutAccount?: Maybe<StripeExternalAccount>,
  /** Updates your connected Stripe account */
  updateStripeAccount?: Maybe<StripeConnectAccount>,
  /** Updates a payment schedule. */
  updatePaymentSchedule?: Maybe<PaymentSchedule>,
  /** Cancels a payment subscription. */
  cancelPaymentSubscription?: Maybe<PaymentSubscription>,
  /** Creates a payment subscription. */
  createPaymentSubscription?: Maybe<PaymentSubscription>,
  /** Updates a payment subscription. */
  updatePaymentSubscription?: Maybe<PaymentSubscription>,
  /** Creates a person */
  createPerson?: Maybe<Person>,
  /** Updates a person */
  updatePerson?: Maybe<Person>,
  /** Attaches a phone number to a organization */
  attachPhoneNumberToOrganization?: Maybe<PhoneNumber>,
  /** Attaches a phone number to a person */
  attachPhoneNumberToPerson?: Maybe<PhoneNumber>,
  /** Detaches a phone number from a organization */
  detachPhoneNumberFromOrganization?: Maybe<Scalars['Boolean']>,
  /** Detaches a phone number from a person */
  detachPhoneNumberFromPerson?: Maybe<Scalars['Boolean']>,
  /** Fetches or creates a phone number */
  fetchOrCreatePhoneNumber?: Maybe<PhoneNumber>,
  /** Adds a photo to a listing */
  addPhotoToListing?: Maybe<PrivatePhoto>,
  /** Copies an existing photo to a listing */
  copyPhotoToListing?: Maybe<PrivatePhoto>,
  /** Creates a photo */
  createPhoto?: Maybe<PrivatePhoto>,
  /** Deletes a photo */
  deletePhoto?: Maybe<Scalars['Boolean']>,
  /** Updates a photo */
  updatePhoto?: Maybe<PrivatePhoto>,
  /** Create property manager contract for an address unit. */
  createPropertyManagerContract?: Maybe<PrivatePropertyManagerContract>,
  /** Terminate a property management contract */
  terminatePropertyManagerContract?: Maybe<PrivatePropertyManagerContract>,
  /** Update property manager contract for an address unit. */
  updatePropertyManagerContract?: Maybe<PrivatePropertyManagerContract>,
  /** Validates eligibility to create a Caretaker property management contract for a unit. */
  validatePropertyManagerContractEligibility: Scalars['Boolean'],
  /** Creates a property manager */
  createPropertyManager?: Maybe<PropertyManager>,
  /** Creates a qualification */
  createQualification?: Maybe<Qualification>,
  /** Attaches a rental form to an entity */
  attachRentalFormToEntity?: Maybe<RentalForm>,
  /** Creates a form for a rental */
  createRentalForm?: Maybe<RentalForm>,
  /** Creates a rental policy */
  createRentalPolicy?: Maybe<RentalPolicy>,
  /** Delete rental policy */
  deleteRentalPolicy?: Maybe<Scalars['Boolean']>,
  /** Updates a policy for a rental */
  updateRentalPolicy?: Maybe<RentalPolicy>,
  /** Creates a rent payment */
  createRentPayment?: Maybe<RentPayment>,
  /** Attaches a verification method to a building requirement */
  attachVerificationMethodToRequirement?: Maybe<Requirement>,
  /** Attaches a rental requirement to an entity */
  createRequirement?: Maybe<Requirement>,
  /** Attaches a rental requirement to an entity */
  deleteRequirement: Scalars['Boolean'],
  /** Detaches a verification method from a building requirement */
  detachVerificationMethodFromRequirement?: Maybe<Requirement>,
  /** Creates a review */
  createReview?: Maybe<Review>,
  /** Deletes a review */
  deleteReview?: Maybe<Scalars['Boolean']>,
  /** Updates a review */
  updateReview?: Maybe<Review>,
  /** Creates a saved search alert */
  createSavedSearch?: Maybe<SavedSearch>,
  /** Delete saved search */
  deleteSavedSearch?: Maybe<Scalars['Boolean']>,
  /** Update saved search */
  updateSavedSearch?: Maybe<SavedSearch>,
  /** Update saved search */
  updateSavedSearchSubscription?: Maybe<SavedSearch>,
  /** Puts a security deposit into escrow. */
  createSecurityDeposit?: Maybe<SecurityDepositType>,
  /** Tries again to charge a passed source for a failed security deposit. */
  retryChargeSecurityDeposit?: Maybe<SecurityDepositType>,
  /** Accept a service dispatch */
  acceptServiceDispatch?: Maybe<ServiceDispatch>,
  /** Reject a service dispatch */
  rejectServiceDispatch?: Maybe<ServiceDispatch>,
  /** Submits a service fulfillment for approval */
  submitServiceFulfillmentForApproval?: Maybe<ServiceFulfillment>,
  /** Add a property manager contract to a service preference. */
  addPropertyManagerContractToServicePreference?: Maybe<ServicePreference>,
  /** Create a service preference. */
  createServicePreference?: Maybe<ServicePreference>,
  /** Delete service preference. */
  deleteServicePreference?: Maybe<Scalars['Boolean']>,
  /** Remove a property manager contract from a service preference. */
  removePropertyManagerContractFromServicePreference?: Maybe<ServicePreference>,
  /** Update a service preference. */
  updateServicePreference?: Maybe<ServicePreference>,
  /** Create a service provider. */
  createServiceProvider?: Maybe<ServiceProvider>,
  /** Update a service provider. */
  updateServiceProvider?: Maybe<ServiceProvider>,
  /** Create a spending threshold */
  createSpendingThreshold?: Maybe<SpendingThreshold>,
  /** Update a spending threshold. */
  updateSpendingThreshold?: Maybe<SpendingThreshold>,
  /** Accepts a Caretaker Instant (takeover) proposal */
  acceptTakeoverProposal?: Maybe<TakeoverProposal>,
  /** Cancels a Caretaker Instant (takeover) request */
  cancelTakeoverRequest?: Maybe<TakeoverRequest>,
  /** Creates a Caretaker Instant (takeover) request */
  createTakeoverRequest?: Maybe<TakeoverRequest>,
  /** Assigns a new Instant Agreement to a takeover */
  assignInstantAgreementDocumentToTakeover?: Maybe<Takeover>,
  /** Creates payment schedule for a takeover. */
  createPaymentScheduleForTakeover?: Maybe<Takeover>,
  /** Generates the Caretaker Instant agreement for a Takeover */
  generateInstantAgreementForTakeover?: Maybe<Takeover>,
  /** Pays the one time fee for a Caretaker Instant (takeover) proposal */
  purchaseTakeover?: Maybe<Takeover>,
  /** Update the context of a takeover. */
  updateTakeover?: Maybe<Takeover>,
  /** Creates a new thread */
  createThread?: Maybe<Thread>,
  /** Creates a new live video session for a Flip */
  createLiveVideoAccessToken?: Maybe<LiveVideoCredentials>,
  /** Requests Caretaker support to join a thread */
  requestSupport?: Maybe<Thread>,
  /** Updates readUpTo for a given thread. */
  updateReadUpTo?: Maybe<Thread>,
  /** Creates a new upload */
  createUpload?: Maybe<Upload>,
  /** Delete an upload */
  deleteUpload?: Maybe<Scalars['Boolean']>,
  /** Creates a signed S3 upload request for direct to S3 client side file uploading. */
  signUploadRequest?: Maybe<AwsSignature>,
  /** Confirms a verification request by token for the current user */
  confirmVerificationWithToken?: Maybe<Verification>,
  /** Create a new verification */
  createVerification?: Maybe<Verification>,
  /** Begins verification for a listing */
  createVerificationForListing?: Maybe<Verification>,
  /** Verify the current user's identity */
  verifyUserIdentity?: Maybe<Verification>,
  /** Creates a viewing for a listing */
  createViewingForListing?: Maybe<PrivateViewing>,
  /** Deletes a viewing */
  deleteViewing?: Maybe<Scalars['Boolean']>,
  /** Creates a vote for an entity */
  createVote?: Maybe<VoteType>,
  /** Updates a vote */
  updateVote?: Maybe<VoteType>,
}


/** The root of all... mutations */
export interface MutationsCreateAccessInstructionArgs {
  input: CreateAccessInstructionInput
}


/** The root of all... mutations */
export interface MutationsUpdateAccessInstructionArgs {
  input: UpdateAccessInstructionInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateAccountAddressUnitArgs {
  input?: Maybe<CreateAccountAddressUnitInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteAccountAddressUnitArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateAccountAddressUnitArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdateAccountAddressUnitInputType>
}


/** The root of all... mutations */
export interface MutationsCreateUserAddressUnitArgs {
  input?: Maybe<CreateUserAddressUnitInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteUserAddressUnitArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateUserAddressUnitArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdateUserAddressUnitInputType>
}


/** The root of all... mutations */
export interface MutationsActivateAccountArgs {
  input?: Maybe<ActivateAccountInputType>
}


/** The root of all... mutations */
export interface MutationsAttachPrimaryPhotoToAccountArgs {
  input?: Maybe<AttachPrimaryPhotoToAccountInput>
}


/** The root of all... mutations */
export interface MutationsChangePasswordArgs {
  input?: Maybe<ChangePasswordInputType>
}


/** The root of all... mutations */
export interface MutationsConnectFacebookArgs {
  input?: Maybe<ConnectFacebookInputType>
}


/** The root of all... mutations */
export interface MutationsConnectLinkedinArgs {
  input?: Maybe<ConnectLinkedinInputType>
}


/** The root of all... mutations */
export interface MutationsLoginArgs {
  input?: Maybe<LoginInputType>
}


/** The root of all... mutations */
export interface MutationsLoginWithFacebookArgs {
  input?: Maybe<LoginWithFacebookInputType>
}


/** The root of all... mutations */
export interface MutationsRegisterArgs {
  input?: Maybe<RegisterInputType>
}


/** The root of all... mutations */
export interface MutationsResetPasswordArgs {
  input?: Maybe<ResetPasswordInput>
}


/** The root of all... mutations */
export interface MutationsSendDownloadLinkViaSmsArgs {
  phoneNumber: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsUpdateAccountArgs {
  input?: Maybe<UpdateAccountInputType>
}


/** The root of all... mutations */
export interface MutationsVerifyEmailArgs {
  token: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsVerifySmsArgs {
  code?: Maybe<Scalars['String']>
}


/** The root of all... mutations */
export interface MutationsUpdateUserArgs {
  input?: Maybe<UpdateUserInputType>
}


/** The root of all... mutations */
export interface MutationsUpdateUserSettingsArgs {
  input?: Maybe<UpdateUserSettingsInputType>
}


/** The root of all... mutations */
export interface MutationsUpdateAccountSettingsArgs {
  input?: Maybe<UpdateAccountSettingsInputType>
}


/** The root of all... mutations */
export interface MutationsFetchOrCreateAddressArgs {
  input?: Maybe<FetchOrCreateAddressInputType>
}


/** The root of all... mutations */
export interface MutationsCreateLegacyAddressArgs {
  input?: Maybe<CreateLegacyAddressInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteLegacyAddressArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateAddressUnitAmenityArgs {
  input?: Maybe<CreateAddressUnitAmenityInput>
}


/** The root of all... mutations */
export interface MutationsArchiveAddressUnitRoomArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateAddressUnitRoomArgs {
  input?: Maybe<CreateAddressUnitRoomInput>
}


/** The root of all... mutations */
export interface MutationsDeleteAddressUnitRoomArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsFetchOrCreateAddressUnitArgs {
  input?: Maybe<FetchOrCreateAddressUnitInputType>
}


/** The root of all... mutations */
export interface MutationsCreateKeyboxOrderForAddressUnitArgs {
  uuid: Scalars['UUID'],
  input: CreateKeyboxOrderForAddressUnitInput
}


/** The root of all... mutations */
export interface MutationsCreateSmartLockOrderForAddressUnitArgs {
  uuid: Scalars['UUID'],
  input: CreateSmartLockOrderForAddressUnitInput
}


/** The root of all... mutations */
export interface MutationsUpdateAddressUnitArgs {
  input?: Maybe<UpdateAddressUnitInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUnacceptApplicationArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsExchangeAuthExchangeTokenArgs {
  authExchangeToken: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsTopupBalanceArgs {
  input: TopupBalanceInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateBalanceArgs {
  input?: Maybe<UpdateBalanceInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateBookmarkArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsDeleteBookmarkArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAddBookmarkArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRemoveBookmarkArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateBuildingAmenityArgs {
  input?: Maybe<CreateBuildingAmenityInput>
}


/** The root of all... mutations */
export interface MutationsAttachTimeSlotsToCalendarArgs {
  uuid?: Maybe<Scalars['UUID']>,
  input?: Maybe<AttachTimeSlotsToCalendarInput>
}


/** The root of all... mutations */
export interface MutationsCreateCalendarArgs {
  input?: Maybe<CreateCalendarInput>
}


/** The root of all... mutations */
export interface MutationsDetachTimeSlotsFromCalendarArgs {
  uuid?: Maybe<Scalars['UUID']>,
  input?: Maybe<DetachTimeSlotsFromCalendarInput>
}


/** The root of all... mutations */
export interface MutationsApproveClaimArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAttachEvidenceArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<AttachEvidenceInputType>
}


/** The root of all... mutations */
export interface MutationsCreateClaimArgs {
  input?: Maybe<CreateClaimInputType>
}


/** The root of all... mutations */
export interface MutationsRejectClaimArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateClaimArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdateClaimInputType>
}


/** The root of all... mutations */
export interface MutationsFetchOrCreateConditionInspectionArgs {
  input?: Maybe<FetchOrCreateConditionInspectionInput>
}


/** The root of all... mutations */
export interface MutationsLockConditionInspectionArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateConditionReportArgs {
  input?: Maybe<CreateConditionReportInput>
}


/** The root of all... mutations */
export interface MutationsUpdateConditionReportArgs {
  input?: Maybe<UpdateConditionReportInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsLockConditionReportArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsDeleteConditionReportArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAskContentQuestionArgs {
  input?: Maybe<AskContentQuestionInputType>
}


/** The root of all... mutations */
export interface MutationsRenderRoommateAgreementArgs {
  input?: Maybe<RoommateAgreementInputType>
}


/** The root of all... mutations */
export interface MutationsRenderSubleaseAgreementArgs {
  input?: Maybe<RenderSubleaseAgreementInputType>
}


/** The root of all... mutations */
export interface MutationsCreateConversationArgs {
  input?: Maybe<CreateConversationInputType>
}


/** The root of all... mutations */
export interface MutationsCreateCreditReportArgs {
  input?: Maybe<CreateCreditReportInputType>
}


/** The root of all... mutations */
export interface MutationsAddDeviceArgs {
  input?: Maybe<AddDeviceInputType>
}


/** The root of all... mutations */
export interface MutationsRemoveDeviceArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateDeviceArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdateDeviceInputType>
}


/** The root of all... mutations */
export interface MutationsCreateDocumentArgs {
  input?: Maybe<CreateDocumentInput>
}


/** The root of all... mutations */
export interface MutationsDeleteDocumentArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsEnableEsignaturesOnDocumentArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsGetDocumentEsignEmbedUrlArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsGetEsignedDocumentUrlArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsSyncEsignaturesOnDocumentArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateDocumentArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdateDocumentInputType>
}


/** The root of all... mutations */
export interface MutationsSendPartnerBuildingLeadArgs {
  input?: Maybe<SendPartnerBuildingLeadInput>
}


/** The root of all... mutations */
export interface MutationsSendRoommateAgreementEmailArgs {
  email: Scalars['String'],
  input?: Maybe<RoommateAgreementInputType>
}


/** The root of all... mutations */
export interface MutationsSendSubleaseAgreementEmailArgs {
  input?: Maybe<SendSubleaseAgreementEmailInputType>
}


/** The root of all... mutations */
export interface MutationsAcceptApplicationArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsArchiveFlipArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateApplicationArgs {
  input?: Maybe<CreateApplicationInput>
}


/** The root of all... mutations */
export interface MutationsGenerateLeaseAgreementArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<LeaseContextInput>
}


/** The root of all... mutations */
export interface MutationsGenerateSubletAgreementArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<SubleaseContextInput>
}


/** The root of all... mutations */
export interface MutationsRejectApplicationArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRequestApplicationArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRequestCreditScoreArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRequestDepositArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRequestEmploymentArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRequestSocialArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsSubmitApplicationArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateDepositForFlipArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdateDepositForFlipInputType>
}


/** The root of all... mutations */
export interface MutationsUpdateSecurityDepositForFlipArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdateSecurityDepositForFlipInputType>
}


/** The root of all... mutations */
export interface MutationsWithdrawApplicationArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAttachUploadToIncomeSourceArgs {
  uploadUuid: Scalars['UUID'],
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateIncomeSourceArgs {
  input?: Maybe<CreateIncomeSourceInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteIncomeSourceArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsDetachUploadFromIncomeSourceArgs {
  uploadUuid: Scalars['UUID'],
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRequestIncomeSourceVerificationArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateIncomeSourceArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdateIncomeSourceInput>
}


/** The root of all... mutations */
export interface MutationsSendJobApplicationArgs {
  input: SendJobApplicationInputType,
  jobTitle: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsCreateIssueResolutionArgs {
  input?: Maybe<CreateIssueResolutionInput>
}


/** The root of all... mutations */
export interface MutationsCreateIssueArgs {
  input?: Maybe<CreateIssueInput>
}


/** The root of all... mutations */
export interface MutationsGenerateSmartLockOneTimeCodeArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsGenerateKeyboxOneTimeCodeArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAcceptLeaseInviteArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateLeaseInviteArgs {
  input?: Maybe<CreateLeaseInviteInput>
}


/** The root of all... mutations */
export interface MutationsResendLeaseInviteArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRevokeLeaseInviteArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateLeaseArgs {
  input?: Maybe<CreateLeaseInput>
}


/** The root of all... mutations */
export interface MutationsDisableAutopayForLeaseArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsEnableAutopayArgs {
  input?: Maybe<EnableAutopayInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsSetDefaultPaymentMethodForLeaseArgs {
  input?: Maybe<SetDefaultPaymentMethodForLeaseInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateLeaseArgs {
  input?: Maybe<UpdateLeaseInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsVoidLeaseArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateLeasesSettingArgs {
  input: CreateLeasesSettingInput
}


/** The root of all... mutations */
export interface MutationsUpdateLeasesSettingArgs {
  input: UpdateLeasesSettingInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateListingAnswerArgs {
  input?: Maybe<CreateListingAnswerInput>
}


/** The root of all... mutations */
export interface MutationsArchiveListingAnswerArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateListingAnswerArgs {
  input?: Maybe<UpdateListingAnswerInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsPauseListingBoostArgs {
  uuid?: Maybe<Scalars['UUID']>
}


/** The root of all... mutations */
export interface MutationsPurchaseListingBoostArgs {
  input?: Maybe<PurchaseListingBoostInputType>
}


/** The root of all... mutations */
export interface MutationsArchiveListingQuestionArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateListingQuestionArgs {
  input?: Maybe<CreateListingQuestionInput>
}


/** The root of all... mutations */
export interface MutationsUpdateListingQuestionArgs {
  input?: Maybe<UpdateListingQuestionInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsArchiveListingArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsBumpListingArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateListingArgs {
  input?: Maybe<CreateListingInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteListingArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsFetchOrCreateListingForPropertyManagerContractArgs {
  input?: Maybe<FetchOrCreateListingForPropertyManagerContractInput>
}


/** The root of all... mutations */
export interface MutationsListListingArgs {
  input?: Maybe<ListListingInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsTrackListingViewArgs {
  slugOrUuid?: Maybe<Scalars['String']>,
  uuid?: Maybe<Scalars['UUID']>
}


/** The root of all... mutations */
export interface MutationsUnlistListingArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateListingArgs {
  input?: Maybe<UpdateListingInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsJoinMailjetListArgs {
  input: JoinMailjetListInput
}


/** The root of all... mutations */
export interface MutationsCreateMaintenanceSettingArgs {
  input?: Maybe<CreateMaintenanceSettingInput>
}


/** The root of all... mutations */
export interface MutationsSendMessageArgs {
  input?: Maybe<SendMessageInputType>
}


/** The root of all... mutations */
export interface MutationsUpdateMoveArgs {
  input?: Maybe<UpdateMoveInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateNotificationRequestForAddressUnitArgs {
  input?: Maybe<CreateNotificationRequestForAddressUnitInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsMarkManyNotificationsAsReadArgs {
  uuids?: Maybe<Array<Scalars['UUID']>>
}


/** The root of all... mutations */
export interface MutationsMarkNotificationAsReadArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateOrganizationPersonArgs {
  input?: Maybe<CreateOrganizationPersonInput>
}


/** The root of all... mutations */
export interface MutationsUpdateOrganizationPersonArgs {
  input?: Maybe<UpdateOrganizationPersonInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateOrganizationArgs {
  input?: Maybe<CreateOrganizationInput>
}


/** The root of all... mutations */
export interface MutationsUpdateOrganizationArgs {
  input?: Maybe<UpdateOrganizationInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAddPayoutAccountArgs {
  token: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsCreateStripeAccountLinkArgs {
  input?: Maybe<CreateStripeAccountLinkInput>
}


/** The root of all... mutations */
export interface MutationsCreateStripeFinancialConnectionSessionArgs {
  input?: Maybe<CreateStripeFinancialConnectionSessionInput>
}


/** The root of all... mutations */
export interface MutationsCreateStripeSetupIntentArgs {
  input?: Maybe<CreateStripeSetupIntentInput>
}


/** The root of all... mutations */
export interface MutationsRemovePaymentMethodArgs {
  paymentMethodId: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsRemovePayoutAccountArgs {
  accountId: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsSetDefaultPaymentMethodArgs {
  paymentMethodId: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsSetPrimaryPayoutAccountArgs {
  accountId: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsUpdateStripeAccountArgs {
  individual?: Maybe<IndividualInput>
}


/** The root of all... mutations */
export interface MutationsUpdatePaymentScheduleArgs {
  input?: Maybe<UpdatePaymentScheduleInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCancelPaymentSubscriptionArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreatePaymentSubscriptionArgs {
  input: CreatePaymentSubscriptionInput
}


/** The root of all... mutations */
export interface MutationsUpdatePaymentSubscriptionArgs {
  input: UpdatePaymentSubscriptionInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreatePersonArgs {
  input?: Maybe<CreatePersonInput>
}


/** The root of all... mutations */
export interface MutationsUpdatePersonArgs {
  input?: Maybe<UpdatePersonInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAttachPhoneNumberToOrganizationArgs {
  input?: Maybe<AttachPhoneNumberToOrganizationInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAttachPhoneNumberToPersonArgs {
  input?: Maybe<AttachPhoneNumberToPersonInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsDetachPhoneNumberFromOrganizationArgs {
  input?: Maybe<DetachPhoneNumberFromOrganizationInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsDetachPhoneNumberFromPersonArgs {
  input?: Maybe<DetachPhoneNumberFromPersonInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsFetchOrCreatePhoneNumberArgs {
  input?: Maybe<FetchOrCreatePhoneNumberInput>
}


/** The root of all... mutations */
export interface MutationsAddPhotoToListingArgs {
  input?: Maybe<AddPhotoToListingInputType>
}


/** The root of all... mutations */
export interface MutationsCopyPhotoToListingArgs {
  uuid?: Maybe<Scalars['UUID']>,
  input?: Maybe<CopyPhotoToListingInputType>
}


/** The root of all... mutations */
export interface MutationsCreatePhotoArgs {
  input?: Maybe<CreatePhotoInputType>
}


/** The root of all... mutations */
export interface MutationsDeletePhotoArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdatePhotoArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<UpdatePhotoInputType>
}


/** The root of all... mutations */
export interface MutationsCreatePropertyManagerContractArgs {
  input?: Maybe<CreatePropertyManagerContractInput>
}


/** The root of all... mutations */
export interface MutationsTerminatePropertyManagerContractArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdatePropertyManagerContractArgs {
  input?: Maybe<UpdatePropertyManagerContractInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsValidatePropertyManagerContractEligibilityArgs {
  input?: Maybe<ValidatePropertyManagerContractEligibilityInput>
}


/** The root of all... mutations */
export interface MutationsCreatePropertyManagerArgs {
  input?: Maybe<CreatePropertyManagerInputType>
}


/** The root of all... mutations */
export interface MutationsCreateQualificationArgs {
  input?: Maybe<CreateQualificationInput>
}


/** The root of all... mutations */
export interface MutationsAttachRentalFormToEntityArgs {
  input?: Maybe<AttachRentalFormToEntityInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateRentalFormArgs {
  input?: Maybe<CreateRentalFormInputType>
}


/** The root of all... mutations */
export interface MutationsCreateRentalPolicyArgs {
  input?: Maybe<CreateRentalPolicyInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteRentalPolicyArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateRentalPolicyArgs {
  input?: Maybe<UpdateRentalPolicyInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateRentPaymentArgs {
  input?: Maybe<CreateRentPaymentInput>
}


/** The root of all... mutations */
export interface MutationsAttachVerificationMethodToRequirementArgs {
  input?: Maybe<AttachVerificationMethodToRequirementInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateRequirementArgs {
  input?: Maybe<CreateRequirementInput>
}


/** The root of all... mutations */
export interface MutationsDeleteRequirementArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsDetachVerificationMethodFromRequirementArgs {
  input?: Maybe<DetachVerificationMethodFromRequirementInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateReviewArgs {
  input?: Maybe<CreateReviewInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteReviewArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateReviewArgs {
  input?: Maybe<UpdateReviewInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateSavedSearchArgs {
  input?: Maybe<CreateSavedSearchInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteSavedSearchArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateSavedSearchArgs {
  uuid?: Maybe<Scalars['UUID']>,
  input?: Maybe<UpdateSavedSearchType>
}


/** The root of all... mutations */
export interface MutationsUpdateSavedSearchSubscriptionArgs {
  input?: Maybe<UpdateSavedSearchSubscriptionType>
}


/** The root of all... mutations */
export interface MutationsCreateSecurityDepositArgs {
  input?: Maybe<CreateSecurityDepositInputType>
}


/** The root of all... mutations */
export interface MutationsRetryChargeSecurityDepositArgs {
  uuid: Scalars['UUID'],
  input?: Maybe<RetryChargeSecurityDepositInputType>
}


/** The root of all... mutations */
export interface MutationsAcceptServiceDispatchArgs {
  input: AcceptServiceDispatchInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRejectServiceDispatchArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsSubmitServiceFulfillmentForApprovalArgs {
  input: SubmitServiceFulfillmentForApprovalInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAddPropertyManagerContractToServicePreferenceArgs {
  input: AddPropertyManagerContractToServicePreferenceInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateServicePreferenceArgs {
  input: CreateServicePreferenceInput
}


/** The root of all... mutations */
export interface MutationsDeleteServicePreferenceArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRemovePropertyManagerContractFromServicePreferenceArgs {
  input: RemovePropertyManagerContractFromServicePreferenceInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateServicePreferenceArgs {
  input: UpdateServicePreferenceInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateServiceProviderArgs {
  input: CreateServiceProviderInput
}


/** The root of all... mutations */
export interface MutationsUpdateServiceProviderArgs {
  input: UpdateServiceProviderInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateSpendingThresholdArgs {
  input?: Maybe<CreateSpendingThresholdInput>
}


/** The root of all... mutations */
export interface MutationsUpdateSpendingThresholdArgs {
  input: UpdateSpendingThresholdInput,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsAcceptTakeoverProposalArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCancelTakeoverRequestArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateTakeoverRequestArgs {
  input?: Maybe<CreateTakeoverRequestInputType>
}


/** The root of all... mutations */
export interface MutationsAssignInstantAgreementDocumentToTakeoverArgs {
  input?: Maybe<AssignInstantAgreementDocumentToTakeoverInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreatePaymentScheduleForTakeoverArgs {
  input?: Maybe<CreatePaymentScheduleForTakeoverInput>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsGenerateInstantAgreementForTakeoverArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsPurchaseTakeoverArgs {
  input?: Maybe<PurchaseTakeoverInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateTakeoverArgs {
  input?: Maybe<UpdateTakeoverInputType>,
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateThreadArgs {
  input?: Maybe<CreateThreadInputType>
}


/** The root of all... mutations */
export interface MutationsCreateLiveVideoAccessTokenArgs {
  threadUuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsRequestSupportArgs {
  threadUuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsUpdateReadUpToArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateUploadArgs {
  input?: Maybe<CreateUploadInputType>
}


/** The root of all... mutations */
export interface MutationsDeleteUploadArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsSignUploadRequestArgs {
  input?: Maybe<SignUploadRequestInputType>
}


/** The root of all... mutations */
export interface MutationsConfirmVerificationWithTokenArgs {
  verificationToken: Scalars['String']
}


/** The root of all... mutations */
export interface MutationsCreateVerificationArgs {
  input?: Maybe<CreateVerificationInput>
}


/** The root of all... mutations */
export interface MutationsCreateVerificationForListingArgs {
  input?: Maybe<CreateVerificationForListingInput>
}


/** The root of all... mutations */
export interface MutationsCreateViewingForListingArgs {
  input?: Maybe<CreateViewingForListingInput>
}


/** The root of all... mutations */
export interface MutationsDeleteViewingArgs {
  uuid: Scalars['UUID']
}


/** The root of all... mutations */
export interface MutationsCreateVoteArgs {
  input?: Maybe<CreateVoteInputType>
}


/** The root of all... mutations */
export interface MutationsUpdateVoteArgs {
  input?: Maybe<UpdateVoteInputType>,
  uuid: Scalars['UUID']
}

/** A type with attributes describing the next action to be taken on a verification */
export interface NextActionType {
  __typename?: 'NextActionType',
  redirectToUrl?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

export interface NormalizedPolicyTypeType {
  __typename?: 'NormalizedPolicyTypeType',
  description: Scalars['String'],
  type: Scalars['String'],
}

/** A notification */
export interface Notification {
  __typename?: 'Notification',
  createdAt?: Maybe<Scalars['Date']>,
  context?: Maybe<Scalars['String']>,
  template?: Maybe<Scalars['String']>,
  text?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<NotificationTypes>,
  pushNotificationSentAt?: Maybe<Scalars['Date']>,
  readAt?: Maybe<Scalars['Date']>,
  smsSentAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
}

/** A connection to a list of items. */
export interface NotificationConnection {
  __typename?: 'NotificationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface NotificationEdge {
  __typename?: 'NotificationEdge',
  /** The item at the end of the edge */
  node?: Maybe<Notification>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A notification set that groups similar notifications */
export interface NotificationFeedItem {
  __typename?: 'NotificationFeedItem',
  createdAt?: Maybe<Scalars['Date']>,
  context?: Maybe<Scalars['String']>,
  readAt?: Maybe<Scalars['Date']>,
  text?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<NotificationTypes>,
  uuid: Scalars['UUID'],
  notifications?: Maybe<Array<Maybe<Notification>>>,
}

/** A move from one place to another */
export interface NotificationRequest {
  __typename?: 'NotificationRequest',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
}

export interface NotificationRequestsFilterInput {
  type?: Maybe<NotificationRequestTypeInput>,
}

export interface NotificationRequestTypeInput {
  eq?: Maybe<NotificationRequestTypes>,
  in?: Maybe<Array<NotificationRequestTypes>>,
}

export enum NotificationRequestTypes {
  NEW_TIMESLOT_AVAILABLE = 'NEW_TIMESLOT_AVAILABLE'
}

export enum NotificationTypes {
  APPLICATION_ACCEPTED = 'APPLICATION_ACCEPTED',
  APPLICATION_EXPIRING = 'APPLICATION_EXPIRING',
  APPLICATION_EXPIRING_MULTIPLE = 'APPLICATION_EXPIRING_MULTIPLE',
  APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',
  GENERAL = 'GENERAL',
  ISSUE_REPORTED = 'ISSUE_REPORTED',
  ISSUE_RESOLVED = 'ISSUE_RESOLVED',
  KEYBOX_DELIVERED = 'KEYBOX_DELIVERED',
  SMART_LOCK_DELIVERED = 'SMART_LOCK_DELIVERED',
  LEASE_SIGNED_BY_LESSEE = 'LEASE_SIGNED_BY_LESSEE',
  LEASE_SIGNED_BY_LESSOR = 'LEASE_SIGNED_BY_LESSOR',
  NEW_LISTING_ANSWER = 'NEW_LISTING_ANSWER',
  NEW_PRIVATE_LISTING_ANSWER = 'NEW_PRIVATE_LISTING_ANSWER',
  NEW_PUBLIC_LISTING_ANSWER = 'NEW_PUBLIC_LISTING_ANSWER',
  NEW_LISTING_QUESTION = 'NEW_LISTING_QUESTION',
  NEW_MESSAGE = 'NEW_MESSAGE',
  NOTIFICATION_GROUP = 'NOTIFICATION_GROUP',
  SAVED_SEARCH_LISTING_MATCH = 'SAVED_SEARCH_LISTING_MATCH',
  VIEWING_REMINDER = 'VIEWING_REMINDER'
}

export interface OfferFilterType {
  type?: Maybe<OfferInputType>,
}

export interface OfferInputType {
  eq?: Maybe<OfferTypes>,
  in?: Maybe<Array<OfferTypes>>,
}

/** Bonus/Discount offers */
export interface OfferType {
  __typename?: 'OfferType',
  uuid: Scalars['UUID'],
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  /** Description of the offer. Can be shown client-side to the user */
  description?: Maybe<Scalars['String']>,
  type?: Maybe<OfferTypes>,
  isActive?: Maybe<Scalars['Boolean']>,
  /** Flat amount for the offer in cents (i.e $50 off) */
  amountInCents?: Maybe<Scalars['Int']>,
  /** Percent amount for the offer (i.e 50% off) */
  percentAmount?: Maybe<Scalars['Int']>,
  /** The minimum amount (in cents) that must be hit for the offer to work */
  minimumThresholdInCents?: Maybe<Scalars['Int']>,
  /** If the bonus is a multiple of the minimum threshold (i.e. $50 bonus for every $100 in spend) */
  isMultipleOfMinimum?: Maybe<Scalars['Boolean']>,
}

export enum OfferTypes {
  LIFETIME_BOOST = 'LIFETIME_BOOST',
  DAILY_BOOST = 'DAILY_BOOST',
  BOOST = 'BOOST'
}

/** An order */
export interface Order {
  __typename?: 'Order',
  createdAt: Scalars['Date'],
  status: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The shipments for this order */
  shipments?: Maybe<Array<Maybe<Shipment>>>,
}

/** The associated entity with an order */
export type OrderAssociatedEntity = PrivatePropertyManagerContract;

export enum OrderAssociatedEntityTypes {
  TAKEOVER = 'TAKEOVER',
  PROPERTY_MANAGER_CONTRACT = 'PROPERTY_MANAGER_CONTRACT'
}

/** An order item */
export interface OrderItem {
  __typename?: 'OrderItem',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
}

export interface OrdersFilterInput {
  status?: Maybe<OrderStatusInput>,
}

export enum OrderStatuses {
  BACKORDER = 'BACKORDER',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
  ERRORED = 'ERRORED',
  FULFILLED = 'FULFILLED',
  PENDING = 'PENDING'
}

export interface OrderStatusInput {
  eq?: Maybe<OrderStatuses>,
  in?: Maybe<Array<OrderStatuses>>,
}

export interface Organization {
  __typename?: 'Organization',
  createdAt?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  website?: Maybe<Scalars['String']>,
  /** The account for this organization */
  account?: Maybe<Account>,
  /** The landlord organization associated with this organization */
  landlord?: Maybe<Landlord>,
  /** The logo of the organization */
  logo?: Maybe<Photo>,
  /** Persons associated with this organization */
  organizationPersons?: Maybe<Array<Maybe<OrganizationPerson>>>,
  /** Phone numbers associated with this organization */
  phoneNumbers?: Maybe<Array<Maybe<PhoneNumber>>>,
  /** Platforms associated with this organization */
  platforms?: Maybe<Array<Maybe<Platform>>>,
  /** The property manager organization associated with this organization */
  propertyManager?: Maybe<PropertyManager>,
  /** The policies for this organization */
  rentalPolicies?: Maybe<Array<Maybe<RentalPolicy>>>,
  /** The service provider specific related details for this organization */
  serviceProvider?: Maybe<ServiceProvider>,
  /** The utility provider specific related details for this organization */
  utilityProvider?: Maybe<UtilityProvider>,
  /** The address unit for the headquarters of this organization */
  hqAddressUnit?: Maybe<AddressUnit>,
}

/** A connection to a list of items. */
export interface OrganizationConnection {
  __typename?: 'OrganizationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface OrganizationEdge {
  __typename?: 'OrganizationEdge',
  /** The item at the end of the edge */
  node?: Maybe<Organization>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface OrganizationPerson {
  __typename?: 'OrganizationPerson',
  createdAt?: Maybe<Scalars['Date']>,
  title?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The associated organization */
  organization?: Maybe<Organization>,
  /** The associated person */
  person?: Maybe<Person>,
  /** The work address of this organization person */
  workAddressUnit?: Maybe<AddressUnit>,
}

/** A connection to a list of items. */
export interface OrganizationPersonConnection {
  __typename?: 'OrganizationPersonConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrganizationPersonEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface OrganizationPersonEdge {
  __typename?: 'OrganizationPersonEdge',
  /** The item at the end of the edge */
  node?: Maybe<OrganizationPerson>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface OrganizationPersonsFilter {
  createdAt?: Maybe<DateInputType>,
  personName?: Maybe<SearchStringInput>,
  organizationUuid?: Maybe<Scalars['UUID']>,
  updatedAt?: Maybe<DateInputType>,
}

export interface OrganizationsFilterType {
  createdAt?: Maybe<DateInputType>,
  name?: Maybe<SearchStringInput>,
  smart?: Maybe<SearchStringInput>,
  updatedAt?: Maybe<DateInputType>,
}

export enum OrganizationsSortByEnum {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export enum OrganizationsSortDirectionEnum {
  /** Ascending */
  ASC = 'ASC',
  /** Descending */
  DESC = 'DESC'
}

export enum OvernightGuestPolicyTypeEnum {
  LIMITEDOVERNIGHTGUESTS = 'LimitedOvernightGuests',
  NOOVERNIGHTGUESTS = 'NoOvernightGuests',
  UNLIMITEDOVERNIGHTGUESTS = 'UnlimitedOvernightGuests'
}

/** Information about pagination in a connection. */
export interface PageInfo {
  __typename?: 'PageInfo',
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'],
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'],
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>,
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>,
}

/** A payment (deposit, rent payment, etc) */
export type Payment = Deposit | RentPayment;

/** A connection to a list of items. */
export interface PaymentConnection {
  __typename?: 'PaymentConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PaymentEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PaymentEdge {
  __typename?: 'PaymentEdge',
  /** The item at the end of the edge */
  node?: Maybe<Payment>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A payment schedule */
export interface PaymentSchedule {
  __typename?: 'PaymentSchedule',
  amountInCents: Scalars['Int'],
  createdAt: Scalars['Date'],
  endsAt?: Maybe<Scalars['Date']>,
  initialAmountInCents: Scalars['Int'],
  intervalAmount: Scalars['Int'],
  intervalUnits: PaymentScheduleIntervalUnits,
  lastChargedAt?: Maybe<Scalars['Date']>,
  nextPaymentIntervalStartingAt?: Maybe<Scalars['Date']>,
  nextPaymentIntervalAmountInCents?: Maybe<Scalars['Int']>,
  nextPaymentAmountInCents?: Maybe<Scalars['Int']>,
  nextPaymentAt?: Maybe<Scalars['Date']>,
  paymentMethodId?: Maybe<Scalars['String']>,
  startsAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  debitTransactions?: Maybe<Array<Maybe<Transaction>>>,
  paymentMethod?: Maybe<StripePaymentMethod>,
}

export enum PaymentScheduleIntervalUnits {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}

/** A subscription */
export interface PaymentSubscription {
  __typename?: 'PaymentSubscription',
  activatedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  cancelledAt?: Maybe<Scalars['Date']>,
  plan: PaymentSubscriptionPlans,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  providerSubscription?: Maybe<StripeSubscription>,
  invoiceItems?: Maybe<Array<Maybe<InvoiceItem>>>,
}

export enum PaymentSubscriptionPlans {
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
  ENTERPRISE = 'ENTERPRISE',
  LEASEHOLDER = 'LEASEHOLDER',
  LITE = 'LITE',
  LANDLORD = 'LANDLORD'
}

/** A Stripe payout */
export interface PayoutType {
  __typename?: 'PayoutType',
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  arrivalDate?: Maybe<Scalars['Date']>,
  automatic?: Maybe<Scalars['Boolean']>,
  created?: Maybe<Scalars['Date']>,
  currency?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  destinationType?: Maybe<StripeExternalAccountTypes>,
  failureCode?: Maybe<Scalars['String']>,
  failureMessage?: Maybe<Scalars['String']>,
  method?: Maybe<Scalars['String']>,
  statementDescriptor?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  card?: Maybe<StripeCard>,
  bankAccount?: Maybe<StripeBankAccount>,
}

export interface Person {
  __typename?: 'Person',
  bio?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  website?: Maybe<Scalars['String']>,
}

export interface PhoneNumber {
  __typename?: 'PhoneNumber',
  createdAt?: Maybe<Scalars['Date']>,
  phoneNumber?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
}

export enum PhoneNumberTypes {
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  OFFICE = 'OFFICE'
}

/** A photo */
export interface Photo {
  __typename?: 'Photo',
  createdAt: Scalars['Date'],
  entityType?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  isPrimary?: Maybe<Scalars['Boolean']>,
  /** The listing associated with this photo */
  listing?: Maybe<Listing>,
  position?: Maybe<Scalars['Int']>,
  size?: Maybe<Scalars['String']>,
  /** A list of tags for the photo. */
  tags?: Maybe<Array<Maybe<MediaTag>>>,
  type?: Maybe<Scalars['String']>,
  /** The URI for this photo. */
  uri: Scalars['String'],
  /** The base URL for this photo. */
  url?: Maybe<Scalars['String']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  width?: Maybe<Scalars['Float']>,
}

export interface PhotoAdjustmentInputType {
  bri?: Maybe<Scalars['Int']>,
  con?: Maybe<Scalars['Int']>,
  exp?: Maybe<Scalars['Int']>,
  flip?: Maybe<Scalars['String']>,
  gam?: Maybe<Scalars['Int']>,
  high?: Maybe<Scalars['Int']>,
  hue?: Maybe<Scalars['Int']>,
  invert?: Maybe<Scalars['Boolean']>,
  rot?: Maybe<Scalars['Int']>,
  sat?: Maybe<Scalars['Int']>,
  shad?: Maybe<Scalars['Int']>,
  sharp?: Maybe<Scalars['Int']>,
  usm?: Maybe<Scalars['Float']>,
  usmrad?: Maybe<Scalars['Float']>,
  vib?: Maybe<Scalars['Int']>,
}

/** A connection to a list of items. */
export interface PhotoConnection {
  __typename?: 'PhotoConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PhotoEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PhotoEdge {
  __typename?: 'PhotoEdge',
  /** The item at the end of the edge */
  node?: Maybe<Photo>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export enum PhotoEntityTypes {
  ACCESS_INSTRUCTION = 'ACCESS_INSTRUCTION',
  ACCOUNT = 'ACCOUNT',
  ADDRESS_UNIT_ROOM = 'ADDRESS_UNIT_ROOM',
  ARTICLE = 'ARTICLE',
  CONDITION_REPORT = 'CONDITION_REPORT',
  ISSUE = 'ISSUE',
  ISSUE_RESOLUTION = 'ISSUE_RESOLUTION',
  LISTING = 'LISTING',
  ORGANIZATION = 'ORGANIZATION',
  SERVICE_FULFILLMENT = 'SERVICE_FULFILLMENT',
  TAKEOVER = 'TAKEOVER',
  THREAD = 'THREAD'
}

/** A platform */
export interface Platform {
  __typename?: 'Platform',
  name: Scalars['String'],
  website: Scalars['String'],
  uuid: Scalars['UUID'],
  /** The organization that owns this platform */
  organization?: Maybe<Organization>,
}

/** A platform account */
export interface PlatformAccount {
  __typename?: 'PlatformAccount',
  activatedAt?: Maybe<Scalars['Date']>,
  password: Scalars['String'],
  username: Scalars['String'],
  shortid: Scalars['String'],
  uuid: Scalars['UUID'],
  /** The account that owns this platform account */
  account?: Maybe<Account>,
  /** The platform that this platform account belongs to */
  platform?: Maybe<Platform>,
}

export interface Policy {
  __typename?: 'Policy',
  createdAt: Scalars['Date'],
  entityType?: Maybe<Scalars['String']>,
  externalNotes?: Maybe<Scalars['String']>,
  internalNotes?: Maybe<Scalars['String']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The policy type associated to this policy */
  policyType?: Maybe<PolicyType>,
}

export interface PolicyType {
  __typename?: 'PolicyType',
  createdAt: Scalars['Date'],
  description?: Maybe<Scalars['String']>,
  normalizedPolicyType: NormalizedPolicyTypeType,
  type: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The children policy types associated to this policy type */
  childrenPolicyTypes?: Maybe<Array<Maybe<PolicyType>>>,
  /** The parent policy type associated to this policy type */
  parentPolicyType?: Maybe<PolicyType>,
  /** The policies associated to this policy type */
  policies?: Maybe<Array<Maybe<Policy>>>,
}

/** A letter sent via mail */
export interface PostalMail {
  __typename?: 'PostalMail',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
}

/** A set of access instructions for an address unit */
export interface PrivateAccessInstruction {
  __typename?: 'PrivateAccessInstruction',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  accessorEntityType?: Maybe<AccessInstructionAccessorEntityTypes>,
  endsAt?: Maybe<Scalars['Date']>,
  notes?: Maybe<Scalars['String']>,
  startsAt: Scalars['String'],
  addressUnit?: Maybe<PrivateAddressUnit>,
  /** Related photos for these access instructions */
  photos?: Maybe<Array<Maybe<Photo>>>,
}

/** A Caretaker user's public profile. */
export interface PrivateAccount {
  __typename?: 'PrivateAccount',
  averageResponseTime?: Maybe<Scalars['Int']>,
  averageResponseTimeFormatted?: Maybe<Scalars['String']>,
  authorBio?: Maybe<Scalars['String']>,
  authorRole?: Maybe<Scalars['String']>,
  authorSocialLink?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  defaultPhotoUrl?: Maybe<Scalars['String']>,
  educationLevel?: Maybe<Scalars['String']>,
  educationLevelString?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  hasConnectedFacebook?: Maybe<Scalars['Boolean']>,
  hasConnectedLinkedIn?: Maybe<Scalars['Boolean']>,
  hasEmail?: Maybe<Scalars['Boolean']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  hasPhoneNumber?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  lastSeen?: Maybe<Scalars['Date']>,
  locationString?: Maybe<Scalars['String']>,
  photoUrl?: Maybe<Scalars['String']>,
  smokes?: Maybe<Scalars['Boolean']>,
  uuid: Scalars['UUID'],
  viewerHasReviewed?: Maybe<Scalars['Boolean']>,
  yearsRenting?: Maybe<Scalars['Int']>,
  photo?: Maybe<Photo>,
  listings?: Maybe<PrivateListingConnection>,
  /** The organization associated with this profile */
  organization?: Maybe<Organization>,
  /** A list of reviews for this user. */
  reviews?: Maybe<ReviewConnection>,
  email?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  role?: Maybe<Scalars['Int']>,
  status: AccountStatuses,
  accountSettings?: Maybe<AccountSettings>,
  bookmarks?: Maybe<ListingConnection>,
  creditReports?: Maybe<Array<Maybe<PrivateCreditReport>>>,
  devices?: Maybe<Array<Maybe<Device>>>,
  documents?: Maybe<PrivateDocumentConnection>,
  flips?: Maybe<FlipConnection>,
  incomeSources?: Maybe<Array<Maybe<PrivateIncomeSource>>>,
  leases?: Maybe<PrivateLeaseConnection>,
  paymentMethods?: Maybe<Array<Maybe<StripePaymentMethod>>>,
  payoutAccounts?: Maybe<Array<Maybe<StripeExternalAccount>>>,
  platformAccounts?: Maybe<Array<Maybe<PlatformAccount>>>,
  rentals?: Maybe<FlipConnection>,
  /** A list of saved searches associated with this viewer. */
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>,
  threads?: Maybe<ThreadConnection>,
  /** A list of transactions associated with this viewer. */
  transactions?: Maybe<Array<Maybe<Transaction>>>,
  uploads?: Maybe<UploadConnection>,
  verifications?: Maybe<VerificationConnection>,
  /** The utility account thats associated to this utility account */
  utilityAccounts?: Maybe<Array<Maybe<UtilityAccount>>>,
}


/** A Caretaker user's public profile. */
export interface PrivateAccountListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  status_in?: Maybe<Array<Maybe<ListingPrivateStatuses>>>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountBookmarksArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountDocumentsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountFlipsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  isArchived?: Maybe<Scalars['Boolean']>,
  status_in?: Maybe<Array<Maybe<FlipStatuses>>>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountLeasesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  isCurrent?: Maybe<Scalars['Boolean']>,
  leaseType?: Maybe<LeaseTypes>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountRentalsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  isArchived?: Maybe<Scalars['Boolean']>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountThreadsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountUploadsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A Caretaker user's public profile. */
export interface PrivateAccountVerificationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

/** An address */
export interface PrivateAddress {
  __typename?: 'PrivateAddress',
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  googlePlaceId?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  longitude?: Maybe<Scalars['Float']>,
  neighborhood?: Maybe<Scalars['String']>,
  standardizedAddress?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  streetAddress1?: Maybe<Scalars['String']>,
  timezone?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  zipCode?: Maybe<Scalars['String']>,
  /** Building that currently exist at this address. */
  building?: Maybe<Building>,
  /** Buildings that have existed or currently exist at this address. */
  historicBuildings?: Maybe<Array<Maybe<Building>>>,
}

export interface PrivateAddressUnit {
  __typename?: 'PrivateAddressUnit',
  createdAt?: Maybe<Scalars['Date']>,
  isRoot?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  unitType?: Maybe<AddressUnitUnitTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  accessInstruction?: Maybe<PrivateAccessInstruction>,
  /** The active landlord contract for this address unit */
  activeLandlordContract?: Maybe<LandlordContract>,
  /** The active property manager contracts for this address unit */
  activePropertyManagerContracts?: Maybe<Array<Maybe<PrivatePropertyManagerContract>>>,
  address?: Maybe<Address>,
  /** The amenities of the address unit */
  addressUnitAmenities?: Maybe<Array<Maybe<AddressUnitAmenityType>>>,
  addressUnitRooms?: Maybe<Array<Maybe<AddressUnitRoom>>>,
  /** The building for this address unit */
  building?: Maybe<Building>,
  /** The calendar associated with this address unit */
  calendar?: Maybe<PrivateCalendar>,
  keybox?: Maybe<PrivateSmartLockType>,
  smartLock?: Maybe<PrivateSmartLockType>,
  /** The list of landlord contracts for this address unit */
  landlordContracts?: Maybe<Array<Maybe<LandlordContract>>>,
  /** The list of property manager contracts for this address unit */
  propertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** A list of reviews for this unit. */
  reviews?: Maybe<ReviewConnection>,
  /** The current viewer's property manager contract for this address unit */
  viewerPropertyManagerContract?: Maybe<PropertyManagerContract>,
  /** Latest cumulative inspection made on this address unit */
  conditionInspection?: Maybe<ConditionInspection>,
  /** Inspections made on this address unit */
  conditionInspections?: Maybe<Array<Maybe<ConditionInspection>>>,
  /** All issues reported on this address unit */
  issues?: Maybe<IssueConnection>,
  lease?: Maybe<PrivateLease>,
  leases?: Maybe<Array<Maybe<PrivateLease>>>,
  takeover?: Maybe<Takeover>,
  takeovers?: Maybe<Array<Maybe<Takeover>>>,
  utilityServices?: Maybe<Array<Maybe<UtilityService>>>,
  orders?: Maybe<Array<Maybe<PrivateOrder>>>,
}


export interface PrivateAddressUnitReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


export interface PrivateAddressUnitLeaseArgs {
  filter?: Maybe<LeasesFilterInput>
}


export interface PrivateAddressUnitLeasesArgs {
  filter?: Maybe<LeasesFilterInput>
}


export interface PrivateAddressUnitTakeoverArgs {
  filter?: Maybe<TakeoversFilterInput>
}


export interface PrivateAddressUnitTakeoversArgs {
  filter?: Maybe<TakeoversFilterInput>
}

/** An application tracks a user through the leasing process for a given listing */
export interface PrivateApplication {
  __typename?: 'PrivateApplication',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  acceptedAt?: Maybe<Scalars['Date']>,
  applicationRequestedAt?: Maybe<Scalars['Date']>,
  rejectedAt?: Maybe<Scalars['Date']>,
  status?: Maybe<ApplicationStatuses>,
  submittedAt?: Maybe<Scalars['Date']>,
  /** The applicants' profiles */
  applicants?: Maybe<Array<Maybe<ApplicationAccount>>>,
  /** The lease associated with this application */
  lease?: Maybe<ApplicationLease>,
  /** The listing associated with this application */
  listing?: Maybe<Listing>,
  /** The thread for this application */
  thread?: Maybe<Thread>,
}

/** A connection to a list of items. */
export interface PrivateApplicationConnection {
  __typename?: 'PrivateApplicationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivateApplicationEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PrivateApplicationEdge {
  __typename?: 'PrivateApplicationEdge',
  /** The item at the end of the edge */
  node?: Maybe<PrivateApplication>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A building */
export interface PrivateBuilding {
  __typename?: 'PrivateBuilding',
  averageRatingValue?: Maybe<Scalars['Float']>,
  averageUnitRentInCents?: Maybe<Scalars['Float']>,
  createdAt?: Maybe<Scalars['Date']>,
  description?: Maybe<Scalars['String']>,
  internalNotes?: Maybe<Scalars['String']>,
  isFlipApproved: Scalars['Boolean'],
  name?: Maybe<Scalars['String']>,
  postedAt?: Maybe<Scalars['Date']>,
  slug?: Maybe<Scalars['String']>,
  transferNotes?: Maybe<Scalars['String']>,
  unitCount?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  website?: Maybe<Scalars['String']>,
  /** The active landlord contract for this building */
  activeLandlordContract?: Maybe<LandlordContract>,
  /** The active public property manager contracts for this building */
  activePropertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** The address for this building */
  address?: Maybe<Address>,
  /** The address unit for this building */
  addressUnit?: Maybe<AddressUnit>,
  /** The articles written about this building */
  articles?: Maybe<Array<Maybe<Article>>>,
  /** The assistant requests associated to this building */
  assistantRequests?: Maybe<Array<Maybe<AssistantRequest>>>,
  /** The amenities for this building */
  buildingAmenities?: Maybe<Array<Maybe<BuildingAmenityType>>>,
  /** The landlord for this building */
  landlord?: Maybe<Landlord>,
  /** The list of landlord contracts for this building */
  landlordContracts?: Maybe<Array<Maybe<LandlordContract>>>,
  /** The list of listings in this building */
  listings?: Maybe<ListingConnection>,
  /** Policies associated to this building */
  policies?: Maybe<Array<Maybe<Policy>>>,
  /** The property manager for this building */
  propertyManager?: Maybe<PropertyManager>,
  /** The list of property manager contracts for this building */
  propertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** The recommend rental policy for this building */
  recommendedRentalPolicy?: Maybe<RentalPolicy>,
  rentalPolicies?: Maybe<Array<Maybe<RentalPolicy>>>,
  /** A list of reviews for this building. */
  reviews?: Maybe<ReviewConnection>,
  /** Photos people have uploaded of this building */
  userPhotos?: Maybe<PhotoConnection>,
  /** Utility connections that exist at this building. */
  utilityConnections?: Maybe<Array<Maybe<UtilityConnection>>>,
}


/** A building */
export interface PrivateBuildingListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ListingsFilterInput>
}


/** A building */
export interface PrivateBuildingRentalPoliciesArgs {
  type_in?: Maybe<Array<Maybe<RentalPolicyTypes>>>
}


/** A building */
export interface PrivateBuildingReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A building */
export interface PrivateBuildingUserPhotosArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

export interface PrivateCalendar {
  __typename?: 'PrivateCalendar',
  createdAt?: Maybe<Scalars['Date']>,
  entityType?: Maybe<CalendarEntityTypes>,
  uuid: Scalars['UUID'],
  addressUnit?: Maybe<AddressUnit>,
  /** A list of available time slots on this calendar. */
  availableTimeSlots?: Maybe<Array<Maybe<AvailableTimeSlot>>>,
  /** The viewer's notification requests for this calendar. */
  viewerNotificationRequests?: Maybe<Array<Maybe<NotificationRequest>>>,
  /** A list of time slots currently associated with this calendar. */
  timeSlots?: Maybe<Array<Maybe<TimeSlot>>>,
}


export interface PrivateCalendarViewerNotificationRequestsArgs {
  filter?: Maybe<NotificationRequestsFilterInput>
}

export interface PrivateCreditReport {
  __typename?: 'PrivateCreditReport',
  uuid: Scalars['UUID'],
  createdAt?: Maybe<Scalars['Date']>,
  creditScore?: Maybe<Scalars['Int']>,
  expiresAt?: Maybe<Scalars['Date']>,
  hasCriminalRecords?: Maybe<Scalars['Boolean']>,
  hasEvictionRecords?: Maybe<Scalars['Boolean']>,
  isPaidFor?: Maybe<Scalars['Boolean']>,
  reportCompletedAt?: Maybe<Scalars['Date']>,
  reportRequestedAt?: Maybe<Scalars['Date']>,
  transunionReportId?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  transaction?: Maybe<Transaction>,
}

export interface PrivateDocument {
  __typename?: 'PrivateDocument',
  createdAt?: Maybe<Scalars['Date']>,
  displayName?: Maybe<Scalars['String']>,
  filename?: Maybe<Scalars['String']>,
  fileType?: Maybe<Scalars['String']>,
  tag?: Maybe<DocumentTags>,
  type?: Maybe<DocumentTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  accessToken?: Maybe<Scalars['String']>,
  accessUrl?: Maybe<Scalars['String']>,
  /** This field indicates if the document has been activated for esignatures with Hellosign */
  areEsignaturesEnabled?: Maybe<Scalars['Boolean']>,
  downloadUrl?: Maybe<Scalars['String']>,
  eSignedUrl?: Maybe<Scalars['String']>,
  eSigningEmbedUrl?: Maybe<Scalars['String']>,
  expiresAt?: Maybe<Scalars['Date']>,
  fullyExecutedAt?: Maybe<Scalars['Date']>,
  /** This field indicates if the user has marked the document as something they would like to sign */
  isSigningEnabled?: Maybe<Scalars['Boolean']>,
  viewerHasSigned?: Maybe<Scalars['Boolean']>,
  application?: Maybe<Application>,
  documentUsers?: Maybe<Array<Maybe<DocumentUser>>>,
  flip?: Maybe<Flip>,
  lease?: Maybe<PrivateLease>,
  upload?: Maybe<Upload>,
  user?: Maybe<Account>,
  users?: Maybe<Array<Maybe<Account>>>,
}

/** A connection to a list of items. */
export interface PrivateDocumentConnection {
  __typename?: 'PrivateDocumentConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivateDocumentEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PrivateDocumentEdge {
  __typename?: 'PrivateDocumentEdge',
  /** The item at the end of the edge */
  node?: Maybe<PrivateDocument>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** An income source */
export interface PrivateIncomeSource {
  __typename?: 'PrivateIncomeSource',
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  active?: Maybe<Scalars['Boolean']>,
  annualIncome?: Maybe<Scalars['Float']>,
  bonus?: Maybe<Scalars['Float']>,
  company?: Maybe<Scalars['String']>,
  days?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  monthlyIncome?: Maybe<Scalars['Float']>,
  plaidAnnualIncome?: Maybe<Scalars['Float']>,
  plaidCompany?: Maybe<Scalars['String']>,
  plaidDays?: Maybe<Scalars['Int']>,
  source?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  type?: Maybe<Scalars['String']>,
  verified?: Maybe<Scalars['Boolean']>,
  verifiedAt?: Maybe<Scalars['Date']>,
  verificationLastRequestedAt?: Maybe<Scalars['Date']>,
  account?: Maybe<Account>,
  /** A list of uploads attached to this incomesource. */
  uploads?: Maybe<Array<Maybe<Upload>>>,
  user?: Maybe<Account>,
}

/** An inventory item */
export interface PrivateInventoryItem {
  __typename?: 'PrivateInventoryItem',
  barcode?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The invoice items related to this inventory item */
  invoiceItems?: Maybe<InvoiceItem>,
  /** The keybox associated with this inventory item */
  keybox?: Maybe<PrivateSmartLockType>,
  /** The smart lock associated with this inventory item */
  smartLock?: Maybe<PrivateSmartLockType>,
  /** Inventory item product type */
  product?: Maybe<Product>,
  /** The address unit of this inventory item */
  locationAddressUnit?: Maybe<PrivateAddressUnit>,
  /** Order items this inventory item has belonged to */
  orderItems?: Maybe<Array<Maybe<PrivateOrderItem>>>,
}

/** A lease agreement */
export interface PrivateLease {
  __typename?: 'PrivateLease',
  createdAt?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  startDate?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  voidedAt?: Maybe<Scalars['Date']>,
  addressUnit?: Maybe<PrivateAddressUnit>,
  childLease?: Maybe<PrivateLease>,
  childLeases?: Maybe<Array<Maybe<PrivateLease>>>,
  landlord?: Maybe<Account>,
  listing?: Maybe<PrivateListing>,
  checksContext?: Maybe<ChecksContextType>,
  defaultPaymentMethodId?: Maybe<Scalars['String']>,
  deltaPaymentDaysFromDueDate?: Maybe<Scalars['Int']>,
  hasAutopayEnabled?: Maybe<Scalars['Boolean']>,
  nextRentPaymentAmountInCents?: Maybe<Scalars['Int']>,
  nextRentPaymentMonthEnding?: Maybe<Scalars['Date']>,
  nextRentPaymentMonthStarting?: Maybe<Scalars['Date']>,
  rentDebitPlatformFeeMultiplier?: Maybe<Scalars['Float']>,
  rentInCents?: Maybe<Scalars['Int']>,
  securityDepositInCents?: Maybe<Scalars['Int']>,
  type?: Maybe<LeaseTypes>,
  upfrontRentInCents?: Maybe<Scalars['Int']>,
  application?: Maybe<PrivateApplication>,
  defaultPaymentMethod?: Maybe<StripePaymentMethod>,
  checksPayableToAddressUnit?: Maybe<PrivateAddressUnit>,
  document?: Maybe<PrivateDocument>,
  flip?: Maybe<Flip>,
  leaseInvite?: Maybe<LeaseInvite>,
  lessee?: Maybe<Account>,
  lessor?: Maybe<Account>,
  rentPayment?: Maybe<RentPayment>,
  rentPayments?: Maybe<RentPaymentConnection>,
  securityDeposit?: Maybe<SecurityDepositType>,
  securityDeposits?: Maybe<SecurityDepositTypeConnection>,
  takeover?: Maybe<Takeover>,
  /** The thread for this lease */
  thread?: Maybe<Thread>,
  deltaPaymentDaysFromDueDay?: Maybe<Scalars['Int']>,
  isCurrent?: Maybe<Scalars['Boolean']>,
  landlordSignedAt?: Maybe<Scalars['Date']>,
  lesseeSignedAt?: Maybe<Scalars['Date']>,
  lessorSignedAt?: Maybe<Scalars['Date']>,
  nextRentPaymentAmount?: Maybe<Scalars['Float']>,
  rentDebitPlatformFeeInCents?: Maybe<Scalars['Int']>,
  upfrontRentDebitPlatformFeeInCents?: Maybe<Scalars['Int']>,
  autopaySourceId?: Maybe<Scalars['String']>,
}


/** A lease agreement */
export interface PrivateLeaseChildLeaseArgs {
  filter?: Maybe<ChildLeasesFilterInput>
}


/** A lease agreement */
export interface PrivateLeaseChildLeasesArgs {
  filter?: Maybe<ChildLeasesFilterInput>
}


/** A lease agreement */
export interface PrivateLeaseRentPaymentArgs {
  uuid: Scalars['UUID']
}


/** A lease agreement */
export interface PrivateLeaseRentPaymentsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A lease agreement */
export interface PrivateLeaseSecurityDepositArgs {
  uuid: Scalars['UUID']
}

/** A connection to a list of items. */
export interface PrivateLeaseConnection {
  __typename?: 'PrivateLeaseConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivateLeaseEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PrivateLeaseEdge {
  __typename?: 'PrivateLeaseEdge',
  /** The item at the end of the edge */
  node?: Maybe<PrivateLease>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** An invitation for a lessee to join a lease agreement. */
export interface PrivateLeaseInvite {
  __typename?: 'PrivateLeaseInvite',
  acceptedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  email: Scalars['String'],
  expiresAt?: Maybe<Scalars['Date']>,
  isInviteeRegistered: Scalars['Boolean'],
  revokedAt?: Maybe<Scalars['Date']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** Viewer is the one being invited */
  viewerIsInvitee?: Maybe<Scalars['Boolean']>,
  /** The account that accepted this invitation */
  accepterAccount?: Maybe<Account>,
  /** The account that created this invitation */
  inviterAccount?: Maybe<Account>,
  /** The associated lease */
  lease?: Maybe<PrivateLease>,
}

/** A user's own listing on Caretaker. */
export interface PrivateListing {
  __typename?: 'PrivateListing',
  depositInCents?: Maybe<Scalars['Int']>,
  depositInDollars?: Maybe<Scalars['Float']>,
  securityDepositInCents?: Maybe<Scalars['Int']>,
  areaCode?: Maybe<Scalars['String']>,
  bathrooms?: Maybe<Scalars['Float']>,
  bedrooms?: Maybe<Scalars['Int']>,
  city?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  defaultUpfrontRentInCents?: Maybe<Scalars['Int']>,
  defaultUpfrontRentInDollars?: Maybe<Scalars['Float']>,
  defaultSecurityDepositInCents?: Maybe<Scalars['Int']>,
  defaultSecurityDepositInDollars?: Maybe<Scalars['Float']>,
  description?: Maybe<Scalars['String']>,
  earliestMoveInDate?: Maybe<Scalars['Date']>,
  earliestMoveInInDays?: Maybe<Scalars['Int']>,
  furnishingType?: Maybe<ListingFurnishingTypes>,
  referenceId?: Maybe<Scalars['String']>,
  /** This listing grants access to visitors via a keybox */
  hasKeyboxAccess?: Maybe<Scalars['Boolean']>,
  /** This listing grants access to visitors via a smart lock */
  hasSmartLockAccess?: Maybe<Scalars['Boolean']>,
  hasQualityPhotos?: Maybe<Scalars['Boolean']>,
  headline?: Maybe<Scalars['String']>,
  /** Less than 24 hours old */
  isNew?: Maybe<Scalars['Boolean']>,
  isLeaseExtendableWithLandlord?: Maybe<Scalars['Boolean']>,
  isManagedByFlip: Scalars['Boolean'],
  isVerified?: Maybe<Scalars['Boolean']>,
  latitude?: Maybe<Scalars['Float']>,
  leaseEndDate?: Maybe<Scalars['Date']>,
  leaseType?: Maybe<ListingLeaseTypes>,
  listedAt?: Maybe<Scalars['Date']>,
  bumpedAt?: Maybe<Scalars['Date']>,
  listingType?: Maybe<ListingTypes>,
  listingTypeString?: Maybe<Scalars['String']>,
  locationString?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['Float']>,
  maxLeaseTerm?: Maybe<Scalars['Int']>,
  minLeaseTerm?: Maybe<Scalars['Int']>,
  neighborhood?: Maybe<Scalars['String']>,
  photosReviewedAt?: Maybe<Scalars['Date']>,
  propertyType?: Maybe<Scalars['String']>,
  rentInCents?: Maybe<Scalars['Int']>,
  rentInDollars?: Maybe<Scalars['Float']>,
  requiresDeposit?: Maybe<Scalars['Boolean']>,
  shouldRequestLandlordApproval?: Maybe<Scalars['Boolean']>,
  slug?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
  status?: Maybe<ListingPrivateStatuses>,
  timeRemainingInDays?: Maybe<Scalars['Int']>,
  timeRemainingString?: Maybe<Scalars['String']>,
  totalRooms?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['Date']>,
  url?: Maybe<Scalars['String']>,
  uuid: Scalars['UUID'],
  viewerHasBookmarked?: Maybe<Scalars['Boolean']>,
  viewerHasMessagedLister?: Maybe<Scalars['Boolean']>,
  /** Viewer is owner of listing */
  viewerIsOwner?: Maybe<Scalars['Boolean']>,
  viewerHasReviewed?: Maybe<Scalars['Boolean']>,
  viewerMeetsRequirements?: Maybe<Scalars['Boolean']>,
  views?: Maybe<Scalars['Int']>,
  /** The account that owns this listing */
  account?: Maybe<Account>,
  /** The address unit associated with this listing */
  addressUnit?: Maybe<PrivateAddressUnit>,
  /** A list of amenities available at this listing. */
  amenities?: Maybe<Array<Maybe<Amenity>>>,
  /** A list of available viewings for this listing. */
  availableViewings?: Maybe<Array<Maybe<AvailableTimeSlot>>>,
  /** A list of available viewing slots for this listing. */
  availableViewingSlots?: Maybe<Array<Maybe<AvailableTimeSlot>>>,
  building?: Maybe<PrivateBuilding>,
  /** Asked questions for a listing. */
  listingQuestions?: Maybe<Array<Maybe<PrivateListingQuestion>>>,
  /** The next available viewing slot for this listing */
  nextAvailableViewingSlot?: Maybe<AvailableTimeSlot>,
  /** A list of photos of this listing. */
  photos?: Maybe<Array<Maybe<PrivatePhoto>>>,
  /** The primary photo for a listing. */
  primaryPhoto?: Maybe<PrivatePhoto>,
  /** The property manager contract associated with this listing */
  propertyManagerContract?: Maybe<PrivatePropertyManagerContract>,
  /** Leasing settings set by the landlord of this listing */
  resolvedLeasesSetting?: Maybe<LeasesSetting>,
  /** The resolved rental policy used for this listing */
  resolvedRentalPolicy?: Maybe<RentalPolicy>,
  /** A list of reviews for this listing. */
  reviews?: Maybe<ReviewConnection>,
  /** The viewer's upcoming viewing for this listing. */
  upcomingViewerViewing?: Maybe<PrivateViewing>,
  /** The owner of the listing */
  user?: Maybe<Account>,
  /** A list of verifications for this listing. */
  verifications?: Maybe<Array<Maybe<Verification>>>,
  /** The viewer's flip for this listing. */
  viewerFlip?: Maybe<Flip>,
  /** The viewer's upcoming viewing for this listing. */
  viewerViewing?: Maybe<PrivateViewing>,
  /** The viewer's viewings for this listing. */
  viewerViewings?: Maybe<Array<Maybe<PrivateViewing>>>,
  /** The viewer's referral link for this listing. */
  viewerReferral?: Maybe<Referral>,
  accessToken?: Maybe<Scalars['String']>,
  apartmentNumber?: Maybe<Scalars['String']>,
  bumpCount?: Maybe<Scalars['Int']>,
  extendedViewingNotes?: Maybe<Scalars['String']>,
  guaranteeStatus?: Maybe<Scalars['String']>,
  isBumpable?: Maybe<Scalars['Boolean']>,
  isConcierge?: Maybe<Scalars['Boolean']>,
  isEligibleForTakeover?: Maybe<Scalars['Boolean']>,
  isPremium?: Maybe<Scalars['Boolean']>,
  isRepostable?: Maybe<Scalars['Boolean']>,
  keycafeKeyId?: Maybe<Scalars['Int']>,
  streetAddress?: Maybe<Scalars['String']>,
  subleaseReviewedAt?: Maybe<Scalars['Date']>,
  unit?: Maybe<AddressUnit>,
  unlistedAt?: Maybe<Scalars['Date']>,
  viewingNotes?: Maybe<Scalars['String']>,
  applications?: Maybe<PrivateApplicationConnection>,
  boosts?: Maybe<ListingBoostConnection>,
  flips?: Maybe<FlipConnection>,
  keybox?: Maybe<PrivateSmartLockType>,
  smartLock?: Maybe<PrivateSmartLockType>,
  rentals?: Maybe<FlipConnection>,
  rentPayment?: Maybe<RentPayment>,
  /** All rent payments made to leases associated with this listing */
  rentPayments?: Maybe<RentPaymentConnection>,
  securityDeposit?: Maybe<SecurityDepositType>,
  /** All security deposits put down on leases associated with this listing */
  securityDeposits?: Maybe<SecurityDepositTypeConnection>,
  takeover?: Maybe<Takeover>,
  /** All viewings for this listing. */
  viewings?: Maybe<Array<Maybe<PrivateViewing>>>,
}


/** A user's own listing on Caretaker. */
export interface PrivateListingPhotosArgs {
  position?: Maybe<Scalars['Int']>
}


/** A user's own listing on Caretaker. */
export interface PrivateListingReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** A user's own listing on Caretaker. */
export interface PrivateListingApplicationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ApplicationsFilterInput>
}


/** A user's own listing on Caretaker. */
export interface PrivateListingFlipsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  isArchived?: Maybe<Scalars['Boolean']>,
  status_in?: Maybe<Array<Maybe<FlipStatuses>>>
}


/** A user's own listing on Caretaker. */
export interface PrivateListingRentalsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  isArchived?: Maybe<Scalars['Boolean']>
}


/** A user's own listing on Caretaker. */
export interface PrivateListingRentPaymentArgs {
  uuid: Scalars['UUID']
}


/** A user's own listing on Caretaker. */
export interface PrivateListingSecurityDepositArgs {
  uuid: Scalars['UUID']
}

/** A listing answer */
export interface PrivateListingAnswer {
  __typename?: 'PrivateListingAnswer',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  postedAt?: Maybe<Scalars['Date']>,
  text: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
}

/** A connection to a list of items. */
export interface PrivateListingConnection {
  __typename?: 'PrivateListingConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivateListingEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PrivateListingEdge {
  __typename?: 'PrivateListingEdge',
  /** The item at the end of the edge */
  node?: Maybe<PrivateListing>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A listing question */
export interface PrivateListingQuestion {
  __typename?: 'PrivateListingQuestion',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt: Scalars['Date'],
  prompt: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  askerAccount?: Maybe<Account>,
  listing?: Maybe<Listing>,
  listingAnswer?: Maybe<ListingAnswer>,
}

/** A connection to a list of items. */
export interface PrivateListingQuestionConnection {
  __typename?: 'PrivateListingQuestionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivateListingQuestionEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PrivateListingQuestionEdge {
  __typename?: 'PrivateListingQuestionEdge',
  /** The item at the end of the edge */
  node?: Maybe<PrivateListingQuestion>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface PrivateListingsFilterInput {
  smart?: Maybe<SearchStringInput>,
  statusIn?: Maybe<Array<ListingPrivateStatuses>>,
  isOccupied?: Maybe<Scalars['Boolean']>,
}

/** An order */
export interface PrivateOrder {
  __typename?: 'PrivateOrder',
  createdAt: Scalars['Date'],
  status: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The shipments for this order */
  shipments?: Maybe<Array<Maybe<PrivateShipment>>>,
  discountInCents: Scalars['Int'],
  processedAt?: Maybe<Scalars['Date']>,
  processor: Scalars['String'],
  processorId: Scalars['String'],
  subtotalInCents: Scalars['Int'],
  taxInCents: Scalars['Int'],
  totalInCents: Scalars['Int'],
  /** The account who placed the order */
  account?: Maybe<Account>,
  /** The entity associated with this order */
  associatedEntity?: Maybe<OrderAssociatedEntity>,
  /** The items for this order */
  orderItems?: Maybe<Array<Maybe<PrivateOrderItem>>>,
  processorOrder?: Maybe<ShipheroOrder>,
  /** The property manager contract associated with this order */
  propertyManagerContract?: Maybe<PropertyManagerContract>,
  /** The shipping address unit tied to the order */
  shippingAddressUnit?: Maybe<AddressUnit>,
  /** The user who placed the order */
  user?: Maybe<Account>,
}

/** A connection to a list of items. */
export interface PrivateOrderConnection {
  __typename?: 'PrivateOrderConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivateOrderEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PrivateOrderEdge {
  __typename?: 'PrivateOrderEdge',
  /** The item at the end of the edge */
  node?: Maybe<PrivateOrder>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** An order item */
export interface PrivateOrderItem {
  __typename?: 'PrivateOrderItem',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  discountInCents: Scalars['Int'],
  externalId?: Maybe<Scalars['String']>,
  priceInCents: Scalars['Int'],
  quantity: Scalars['Int'],
  /** The inventory items for this order item */
  inventoryItems?: Maybe<Array<Maybe<PrivateInventoryItem>>>,
  /** The order this order item belongs to */
  order?: Maybe<PrivateOrder>,
  /** The product type of this order item */
  product?: Maybe<Product>,
  /** The shipments for this order item */
  shipments?: Maybe<Array<Maybe<PrivateOrderItemShipment>>>,
}

/** An orderitem shipment */
export interface PrivateOrderItemShipment {
  __typename?: 'PrivateOrderItemShipment',
  createdAt: Scalars['Date'],
  deliveredAt?: Maybe<Scalars['Date']>,
  shippedAt?: Maybe<Scalars['Date']>,
  status: ShipmentStatuses,
  type: ShipmentTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The order this shipment belongs to */
  order?: Maybe<PrivateOrder>,
  carrier: Scalars['String'],
  labelUrl?: Maybe<Scalars['String']>,
  method?: Maybe<Scalars['String']>,
  processor: ShipmentProcessors,
  processorId?: Maybe<Scalars['String']>,
  trackingNumber: Scalars['String'],
  trackingUrl?: Maybe<Scalars['String']>,
  /** The items in this shipment */
  orderItems?: Maybe<Array<Maybe<PrivateShipmentOrderItem>>>,
  shippedQuantity: Scalars['Int'],
}

export interface PrivateOrganization {
  __typename?: 'PrivateOrganization',
  createdAt?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  website?: Maybe<Scalars['String']>,
  /** The account for this organization */
  account?: Maybe<PrivateAccount>,
  /** The landlord organization associated with this organization */
  landlord?: Maybe<Landlord>,
  /** The logo of the organization */
  logo?: Maybe<Photo>,
  /** Persons associated with this organization */
  organizationPersons?: Maybe<Array<Maybe<OrganizationPerson>>>,
  /** Phone numbers associated with this organization */
  phoneNumbers?: Maybe<Array<Maybe<PhoneNumber>>>,
  /** Platforms associated with this organization */
  platforms?: Maybe<Array<Maybe<Platform>>>,
  /** The property manager organization associated with this organization */
  propertyManager?: Maybe<PropertyManager>,
  /** The policies for this organization */
  rentalPolicies?: Maybe<Array<Maybe<RentalPolicy>>>,
  /** The service provider specific related details for this organization */
  serviceProvider?: Maybe<PrivateServiceProvider>,
  /** The utility provider specific related details for this organization */
  utilityProvider?: Maybe<UtilityProvider>,
  /** The address unit for the headquarters of this organization */
  hqAddressUnit?: Maybe<AddressUnit>,
}

/** A photo */
export interface PrivatePhoto {
  __typename?: 'PrivatePhoto',
  createdAt: Scalars['Date'],
  entityType?: Maybe<Scalars['String']>,
  height?: Maybe<Scalars['Float']>,
  isPrimary?: Maybe<Scalars['Boolean']>,
  /** The listing associated with this photo */
  listing?: Maybe<PrivateListing>,
  position?: Maybe<Scalars['Int']>,
  size?: Maybe<Scalars['String']>,
  /** A list of tags for the photo. */
  tags?: Maybe<Array<Maybe<MediaTag>>>,
  type?: Maybe<Scalars['String']>,
  /** The URI for this photo. */
  uri: Scalars['String'],
  /** The base URL for this photo. */
  url?: Maybe<Scalars['String']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  width?: Maybe<Scalars['Float']>,
}

export interface PrivatePropertyManagerContract {
  __typename?: 'PrivatePropertyManagerContract',
  createdAt: Scalars['Date'],
  endsAt?: Maybe<Scalars['Date']>,
  isSubManagementContract?: Maybe<Scalars['Boolean']>,
  startsAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The address unit associated with this property manager contract */
  addressUnit?: Maybe<PrivateAddressUnit>,
  /** The building associated with this property manager contract */
  building?: Maybe<Building>,
  /** The parent property manager contract associated with this property manager contract */
  parentPropertyManagerContract?: Maybe<PropertyManagerContract>,
  /** The landlord associated with this property manager contract */
  propertyManager?: Maybe<PropertyManager>,
  /** The leases setting for this property manager contract. */
  leasesSetting?: Maybe<LeasesSetting>,
  listing?: Maybe<PrivateListing>,
  /** The promisee account for this property manager contract. */
  promiseeAccount?: Maybe<Account>,
  /** The service preferences to use for this property manager contract */
  servicePreferences?: Maybe<Array<Maybe<ServicePreference>>>,
  /** The maintenance setting for this property manager contract. */
  maintenanceSetting?: Maybe<MaintenanceSetting>,
  /** The orders associated with this property manager contract */
  orders?: Maybe<Array<Maybe<PrivateOrder>>>,
  /** The rental policy for this property manager contract. */
  rentalPolicy?: Maybe<RentalPolicy>,
}


export interface PrivatePropertyManagerContractOrdersArgs {
  filter?: Maybe<OrdersFilterInput>
}

/** A connection to a list of items. */
export interface PrivatePropertyManagerContractConnection {
  __typename?: 'PrivatePropertyManagerContractConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivatePropertyManagerContractEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PrivatePropertyManagerContractEdge {
  __typename?: 'PrivatePropertyManagerContractEdge',
  /** The item at the end of the edge */
  node?: Maybe<PrivatePropertyManagerContract>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface PrivatePropertyManagerContractsFilterAddressUnitInput {
  smart?: Maybe<SearchStringInput>,
}

export interface PrivatePropertyManagerContractsFilterInput {
  addressUnit?: Maybe<PrivatePropertyManagerContractsFilterAddressUnitInput>,
  isActive?: Maybe<Scalars['Boolean']>,
  uuid?: Maybe<Scalars['UUID']>,
}

/** A service provider */
export interface PrivateServiceProvider {
  __typename?: 'PrivateServiceProvider',
  serviceType: ServiceTypes,
  uuid: Scalars['UUID'],
  /** The organization details for the service provider */
  organization?: Maybe<Organization>,
  createdAt: Scalars['Date'],
  serviceAreaBoundingBox?: Maybe<Scalars['String']>,
  updatedAt: Scalars['Date'],
  /** Service dispatches associated with this service provider. */
  serviceDispatch?: Maybe<ServiceDispatch>,
}


/** A service provider */
export interface PrivateServiceProviderServiceDispatchArgs {
  uuid: Scalars['UUID']
}

/** An order shipment */
export interface PrivateShipment {
  __typename?: 'PrivateShipment',
  createdAt: Scalars['Date'],
  deliveredAt?: Maybe<Scalars['Date']>,
  shippedAt?: Maybe<Scalars['Date']>,
  status: ShipmentStatuses,
  type: ShipmentTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The order this shipment belongs to */
  order?: Maybe<PrivateOrder>,
  carrier: Scalars['String'],
  labelUrl?: Maybe<Scalars['String']>,
  method?: Maybe<Scalars['String']>,
  processor: ShipmentProcessors,
  processorId?: Maybe<Scalars['String']>,
  trackingNumber: Scalars['String'],
  trackingUrl?: Maybe<Scalars['String']>,
  /** The items in this shipment */
  orderItems?: Maybe<Array<Maybe<PrivateShipmentOrderItem>>>,
}

/** A shipment orderitem */
export interface PrivateShipmentOrderItem {
  __typename?: 'PrivateShipmentOrderItem',
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  discountInCents: Scalars['Int'],
  externalId?: Maybe<Scalars['String']>,
  priceInCents: Scalars['Int'],
  quantity: Scalars['Int'],
  /** The inventory items for this order item */
  inventoryItems?: Maybe<Array<Maybe<PrivateInventoryItem>>>,
  /** The order this order item belongs to */
  order?: Maybe<PrivateOrder>,
  /** The product type of this order item */
  product?: Maybe<Product>,
  /** The shipments for this order item */
  shipments?: Maybe<Array<Maybe<PrivateOrderItemShipment>>>,
  shippedQuantity: Scalars['Int'],
}

export interface PrivateSmartLockType {
  __typename?: 'PrivateSmartLockType',
  createdAt?: Maybe<Scalars['Date']>,
  isSetup: Scalars['Boolean'],
  nanoid?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The product related to this smart lock */
  product?: Maybe<Product>,
}

export interface PrivateViewing {
  __typename?: 'PrivateViewing',
  createdAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  endDate?: Maybe<Scalars['Date']>,
  keyboxCode?: Maybe<Scalars['String']>,
  smartLockCode?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  viewingNotes?: Maybe<Scalars['String']>,
  account?: Maybe<Viewingaccount>,
  addressUnit?: Maybe<ViewingAddressUnit>,
  calendarEvent?: Maybe<CalendarEvent>,
  listing?: Maybe<Listing>,
  /** The thread for this application */
  thread?: Maybe<Thread>,
  user?: Maybe<Account>,
}

/** A connection to a list of items. */
export interface PrivateViewingConnection {
  __typename?: 'PrivateViewingConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivateViewingEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface PrivateViewingEdge {
  __typename?: 'PrivateViewingEdge',
  /** The item at the end of the edge */
  node?: Maybe<PrivateViewing>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A product */
export interface Product {
  __typename?: 'Product',
  createdAt: Scalars['Date'],
  depth?: Maybe<Scalars['Float']>,
  description?: Maybe<Scalars['String']>,
  dimensionsUnits?: Maybe<Scalars['String']>,
  length?: Maybe<Scalars['Float']>,
  lowInventoryThreshold: Scalars['Int'],
  name: Scalars['String'],
  priceInCents: Scalars['Int'],
  sku: Scalars['String'],
  tableName?: Maybe<Scalars['String']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  weight?: Maybe<Scalars['Float']>,
  weightUnits?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Float']>,
  /** Inventory items belonging to this product */
  inventoryItems?: Maybe<Array<Maybe<InventoryItem>>>,
}


/** A product */
export interface ProductInventoryItemsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<InventoryItemsFilterInput>
}

export enum Products {
  APARTMENT_APP = 'APARTMENT_APP',
  CARETAKER = 'CARETAKER'
}

/** Types of product skus */
export enum ProductSkus {
  BLUE_FREEDOM_STICKER = 'BLUE_FREEDOM_STICKER',
  BOX_9X8X2 = 'BOX_9X8X2',
  KEYBOX = 'KEYBOX',
  DEADBOLT_IGN1 = 'DEADBOLT_IGN1',
  KEYBOX_SETUP_BOOKLET = 'KEYBOX_SETUP_BOOKLET'
}

export interface PropertyManager {
  __typename?: 'PropertyManager',
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The buildings associated with this property manager */
  buildings?: Maybe<Array<Maybe<Building>>>,
  /** The property manager contracts associated with this landlord */
  propertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** The organization for this property manager */
  organization?: Maybe<Organization>,
}

/** A connection to a list of items. */
export interface PropertyManagerConnection {
  __typename?: 'PropertyManagerConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PropertyManagerEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

export interface PropertyManagerContract {
  __typename?: 'PropertyManagerContract',
  createdAt: Scalars['Date'],
  endsAt?: Maybe<Scalars['Date']>,
  isSubManagementContract?: Maybe<Scalars['Boolean']>,
  startsAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The address unit associated with this property manager contract */
  addressUnit?: Maybe<AddressUnit>,
  /** The building associated with this property manager contract */
  building?: Maybe<Building>,
  /** The parent property manager contract associated with this property manager contract */
  parentPropertyManagerContract?: Maybe<PropertyManagerContract>,
  /** The landlord associated with this property manager contract */
  propertyManager?: Maybe<PropertyManager>,
}

/** An edge in a connection. */
export interface PropertyManagerEdge {
  __typename?: 'PropertyManagerEdge',
  /** The item at the end of the edge */
  node?: Maybe<PropertyManager>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface PropertyManagersFilterType {
  createdAt?: Maybe<DateInputType>,
  name?: Maybe<SearchStringInput>,
  smart?: Maybe<SearchStringInput>,
  updatedAt?: Maybe<DateInputType>,
}

export enum PropertyManagersSortByEnum {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export enum PropertyManagersSortDirectionEnum {
  /** Ascending */
  ASC = 'ASC',
  /** Descending */
  DESC = 'DESC'
}

export interface PublicLeasePeriod {
  __typename?: 'PublicLeasePeriod',
  leaseEndDate?: Maybe<Scalars['Date']>,
  leaseStartDate?: Maybe<Scalars['Date']>,
}

export interface PurchaseListingBoostInputType {
  listingUuid?: Maybe<Scalars['UUID']>,
  source?: Maybe<Scalars['String']>,
  amountInCents?: Maybe<Scalars['Int']>,
  isDailyBudgetAdset: Scalars['Boolean'],
  offerUuid?: Maybe<Scalars['UUID']>,
}

export interface PurchaseTakeoverInputType {
  paymentMethodId: Scalars['String'],
}

export interface Qualification {
  __typename?: 'Qualification',
  createdAt: Scalars['Date'],
  subtype?: Maybe<QualificationSubtypes>,
  thresholdModifier: QualificationThresholdModifiers,
  thresholdType?: Maybe<QualificationThresholdTypes>,
  thresholdUnits?: Maybe<QualificationThresholdUnits>,
  thresholdValue: Scalars['Int'],
  thresholdVariable?: Maybe<QualificationThresholdVariables>,
  type: QualificationTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The verification methods needed for this qualification */
  verificationMethods?: Maybe<Array<Maybe<VerificationMethod>>>,
}

/** A connection to a list of items. */
export interface QualificationConnection {
  __typename?: 'QualificationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QualificationEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

export interface QualificationContextArg {
  listingRentInCents?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface QualificationEdge {
  __typename?: 'QualificationEdge',
  /** The item at the end of the edge */
  node?: Maybe<Qualification>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface QualificationsFilterInput {
  smart?: Maybe<SearchStringInput>,
  type?: Maybe<StringInput>,
}

export enum QualificationSubtypes {
  ANNUAL = 'ANNUAL',
  EVICTION_RECORD = 'EVICTION_RECORD',
  FELONY_RECORD = 'FELONY_RECORD'
}

export enum QualificationThresholdModifiers {
  EQUAL_TO = 'EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN'
}

export enum QualificationThresholdTypes {
  BOOLEAN = 'BOOLEAN',
  MULTIPLIER = 'MULTIPLIER',
  NUMBER = 'NUMBER'
}

export enum QualificationThresholdUnits {
  JUDGEMENTS = 'JUDGEMENTS',
  POINTS = 'POINTS',
  SUMMONS = 'SUMMONS',
  USD = 'USD',
  USD_PER_MONTH = 'USD_PER_MONTH',
  USD_PER_YEAR = 'USD_PER_YEAR'
}

export enum QualificationThresholdVariables {
  MONTHLY_RENT_IN_USD = 'MONTHLY_RENT_IN_USD'
}

export enum QualificationTypes {
  ASSETS = 'ASSETS',
  CIVIL_HISTORY = 'CIVIL_HISTORY',
  CREDIT_SCORE = 'CREDIT_SCORE',
  CRIMINAL_HISTORY = 'CRIMINAL_HISTORY',
  INCOME = 'INCOME'
}

/** A user-to-user referral on Caretaker */
export interface Referral {
  __typename?: 'Referral',
  createdAt?: Maybe<Scalars['Date']>,
  shortUrl?: Maybe<Scalars['String']>,
  token?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The specific listing the referral link was generated for */
  listing?: Maybe<Listing>,
  referredUsers?: Maybe<AccountConnection>,
  /** The user who sent the referral */
  user?: Maybe<Account>,
}


/** A user-to-user referral on Caretaker */
export interface ReferralReferredUsersArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

/** A connection to a list of items. */
export interface ReferralConnection {
  __typename?: 'ReferralConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReferralEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface ReferralEdge {
  __typename?: 'ReferralEdge',
  /** The item at the end of the edge */
  node?: Maybe<Referral>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A Stripe refund */
export interface RefundType {
  __typename?: 'RefundType',
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  created?: Maybe<Scalars['Date']>,
  currency?: Maybe<Scalars['String']>,
  failureReason?: Maybe<Scalars['String']>,
  reason?: Maybe<Scalars['String']>,
  receiptNumber?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['Boolean']>,
}

export interface RegisterInputType {
  activationToken?: Maybe<Scalars['UUID']>,
  email: Scalars['String'],
  name: Scalars['String'],
  password: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  product?: Maybe<Products>,
  referralToken?: Maybe<Scalars['UUID']>,
}

export interface RemovePropertyManagerContractFromServicePreferenceInput {
  propertyManagerContractUuid: Scalars['UUID'],
}

export interface RenderSubleaseAgreementInputType {
  addendumText?: Maybe<Scalars['String']>,
  cancellationDeadlineString?: Maybe<Scalars['String']>,
  hasKeybox?: Maybe<Scalars['Boolean']>,
  hasSmartLock?: Maybe<Scalars['Boolean']>,
  hasPaymentMethodsRequirement?: Maybe<Scalars['Boolean']>,
  hasRentGuarentee?: Maybe<Scalars['Boolean']>,
  hasTOSAgreement?: Maybe<Scalars['Boolean']>,
  leaseEndString?: Maybe<Scalars['String']>,
  leaseStartString?: Maybe<Scalars['String']>,
  lister?: Maybe<Scalars['String']>,
  /** Rent amount in dollars */
  rentString?: Maybe<Scalars['String']>,
  securityDeposit?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
  sublessee?: Maybe<Scalars['String']>,
  sublessor?: Maybe<Scalars['String']>,
  utilitiesText?: Maybe<Scalars['String']>,
}

export interface RentalFee {
  __typename?: 'RentalFee',
  amount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  description?: Maybe<Scalars['String']>,
  frequency?: Maybe<RentalFeeFrequencies>,
  isDefault?: Maybe<Scalars['Boolean']>,
  maxAmount?: Maybe<Scalars['Int']>,
  minAmount?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  type?: Maybe<RentalFeeTypes>,
  units?: Maybe<RentalFeeUnits>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
}

export enum RentalFeeFrequencies {
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME'
}

export enum RentalFeeTypes {
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  AMENITY = 'AMENITY',
  APPLICATION = 'APPLICATION',
  ASSIGNMENT = 'ASSIGNMENT',
  BREAKAGE = 'BREAKAGE',
  PET = 'PET',
  SUBLET = 'SUBLET'
}

export enum RentalFeeUnits {
  CENTS = 'CENTS'
}

export interface RentalForm {
  __typename?: 'RentalForm',
  accessUrl?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  isCertifiedMail?: Maybe<Scalars['Boolean']>,
  isDefault?: Maybe<Scalars['Boolean']>,
  isGeneric?: Maybe<Scalars['Boolean']>,
  isThirdParty?: Maybe<Scalars['Boolean']>,
  medium?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  thirdPartyName?: Maybe<Scalars['String']>,
  type?: Maybe<RentalFormTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The fees for this form */
  rentalFees?: Maybe<Array<Maybe<RentalFee>>>,
  /** The upload this rental form is related to */
  upload?: Maybe<Upload>,
}

export enum RentalFormEntityTypes {
  RENTAL_POLICY = 'RENTAL_POLICY',
  REQUIREMENT = 'REQUIREMENT'
}

export enum RentalFormTypes {
  ADD_OCCUPANT = 'ADD_OCCUPANT',
  LEASE_ASSIGNMENT = 'LEASE_ASSIGNMENT',
  LEASE_BREAK = 'LEASE_BREAK',
  LEASE = 'LEASE',
  RELET = 'RELET',
  SUBLEASE = 'SUBLEASE'
}

export interface RentalPolicy {
  __typename?: 'RentalPolicy',
  archivedAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  isAllowed?: Maybe<Scalars['Boolean']>,
  isRecommended?: Maybe<Scalars['Boolean']>,
  type?: Maybe<RentalPolicyTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The fees for this policy */
  rentalFees?: Maybe<Array<Maybe<RentalFee>>>,
  /** The forms for this policy */
  rentalForms?: Maybe<Array<Maybe<RentalForm>>>,
  /** The rental requirements for this building */
  rentalRequirements?: Maybe<Array<Maybe<RentalRequirement>>>,
  /** The requirements for this building */
  requirements?: Maybe<Array<Maybe<Requirement>>>,
}


export interface RentalPolicyRequirementsArgs {
  qualificationContext?: Maybe<QualificationContextArg>
}

export enum RentalPolicyEntityTypes {
  ACCOUNT = 'ACCOUNT',
  BUILDING = 'BUILDING',
  ORGANIZATION = 'ORGANIZATION',
  PROPERTYMANAGERCONTRACT = 'PROPERTYMANAGERCONTRACT'
}

export enum RentalPolicyTypes {
  ADD_OCCUPANT = 'ADD_OCCUPANT',
  BASE = 'BASE',
  LEASE = 'LEASE',
  LEASE_ASSIGNMENT = 'LEASE_ASSIGNMENT',
  LEASE_BREAK = 'LEASE_BREAK',
  RELET = 'RELET',
  SUBLEASE = 'SUBLEASE'
}

export interface RentalRequirement {
  __typename?: 'RentalRequirement',
  createdAt: Scalars['Date'],
  isBase: Scalars['Boolean'],
  subtype?: Maybe<Scalars['String']>,
  thresholdModifier: Scalars['String'],
  thresholdType?: Maybe<Scalars['String']>,
  thresholdUnits?: Maybe<Scalars['String']>,
  thresholdValue: Scalars['Int'],
  thresholdVariable?: Maybe<Scalars['String']>,
  type: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The policy associated with this requirement */
  rentalPolicy?: Maybe<RentalPolicy>,
  /** The verification methods needed for this requirement */
  verificationMethods?: Maybe<Array<Maybe<VerificationMethod>>>,
}

/** A rent payment */
export interface RentPayment {
  __typename?: 'RentPayment',
  amountInCents?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  creditPlatformFeeInCents?: Maybe<Scalars['Int']>,
  debitPlatformFeeInCents?: Maybe<Scalars['Int']>,
  monthStarting?: Maybe<Scalars['Date']>,
  monthEnding?: Maybe<Scalars['Date']>,
  nextScheduledTransferAmountInCents?: Maybe<Scalars['Int']>,
  nextScheduledTransferAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  type?: Maybe<RentPaymentTypes>,
  creditTransaction?: Maybe<Transaction>,
  creditTransactions?: Maybe<Array<Maybe<Transaction>>>,
  debitTransaction?: Maybe<Transaction>,
  lease?: Maybe<PrivateLease>,
  payee?: Maybe<Account>,
  payer?: Maybe<Account>,
  transaction?: Maybe<Transaction>,
}

/** A connection to a list of items. */
export interface RentPaymentConnection {
  __typename?: 'RentPaymentConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RentPaymentEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface RentPaymentEdge {
  __typename?: 'RentPaymentEdge',
  /** The item at the end of the edge */
  node?: Maybe<RentPayment>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export enum RentPaymentPolicyTypeEnum {
  DELEGATERESPONSIBLE = 'DelegateResponsible',
  INDIVIDUALLYRESPONSIBLE = 'IndividuallyResponsible'
}

export interface RentPaymentRoleInput {
  eq?: Maybe<RentPaymentRoles>,
  in?: Maybe<Array<RentPaymentRoles>>,
}

/** Roles in the context of a rent payment. */
export enum RentPaymentRoles {
  PAYEE = 'PAYEE',
  PAYER = 'PAYER'
}

export interface RentPaymentsFilterInput {
  paidPeriodStartsAt?: Maybe<DateInputType>,
  role?: Maybe<RentPaymentRoleInput>,
  type?: Maybe<RentPaymentTypeInput>,
}

export interface RentPaymentTypeInput {
  eq?: Maybe<RentPaymentTypes>,
  in?: Maybe<Array<RentPaymentTypes>>,
}

/** Types of rent payments */
export enum RentPaymentTypes {
  HISTORIC = 'HISTORIC',
  ON_PLATFORM = 'ON_PLATFORM',
  STANDALONE = 'STANDALONE'
}

/** Calculated issue status of a condition report */
export enum ReportStatuses {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export interface Requirement {
  __typename?: 'Requirement',
  createdAt: Scalars['Date'],
  isBase: Scalars['Boolean'],
  viewerDidCreate?: Maybe<Scalars['Boolean']>,
  viewerMeetsRequirement?: Maybe<Scalars['Boolean']>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The qualification associated with this requirement */
  qualification: Qualification,
  /** The policy associated with this requirement */
  rentalPolicy: RentalPolicy,
  /** The verification methods needed for this requirement */
  verificationMethods?: Maybe<Array<Maybe<VerificationMethod>>>,
}

export enum RequirementEntityTypes {
  RENTAL_POLICY = 'RENTAL_POLICY'
}

export interface ResetPasswordInput {
  /** The email address of your account. */
  email: Scalars['String'],
}

export interface RetryChargeSecurityDepositInputType {
  /** The payment method id to charge */
  paymentMethodId: Scalars['String'],
}

export interface Review {
  __typename?: 'Review',
  createdAt?: Maybe<Scalars['Date']>,
  headline?: Maybe<Scalars['String']>,
  maxRating?: Maybe<Scalars['Float']>,
  rating?: Maybe<Scalars['Float']>,
  text?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  building?: Maybe<Building>,
  comments?: Maybe<CommentConnection>,
  listing?: Maybe<Listing>,
  reviewee?: Maybe<Account>,
  /** The user who wrote this review */
  reviewer?: Maybe<Account>,
  unit?: Maybe<AddressUnit>,
}


export interface ReviewCommentsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

/** A connection to a list of items. */
export interface ReviewConnection {
  __typename?: 'ReviewConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReviewEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface ReviewEdge {
  __typename?: 'ReviewEdge',
  /** The item at the end of the edge */
  node?: Maybe<Review>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export enum ReviewEntityTypes {
  ACCOUNT = 'ACCOUNT',
  BUILDING = 'BUILDING',
  LISTING = 'LISTING',
  UNIT = 'UNIT'
}

export interface RoommateAgreementInputType {
  alcoholPolicy?: Maybe<SubstancePolicyTypeEnum>,
  drugPolicy?: Maybe<SubstancePolicyTypeEnum>,
  marijuanaPolicy?: Maybe<SubstancePolicyTypeEnum>,
  maxHoursDirtyDishesCanSitInSink?: Maybe<Scalars['Int']>,
  numberOfGuestsThatDefinesParty?: Maybe<Scalars['Int']>,
  overnightGuestPolicy?: Maybe<OvernightGuestPolicyTypeEnum>,
  overnightGuestMaxNightsPerMonth?: Maybe<Scalars['Int']>,
  /** Hour from 0 to 23 */
  quietHoursStart?: Maybe<Scalars['Int']>,
  /** Hour from 0 to 23 */
  quietHoursEnd?: Maybe<Scalars['Int']>,
  rentPaymentDayOfMonth?: Maybe<Scalars['Int']>,
  rentPaymentPolicy?: Maybe<RentPaymentPolicyTypeEnum>,
  smokingPolicy?: Maybe<SubstancePolicyTypeEnum>,
  streetAddress?: Maybe<Scalars['String']>,
  totalRentAmountInCents?: Maybe<Scalars['Int']>,
  weeklyCleaningDay?: Maybe<CleaningDayTypeEnum>,
  roommates?: Maybe<Array<Maybe<RoommateInputType>>>,
  utilities?: Maybe<Array<Maybe<UtilityInputType>>>,
}

/** A roommate */
export interface RoommateInputType {
  isDelegateForRentPayment?: Maybe<Scalars['Boolean']>,
  name: Scalars['String'],
  rentAmountInCents?: Maybe<Scalars['Int']>,
}

/** The root of all... queries */
export interface RootQuery {
  __typename?: 'RootQuery',
  /** An address unit. */
  addressUnit?: Maybe<AddressUnit>,
  /** A list of possible amenities on a Caretaker unit. */
  amenities?: Maybe<Array<Maybe<Amenity>>>,
  /** An article on Caretaker. */
  article?: Maybe<Article>,
  /** The list of articles on Caretaker. */
  articles?: Maybe<ArticleConnection>,
  /** A building on Caretaker. */
  building?: Maybe<Building>,
  /** The list of buildings on Caretaker. */
  buildings?: Maybe<BuildingConnection>,
  /** A list of cities. */
  cities?: Maybe<Array<Maybe<City>>>,
  /** App wide constants for Caretaker. */
  constants: Constants,
  /** A content answer on Caretaker. */
  contentAnswer?: Maybe<ContentAnswer>,
  /** A list of content categories on Caretaker. */
  contentCategories?: Maybe<Array<Maybe<ContentCategory>>>,
  /** A content category on Caretaker. */
  contentCategory?: Maybe<ContentCategory>,
  /** A content guide on Caretaker. */
  contentGuide?: Maybe<ContentGuide>,
  /** A list of content guides on Caretaker. */
  contentGuides?: Maybe<ContentGuideConnection>,
  /** A content question on Caretaker. */
  contentQuestion?: Maybe<ContentQuestion>,
  /** The list of content questions on Caretaker. */
  contentQuestions?: Maybe<ContentQuestionConnection>,
  /** A content section */
  contentSection?: Maybe<ContentSection>,
  /** A list of content sections on Caretaker. */
  contentSections?: Maybe<Array<Maybe<ContentSection>>>,
  /** A document type. */
  documentType?: Maybe<DocumentType>,
  /** The list of document types on Caretaker. */
  documentTypes?: Maybe<DocumentTypeConnection>,
  ipGeolocation?: Maybe<IpGeolocation>,
  jobPostingDepartments?: Maybe<Array<Maybe<GoogleDepartment>>>,
  /** The list of landlords on Caretaker. */
  landlords?: Maybe<LandlordConnection>,
  /** An invitation to join an ongoing lease. */
  leaseInvite?: Maybe<LeaseInvite>,
  /** A public listing on Caretaker. */
  listing?: Maybe<Listing>,
  /** Query a Boost with just the UUID. Admins only */
  listingBoost?: Maybe<ListingBoost>,
  /** A searchable list of public listings on Caretaker. */
  listings?: Maybe<ListingConnection>,
  /** A list of possible tags on a media type (photo). */
  mediaTags?: Maybe<Array<Maybe<MediaTag>>>,
  /** A list of active offers by type. */
  offers?: Maybe<Array<Maybe<OfferType>>>,
  /** An organization. */
  organization?: Maybe<Organization>,
  /** The list of organization's person records on Caretaker. */
  organizationPersons?: Maybe<OrganizationPersonConnection>,
  /** The list of organizations on Caretaker. */
  organizations?: Maybe<OrganizationConnection>,
  /** All publicly available products */
  products?: Maybe<Array<Maybe<Product>>>,
  /** A public profile on Caretaker. */
  profile?: Maybe<Account>,
  /** A list of public accounts */
  profiles?: Maybe<AccountConnection>,
  /** A property manager on Caretaker. */
  propertyManager?: Maybe<PropertyManager>,
  /** The list of property managers on Caretaker. */
  propertyManagers?: Maybe<PropertyManagerConnection>,
  /** A qualification. */
  qualification?: Maybe<Qualification>,
  /** The list of qualifications */
  qualifications?: Maybe<QualificationConnection>,
  /** A searchable list of SEO indexable listings on Caretaker. */
  seoListings?: Maybe<Array<Maybe<Listing>>>,
  /** A service dispatch. */
  serviceDispatch?: Maybe<ServiceDispatch>,
  /** A service request. */
  serviceRequest?: Maybe<ServiceRequest>,
  /** A list of states. */
  states?: Maybe<Array<Maybe<State>>>,
  /** The list of utilities. */
  utilities?: Maybe<Array<Maybe<Utility>>>,
  /** The list of property managers on Caretaker. */
  utilityProviders?: Maybe<UtilityProviderConnection>,
  /** A verification method. */
  verificationMethod?: Maybe<VerificationMethod>,
  /** The list of verification methods on Caretaker. */
  verificationMethods?: Maybe<VerificationMethodConnection>,
  viewer?: Maybe<Viewer>,
}


/** The root of all... queries */
export interface RootQueryAddressUnitArgs {
  uuid: Scalars['UUID']
}


/** The root of all... queries */
export interface RootQueryAmenitiesArgs {
  filter?: Maybe<AmenitiesFilterType>
}


/** The root of all... queries */
export interface RootQueryArticleArgs {
  slugOrUuid?: Maybe<Scalars['String']>
}


/** The root of all... queries */
export interface RootQueryArticlesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ArticleFilterType>
}


/** The root of all... queries */
export interface RootQueryBuildingArgs {
  slugOrUuid?: Maybe<Scalars['String']>
}


/** The root of all... queries */
export interface RootQueryBuildingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<BuildingsFilterType>,
  sortBy?: Maybe<BuildingsSortByEnum>,
  sortDirection?: Maybe<BuildingsSortDirectionEnum>
}


/** The root of all... queries */
export interface RootQueryContentAnswerArgs {
  slugOrUuid?: Maybe<Scalars['String']>,
  uuid?: Maybe<Scalars['UUID']>
}


/** The root of all... queries */
export interface RootQueryContentCategoriesArgs {
  filter?: Maybe<ContentCategoryFilterInput>
}


/** The root of all... queries */
export interface RootQueryContentCategoryArgs {
  filter?: Maybe<ContentCategoryFilterInput>,
  slugOrUuid: Scalars['String']
}


/** The root of all... queries */
export interface RootQueryContentGuideArgs {
  tagOrUuid?: Maybe<Scalars['String']>
}


/** The root of all... queries */
export interface RootQueryContentQuestionArgs {
  slugOrUuid?: Maybe<Scalars['String']>
}


/** The root of all... queries */
export interface RootQueryContentQuestionsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ContentQuestionArgType>
}


/** The root of all... queries */
export interface RootQueryContentSectionArgs {
  slugOrUuid: Scalars['String']
}


/** The root of all... queries */
export interface RootQueryContentSectionsArgs {
  filter?: Maybe<ContentSectionFilterInput>
}


/** The root of all... queries */
export interface RootQueryDocumentTypeArgs {
  uuid: Scalars['UUID']
}


/** The root of all... queries */
export interface RootQueryDocumentTypesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<DocumentTypesFilterType>,
  sortBy?: Maybe<DocumentTypesSortByEnum>,
  sortDirection?: Maybe<DocumentTypesSortDirectionEnum>
}


/** The root of all... queries */
export interface RootQueryLandlordsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<LandlordsFilterType>,
  sortBy?: Maybe<LandlordsSortByEnum>,
  sortDirection?: Maybe<LandlordsSortDirectionEnum>
}


/** The root of all... queries */
export interface RootQueryLeaseInviteArgs {
  uuid: Scalars['UUID']
}


/** The root of all... queries */
export interface RootQueryListingArgs {
  slugOrUuid?: Maybe<Scalars['String']>,
  uuid?: Maybe<Scalars['UUID']>
}


/** The root of all... queries */
export interface RootQueryListingBoostArgs {
  uuid?: Maybe<Scalars['UUID']>
}


/** The root of all... queries */
export interface RootQueryListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchResultFilterType>,
  sortBy?: Maybe<ListingOrderByEnum>,
  sortDirection?: Maybe<ListingOrderDirectionEnum>
}


/** The root of all... queries */
export interface RootQueryOffersArgs {
  filter?: Maybe<OfferFilterType>
}


/** The root of all... queries */
export interface RootQueryOrganizationArgs {
  uuid?: Maybe<Scalars['UUID']>,
  slugOrUuid?: Maybe<Scalars['String']>
}


/** The root of all... queries */
export interface RootQueryOrganizationPersonsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<OrganizationPersonsFilter>
}


/** The root of all... queries */
export interface RootQueryOrganizationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<OrganizationsFilterType>,
  sortBy?: Maybe<OrganizationsSortByEnum>,
  sortDirection?: Maybe<OrganizationsSortDirectionEnum>
}


/** The root of all... queries */
export interface RootQueryProductsArgs {
  sku?: Maybe<ProductSkus>,
  tableName?: Maybe<TableNames>
}


/** The root of all... queries */
export interface RootQueryProfileArgs {
  uuid: Scalars['UUID']
}


/** The root of all... queries */
export interface RootQueryProfilesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The root of all... queries */
export interface RootQueryPropertyManagerArgs {
  uuid?: Maybe<Scalars['UUID']>
}


/** The root of all... queries */
export interface RootQueryPropertyManagersArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<PropertyManagersFilterType>,
  sortBy?: Maybe<PropertyManagersSortByEnum>,
  sortDirection?: Maybe<PropertyManagersSortDirectionEnum>
}


/** The root of all... queries */
export interface RootQueryQualificationArgs {
  uuid: Scalars['UUID']
}


/** The root of all... queries */
export interface RootQueryQualificationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<QualificationsFilterInput>
}


/** The root of all... queries */
export interface RootQueryServiceDispatchArgs {
  uuid: Scalars['UUID']
}


/** The root of all... queries */
export interface RootQueryServiceRequestArgs {
  uuid: Scalars['UUID']
}


/** The root of all... queries */
export interface RootQueryUtilityProvidersArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<UtilityProvidersFilterType>,
  sortBy?: Maybe<UtilityProvidersSortByEnum>,
  sortDirection?: Maybe<UtilityProvidersSortDirectionEnum>
}


/** The root of all... queries */
export interface RootQueryVerificationMethodArgs {
  slugOrUuid: Scalars['String']
}


/** The root of all... queries */
export interface RootQueryVerificationMethodsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<VerificationMethodsFilterType>,
  sortBy?: Maybe<VerificationMethodsSortByEnum>,
  sortDirection?: Maybe<VerificationMethodsSortDirectionEnum>
}


/** The root of all... queries */
export interface RootQueryViewerArgs {
  token?: Maybe<Scalars['String']>
}

/** Saved searches for listing alerts */
export interface SavedSearch {
  __typename?: 'SavedSearch',
  alertFrequency?: Maybe<SavedSearchAlertFrequencyTypes>,
  amenities?: Maybe<Array<Maybe<AmenitySlugs>>>,
  createdAt?: Maybe<Scalars['Date']>,
  earliestMoveInDate?: Maybe<Scalars['Date']>,
  furnishingTypes?: Maybe<Array<Maybe<ListingFurnishingTypes>>>,
  isLeaseExtendableWithLandlord?: Maybe<Scalars['Boolean']>,
  isSubscribed?: Maybe<Scalars['Boolean']>,
  lastNotified?: Maybe<Scalars['Date']>,
  listingType?: Maybe<ListingTypes>,
  maxLatitude?: Maybe<Scalars['Float']>,
  maxLeaseTerm?: Maybe<Scalars['Int']>,
  maxLongitude?: Maybe<Scalars['Float']>,
  maxRentInCents?: Maybe<Scalars['Int']>,
  minBathrooms?: Maybe<Scalars['Float']>,
  minBedrooms?: Maybe<Scalars['Int']>,
  minLatitude?: Maybe<Scalars['Float']>,
  minLeaseTerm?: Maybe<Scalars['Int']>,
  minLongitude?: Maybe<Scalars['Float']>,
  minRentInCents?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  propertyType?: Maybe<ListingPropertyTypes>,
  shouldSendDailyEmail?: Maybe<Scalars['Boolean']>,
  shouldSendPushNotifications?: Maybe<Scalars['Boolean']>,
  shouldSendSmsNotifications?: Maybe<Scalars['Boolean']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The user associated to this Saved Search */
  user?: Maybe<FlipProfile>,
}

export enum SavedSearchAlertFrequencyTypes {
  DAILY = 'DAILY',
  INSTANT = 'INSTANT'
}

/** A searchable type (listing, lease, etc) */
export type Searchable = PrivateLease | PrivatePropertyManagerContract;

/** A connection to a list of items. */
export interface SearchableConnection {
  __typename?: 'SearchableConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchableEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface SearchableEdge {
  __typename?: 'SearchableEdge',
  /** The item at the end of the edge */
  node?: Maybe<Searchable>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface SearchableFilterInput {
  smart?: Maybe<SearchStringInput>,
}

export interface SearchResultFilterType {
  /** Listing with these amenities (By slug) */
  amenities?: Maybe<Array<AmenitySlugs>>,
  availableViewingSlots?: Maybe<AvailableViewingSlotsInput>,
  /** Number of bathrooms */
  bathrooms?: Maybe<IntegerInput>,
  /** Number of bedrooms */
  bedrooms?: Maybe<IntegerInput>,
  /** City the listing is in */
  city?: Maybe<StringInput>,
  /** Deposit amount in cents */
  depositInCents?: Maybe<IntegerInput>,
  /** Earliest date available to move in */
  earliestMoveInDate?: Maybe<Scalars['Date']>,
  furnishingType?: Maybe<FurnishingInputType>,
  hasKeyboxAccess?: Maybe<Scalars['Boolean']>,
  hasSmartLockAccess?: Maybe<Scalars['Boolean']>,
  hasQualityPhotos?: Maybe<Scalars['Boolean']>,
  isLeaseExtendableWithLandlord?: Maybe<Scalars['Boolean']>,
  isManagedByFlip?: Maybe<Scalars['Boolean']>,
  listedAt?: Maybe<DateInputType>,
  /** Listing type */
  listingType?: Maybe<ListingTypes>,
  /** An ambiguous location description that will be geocoded to a bounds filter */
  locationString?: Maybe<Scalars['String']>,
  /** Maximum length of lease in months */
  maxLeaseTerm?: Maybe<IntegerInput>,
  /** Minimum length of lease in months */
  minLeaseTerm?: Maybe<IntegerInput>,
  /** Neighborhood the listing is in */
  neighborhood?: Maybe<StringInput>,
  /** Property type */
  propertyType?: Maybe<ListingPropertyTypes>,
  /** Rent amount in cents */
  rentInCents?: Maybe<IntegerInput>,
  /** The current status of the listing */
  status?: Maybe<StatusInput>,
  updatedAt?: Maybe<DateInputType>,
  /** A min/max set of lat/long coordinates to search within */
  withinCoords?: Maybe<GeoBoxInput>,
}

export interface SearchStringInput {
  eq?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
  startswith?: Maybe<Scalars['String']>,
  endswith?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  istartswith?: Maybe<Scalars['String']>,
  iendswith?: Maybe<Scalars['String']>,
  icontains?: Maybe<Scalars['String']>,
}

/** A security deposit. */
export interface SecurityDepositType {
  __typename?: 'SecurityDepositType',
  /** The timestamp when the security deposit was created. */
  createdAt?: Maybe<Scalars['Date']>,
  /** The amount remaining in escrow. */
  currentAmountInCents?: Maybe<Scalars['Int']>,
  /** The amount initially put into escrow */
  initialAmountInCents?: Maybe<Scalars['Int']>,
  /** The timestamp when the security deposit was last updated. */
  updatedAt?: Maybe<Scalars['Date']>,
  uuid?: Maybe<Scalars['UUID']>,
  /** The owning account of this security deposit. */
  account?: Maybe<Account>,
  /** A claim associated with this security deposit */
  claim?: Maybe<ClaimType>,
  /** A list of claims associated with this security deposit */
  claims?: Maybe<Array<Maybe<ClaimType>>>,
  /** The inital debit transaction. */
  debitTransaction?: Maybe<Transaction>,
  lease?: Maybe<PrivateLease>,
  /** The owner of this security deposit. */
  user?: Maybe<Account>,
  /** The inital debit transaction. */
  transaction?: Maybe<Transaction>,
}


/** A security deposit. */
export interface SecurityDepositTypeClaimArgs {
  uuid?: Maybe<Scalars['UUID']>
}

/** A connection to a list of items. */
export interface SecurityDepositTypeConnection {
  __typename?: 'SecurityDepositTypeConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SecurityDepositTypeEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface SecurityDepositTypeEdge {
  __typename?: 'SecurityDepositTypeEdge',
  /** The item at the end of the edge */
  node?: Maybe<SecurityDepositType>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface SendJobApplicationInputType {
  /** Tell us about yourself. Put whatever you want here. e.g. What are you interested in? What is your background? */
  description: Scalars['String'],
  /** Your email address */
  emailAddress: Scalars['String'],
  /** Your first name */
  firstName: Scalars['String'],
  /** Your username on GitHub */
  githubUsername?: Maybe<Scalars['String']>,
  /** Do you intend to work remotely? */
  isRemote: Scalars['Boolean'],
  /** Your last name */
  lastName: Scalars['String'],
  /** The URL of your LinkedIn profile */
  linkedInUrl?: Maybe<Scalars['String']>,
  /** The URL of your personal website */
  personalWebsiteUrl?: Maybe<Scalars['String']>,
}

export interface SendMessageInputType {
  attachments?: Maybe<Array<Maybe<MessageAttachmentInputType>>>,
  /** The "uuid" of the conversation the message is being sent to. */
  conversationUuid?: Maybe<Scalars['UUID']>,
  /** The "uuid" of the thread the message is being sent to. */
  threadUuid?: Maybe<Scalars['UUID']>,
  /** The message text. */
  text?: Maybe<Scalars['String']>,
}

export interface SendPartnerBuildingLeadInput {
  buildingAddressForPilot: Scalars['String'],
  buildingsUnderManagementCount: Scalars['String'],
  companyName: Scalars['String'],
  emailAddress?: Maybe<Scalars['String']>,
  estimatedMonthlyLeads: Scalars['String'],
}

export interface SendSubleaseAgreementEmailInputType {
  email: Scalars['String'],
  endDate?: Maybe<Scalars['Date']>,
  /** Rent amount in dollars */
  rentInDollars?: Maybe<Scalars['Int']>,
  startDate?: Maybe<Scalars['Date']>,
  streetAddress?: Maybe<Scalars['String']>,
  sublessee?: Maybe<Scalars['String']>,
  sublessor?: Maybe<Scalars['String']>,
}

/** A service dispatch */
export interface ServiceDispatch {
  __typename?: 'ServiceDispatch',
  acceptedAt?: Maybe<Scalars['Date']>,
  expiresAt: Scalars['Date'],
  rejectedAt?: Maybe<Scalars['Date']>,
  status: Scalars['String'],
  uuid: Scalars['UUID'],
  /** The associated service fulfillment */
  serviceFulfillment?: Maybe<ServiceFulfillment>,
  /** The request this service dispatch is meant to fulfill */
  serviceRequest?: Maybe<ServiceRequest>,
  /** The provider that is being dispatched to fulfill the service request */
  serviceProvider?: Maybe<ServiceProvider>,
}

/** A service fulfillment */
export interface ServiceFulfillment {
  __typename?: 'ServiceFulfillment',
  amountInCents?: Maybe<Scalars['Date']>,
  appointmentEndsAt: Scalars['Date'],
  appointmentStartsAt: Scalars['Date'],
  approvedAt?: Maybe<Scalars['Date']>,
  notes?: Maybe<Scalars['Date']>,
  serviceType: ServiceFulfillmentServiceTypes,
  submittedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The address unit to be serviced */
  addressUnit?: Maybe<AddressUnit>,
  calendarEvent?: Maybe<CalendarEvent>,
  /** Photos related to this service fulfillment */
  photos?: Maybe<Array<Maybe<Photo>>>,
  /** The dispatch associated with this fulfillment */
  serviceDispatch?: Maybe<ServiceDispatch>,
  /** The provider that is being fulfillmented to fulfill the service request */
  serviceProvider?: Maybe<ServiceProvider>,
}

export enum ServiceFulfillmentServiceTypes {
  ELECTRICAL = 'ELECTRICAL',
  GENERAL = 'GENERAL',
  OTHER = 'OTHER',
  PLUMBING = 'PLUMBING'
}

/** A service preference */
export interface ServicePreference {
  __typename?: 'ServicePreference',
  createdAt: Scalars['Date'],
  notes?: Maybe<Scalars['String']>,
  serviceType: ServiceTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The property manager contracts that make use of this service preference */
  propertyManagerContracts?: Maybe<Array<Maybe<PrivatePropertyManagerContract>>>,
  /** The preferred service provider */
  serviceProvider?: Maybe<ServiceProvider>,
}

/** A service provider */
export interface ServiceProvider {
  __typename?: 'ServiceProvider',
  serviceType: ServiceTypes,
  uuid: Scalars['UUID'],
  /** The organization details for the service provider */
  organization?: Maybe<Organization>,
}

/** A service request */
export interface ServiceRequest {
  __typename?: 'ServiceRequest',
  serviceType: ServiceRequestServiceTypes,
  status: ServiceRequestStatuses,
  uuid: Scalars['UUID'],
  windowEndsAt: Scalars['Date'],
  windowStartsAt: Scalars['Date'],
  /** The accepted service dispatch */
  acceptedServiceDispatch?: Maybe<ServiceDispatch>,
  /** The address unit to be serviced */
  addressUnit?: Maybe<AddressUnit>,
  /** A list of available time slots on this calendar. */
  availableTimeSlots?: Maybe<Array<Maybe<AvailableTimeSlot>>>,
}

export enum ServiceRequestServiceTypes {
  ELECTRICAL = 'ELECTRICAL',
  GENERAL = 'GENERAL',
  OTHER = 'OTHER',
  PLUMBING = 'PLUMBING'
}

export enum ServiceRequestStatuses {
  CREATED = 'CREATED',
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export enum ServiceTypes {
  ELECTRICAL = 'ELECTRICAL',
  GENERAL = 'GENERAL',
  OTHER = 'OTHER',
  PLUMBING = 'PLUMBING'
}

export interface SetDefaultPaymentMethodForLeaseInput {
  /** The Stripe payment method id */
  paymentMethodId?: Maybe<Scalars['String']>,
}

/** A Shiphero address */
export interface ShipheroAddress {
  __typename?: 'ShipheroAddress',
  address1?: Maybe<Scalars['String']>,
  address2?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  countryCode?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  province?: Maybe<Scalars['String']>,
  provinceCode?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
}

/** A Shiphero order line item */
export interface ShipheroLineItem {
  __typename?: 'ShipheroLineItem',
  barcode: Scalars['String'],
  createdAt: Scalars['String'],
  customBarcode?: Maybe<Scalars['String']>,
  eligibleForReturn?: Maybe<Scalars['Boolean']>,
  fulfillmentStatus: Scalars['String'],
  id: Scalars['String'],
  name: Scalars['String'],
  partnerLineItemId: Scalars['String'],
  price: Scalars['String'],
  productId?: Maybe<Scalars['String']>,
  quantity: Scalars['Int'],
  quantityShipped: Scalars['Int'],
  sku: Scalars['String'],
  updatedAt: Scalars['String'],
}

/** A Shiphero order */
export interface ShipheroOrder {
  __typename?: 'ShipheroOrder',
  billingAddress?: Maybe<ShipheroAddress>,
  createdAt: Scalars['String'],
  fulfillmentStatus: Scalars['String'],
  id: Scalars['String'],
  lineItems?: Maybe<Array<Maybe<ShipheroLineItem>>>,
  packingNote?: Maybe<Scalars['String']>,
  partnerOrderId?: Maybe<Scalars['String']>,
  profile: Scalars['String'],
  requiredShipDate: Scalars['String'],
  requireSignature: Scalars['Int'],
  shippingAddress?: Maybe<ShipheroAddress>,
  shippingLines?: Maybe<Array<Maybe<ShipheroShippingLine>>>,
  subtotalPrice: Scalars['String'],
  totalDiscounts: Scalars['String'],
  totalTax: Scalars['String'],
}

/** A Shiphero order shipping line */
export interface ShipheroShippingLine {
  __typename?: 'ShipheroShippingLine',
  carrier: Scalars['String'],
  method: Scalars['String'],
  title: Scalars['String'],
}

/** An order shipment */
export interface Shipment {
  __typename?: 'Shipment',
  createdAt: Scalars['Date'],
  deliveredAt?: Maybe<Scalars['Date']>,
  shippedAt?: Maybe<Scalars['Date']>,
  status: ShipmentStatuses,
  type: ShipmentTypes,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The order this shipment belongs to */
  order?: Maybe<Order>,
}

/** Processors of shipments */
export enum ShipmentProcessors {
  INTERNAL = 'INTERNAL',
  SHIPHERO = 'SHIPHERO',
  SHIPPO = 'SHIPPO'
}

/** Statuses of shipments */
export enum ShipmentStatuses {
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  IN_TRANSIT = 'IN_TRANSIT',
  PREPARING = 'PREPARING',
  REFUNDED = 'REFUNDED',
  RETURNED = 'RETURNED',
  UNKNOWN = 'UNKNOWN',
  VALID = 'VALID',
  VOID = 'VOID'
}

/** Types of shipments */
export enum ShipmentTypes {
  RETURN = 'RETURN',
  SHIPMENT = 'SHIPMENT'
}

export interface SignUploadRequestInputType {
  filename: Scalars['String'],
}

export interface SmartLock {
  __typename?: 'SmartLock',
  createdAt?: Maybe<Scalars['Date']>,
  isSetup: Scalars['Boolean'],
  nanoid?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  /** The product related to this smart lock */
  product?: Maybe<Product>,
}

/** A spending threshold */
export interface SpendingThreshold {
  __typename?: 'SpendingThreshold',
  annualThresholdAmount?: Maybe<Scalars['Int']>,
  annualThresholdType?: Maybe<SpendingThresholdTypes>,
  createdAt: Scalars['Date'],
  issueThresholdAmount?: Maybe<Scalars['Int']>,
  issueThresholdType?: Maybe<SpendingThresholdTypes>,
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The maintenance setting for this spending threshold */
  maintenanceSetting?: Maybe<MaintenanceSetting>,
}

export enum SpendingThresholdTypes {
  CENTS = 'CENTS',
  PERCENTAGE_OF_ANNUAL_RENT = 'PERCENTAGE_OF_ANNUAL_RENT'
}

export interface State {
  __typename?: 'State',
  abbreviation?: Maybe<Scalars['String']>,
  averageRentInCents?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  googlePlaceId?: Maybe<Scalars['String']>,
  latitude?: Maybe<Scalars['Float']>,
  legalClimate?: Maybe<Scalars['Int']>,
  longitude?: Maybe<Scalars['Float']>,
  population?: Maybe<Scalars['Int']>,
  name?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  tip?: Maybe<Scalars['String']>,
  uuid: Scalars['UUID'],
}

export interface StatusInput {
  eq?: Maybe<ListingStatuses>,
  in?: Maybe<Array<ListingStatuses>>,
}

export interface StringInput {
  eq?: Maybe<Scalars['String']>,
  in?: Maybe<Array<Scalars['String']>>,
}

export enum StripeAccountHolderTypes {
  CUSTOMER = 'CUSTOMER',
  ACCOUNT = 'ACCOUNT'
}

export enum StripeAccountLinkTypes {
  ONBOARDING = 'ONBOARDING',
  UPDATE = 'UPDATE'
}

/** A Stripe address */
export interface StripeAddress {
  __typename?: 'StripeAddress',
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  line1?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  state?: Maybe<Scalars['String']>,
}

/** A Stripe account balance */
export interface StripeBalance {
  __typename?: 'StripeBalance',
  available?: Maybe<Array<Maybe<StripeBalanceCurrency>>>,
  pending?: Maybe<Array<Maybe<StripeBalanceCurrency>>>,
}

export interface StripeBalanceCurrency {
  __typename?: 'StripeBalanceCurrency',
  amount?: Maybe<Scalars['Int']>,
  currency?: Maybe<Scalars['String']>,
  sourceTypes?: Maybe<StripeBalanceSourceTypes>,
}

export interface StripeBalanceSourceTypes {
  __typename?: 'StripeBalanceSourceTypes',
  bankAccount?: Maybe<Scalars['Int']>,
  card?: Maybe<Scalars['Int']>,
  fpx?: Maybe<Scalars['Int']>,
}

/** A Stripe bank account */
export interface StripeBankAccount {
  __typename?: 'StripeBankAccount',
  id?: Maybe<Scalars['String']>,
  object?: Maybe<Scalars['String']>,
  account?: Maybe<Scalars['String']>,
  accountName?: Maybe<Scalars['String']>,
  accountHolderName?: Maybe<Scalars['String']>,
  accountHolderType?: Maybe<Scalars['String']>,
  bankName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  defaultForCurrency?: Maybe<Scalars['Boolean']>,
  last4?: Maybe<Scalars['String']>,
  routingNumber?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
}

/** A stored card */
export interface StripeCard {
  __typename?: 'StripeCard',
  id?: Maybe<Scalars['String']>,
  addressCity?: Maybe<Scalars['String']>,
  addressCountry?: Maybe<Scalars['String']>,
  addressLine1?: Maybe<Scalars['String']>,
  addressLine1Check?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressState?: Maybe<Scalars['String']>,
  addressZip?: Maybe<Scalars['String']>,
  addressZipCheck?: Maybe<Scalars['String']>,
  brand?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  customer?: Maybe<Scalars['String']>,
  checks?: Maybe<StripeCardChecks>,
  cvcCheck?: Maybe<Scalars['String']>,
  dynamicLast4?: Maybe<Scalars['String']>,
  expMonth?: Maybe<Scalars['Int']>,
  expYear?: Maybe<Scalars['Int']>,
  fingerprint?: Maybe<Scalars['String']>,
  funding?: Maybe<Scalars['String']>,
  last4?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
}

/** Checks on a Stripe card */
export interface StripeCardChecks {
  __typename?: 'StripeCardChecks',
  addressLine1Check?: Maybe<Scalars['String']>,
  addressPostalCodeCheck?: Maybe<Scalars['String']>,
  cvcCheck?: Maybe<Scalars['String']>,
}

export interface StripeCompany {
  __typename?: 'StripeCompany',
  address?: Maybe<StripeAddress>,
  directorsProvided?: Maybe<Scalars['Boolean']>,
  executivesProvided?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  ownersProvided?: Maybe<Scalars['Boolean']>,
  phone?: Maybe<Scalars['String']>,
  taxIdProvided?: Maybe<Scalars['Boolean']>,
  vatIdProvided?: Maybe<Scalars['Boolean']>,
  verification?: Maybe<StripeCompanyVerification>,
}

export interface StripeCompanyVerification {
  __typename?: 'StripeCompanyVerification',
  document?: Maybe<StripeVerificationDocument>,
}

/** A Stripe connect account */
export interface StripeConnectAccount {
  __typename?: 'StripeConnectAccount',
  businessType?: Maybe<StripeConnectAccountBusinessTypes>,
  capabilities?: Maybe<StripeConnectAccountCapabilities>,
  chargesEnabled?: Maybe<Scalars['Boolean']>,
  company?: Maybe<StripeCompany>,
  detailsSubmitted?: Maybe<Scalars['Boolean']>,
  displayName?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  externalAccounts?: Maybe<Array<Maybe<StripeExternalAccount>>>,
  id?: Maybe<Scalars['String']>,
  individual?: Maybe<StripeIndividual>,
  futureRequirements?: Maybe<StripeConnectAccountRequirements>,
  payoutsEnabled?: Maybe<Scalars['Boolean']>,
  requirements?: Maybe<StripeConnectAccountRequirements>,
  tosAcceptance?: Maybe<StripeConnectAccountTosAcceptance>,
}

export enum StripeConnectAccountBusinessTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY'
}

export interface StripeConnectAccountCapabilities {
  __typename?: 'StripeConnectAccountCapabilities',
  cardIssuing?: Maybe<StripeConnectAccountCapabilityStates>,
  cardPayments?: Maybe<StripeConnectAccountCapabilityStates>,
  taxReportingUs1099K?: Maybe<StripeConnectAccountCapabilityStates>,
  taxReportingUs1099Misc?: Maybe<StripeConnectAccountCapabilityStates>,
  transfers?: Maybe<StripeConnectAccountCapabilityStates>,
}

export enum StripeConnectAccountCapabilityStates {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING'
}

export interface StripeConnectAccountRequirements {
  __typename?: 'StripeConnectAccountRequirements',
  currentDeadline?: Maybe<Scalars['Date']>,
  currentlyDue?: Maybe<Array<Maybe<Scalars['String']>>>,
  disabledReason?: Maybe<Scalars['String']>,
  errors?: Maybe<Array<Maybe<Scalars['String']>>>,
  eventuallyDue?: Maybe<Array<Maybe<Scalars['String']>>>,
  pastDue?: Maybe<Array<Maybe<Scalars['String']>>>,
  pendingVerification?: Maybe<Array<Maybe<Scalars['String']>>>,
}

export interface StripeConnectAccountTosAcceptance {
  __typename?: 'StripeConnectAccountTosAcceptance',
  date?: Maybe<Scalars['Date']>,
  ip?: Maybe<Scalars['String']>,
  userAgent?: Maybe<Scalars['String']>,
}

/** A Stripe customer */
export interface StripeCustomer {
  __typename?: 'StripeCustomer',
  balance?: Maybe<Scalars['Int']>,
  defaultPaymentMethod?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
}

export interface StripeDateOfBirth {
  __typename?: 'StripeDateOfBirth',
  day?: Maybe<Scalars['Int']>,
  month?: Maybe<Scalars['Int']>,
  year?: Maybe<Scalars['Int']>,
}

/** A Stripe external account */
export interface StripeExternalAccount {
  __typename?: 'StripeExternalAccount',
  accountHolderName?: Maybe<Scalars['String']>,
  accountHolderType?: Maybe<Scalars['String']>,
  bankName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  defaultForCurrency?: Maybe<Scalars['Boolean']>,
  id?: Maybe<Scalars['String']>,
  last4?: Maybe<Scalars['String']>,
  routingNumber?: Maybe<Scalars['String']>,
  type?: Maybe<StripeExternalAccountTypes>,
  bankAccount?: Maybe<StripeBankAccount>,
  card?: Maybe<StripeCard>,
}

export enum StripeExternalAccountTypes {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CARD = 'CARD'
}

export interface StripeIndividual {
  __typename?: 'StripeIndividual',
  address?: Maybe<StripeAddress>,
  dob?: Maybe<StripeDateOfBirth>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  ssnLast4Provided?: Maybe<Scalars['Boolean']>,
  verification?: Maybe<StripeIndividualVerification>,
}

export interface StripeIndividualVerification {
  __typename?: 'StripeIndividualVerification',
  additionalDocument?: Maybe<StripeVerificationDocument>,
  details?: Maybe<Scalars['String']>,
  detailsCode?: Maybe<Scalars['String']>,
  document?: Maybe<StripeVerificationDocument>,
  status?: Maybe<Scalars['String']>,
}

/** A Stripe invoice */
export interface StripeInvoice {
  __typename?: 'StripeInvoice',
  amount: Scalars['Int'],
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  lines?: Maybe<Array<Maybe<StripeInvoiceLineItem>>>,
  periodEnd: Scalars['Date'],
  periodStart: Scalars['Date'],
  price: StripePrice,
  proration: Scalars['Boolean'],
  status: Scalars['String'],
  subscription: StripeSubscription,
  subtotal: Scalars['Int'],
  total: Scalars['Int'],
  quantity: Scalars['Int'],
}

/** A Stripe invoice line item */
export interface StripeInvoiceLineItem {
  __typename?: 'StripeInvoiceLineItem',
  amount: Scalars['Int'],
  description?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  period: StripePeriod,
  price: StripePrice,
  proration: Scalars['Boolean'],
  quantity: Scalars['Int'],
}

/** A stored issuing card */
export interface StripeIssuingCard {
  __typename?: 'StripeIssuingCard',
  id?: Maybe<Scalars['String']>,
  brand?: Maybe<Scalars['String']>,
  created?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  cvc?: Maybe<Scalars['String']>,
  expMonth?: Maybe<Scalars['String']>,
  expYear?: Maybe<Scalars['String']>,
  last4?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  number?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  type?: Maybe<Scalars['String']>,
}

/** A stored payment method */
export interface StripePaymentMethod {
  __typename?: 'StripePaymentMethod',
  createdAt?: Maybe<Scalars['Date']>,
  id?: Maybe<Scalars['String']>,
  isDefault?: Maybe<Scalars['Boolean']>,
  type?: Maybe<StripePaymentMethodTypes>,
  usage?: Maybe<Scalars['String']>,
  usBankAccount?: Maybe<StripeUsBankAccount>,
  card?: Maybe<StripeCard>,
}

/** Types of payment methods */
export enum StripePaymentMethodTypes {
  US_BANK_ACCOUNT = 'US_BANK_ACCOUNT',
  CARD = 'CARD'
}

/** A Stripe period */
export interface StripePeriod {
  __typename?: 'StripePeriod',
  end: Scalars['Date'],
  start: Scalars['Date'],
}

/** A Stripe plan */
export interface StripePlan {
  __typename?: 'StripePlan',
  active: Scalars['Boolean'],
  amount?: Maybe<Scalars['Int']>,
  billingScheme?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  interval?: Maybe<Scalars['String']>,
  product: StripeProduct,
  usageType: Scalars['String'],
}

/** A Stripe price */
export interface StripePrice {
  __typename?: 'StripePrice',
  active: Scalars['Boolean'],
  billingScheme?: Maybe<Scalars['String']>,
  id: Scalars['String'],
  nickname?: Maybe<Scalars['String']>,
  product: StripeProduct,
  type: Scalars['String'],
  unitAmount?: Maybe<Scalars['Int']>,
}

/** A Stripe product */
export interface StripeProduct {
  __typename?: 'StripeProduct',
  active: Scalars['Boolean'],
  description?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  id: Scalars['String'],
  unitLabel?: Maybe<Scalars['String']>,
}

/** A stripe subscription */
export interface StripeSubscription {
  __typename?: 'StripeSubscription',
  cancelAt?: Maybe<Scalars['Date']>,
  canceledAt?: Maybe<Scalars['Date']>,
  currentPeriodEnd?: Maybe<Scalars['Date']>,
  currentPeriodStart?: Maybe<Scalars['Date']>,
  daysUntilDue?: Maybe<Scalars['Int']>,
  defaultPaymentMethod?: Maybe<StripePaymentMethod>,
  id: Scalars['String'],
  items?: Maybe<Array<Maybe<StripeSubscriptionItem>>>,
  plan: StripePlan,
  status: Scalars['String'],
}

/** A Stripe subscription item */
export interface StripeSubscriptionItem {
  __typename?: 'StripeSubscriptionItem',
  id: Scalars['String'],
  price: StripePrice,
  quantity: Scalars['Int'],
}

/** A Stripe US bank account */
export interface StripeUsBankAccount {
  __typename?: 'StripeUsBankAccount',
  accountType?: Maybe<Scalars['String']>,
  accountHolderType?: Maybe<Scalars['String']>,
  bankName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  defaultForCurrency?: Maybe<Scalars['Boolean']>,
  last4?: Maybe<Scalars['String']>,
  routingNumber?: Maybe<Scalars['String']>,
  statusDetails?: Maybe<StripeUsBankAccountStatusDetails>,
}

/** Details about the block on a Stripe US bank account */
export interface StripeUsBankAccountBlockDetails {
  __typename?: 'StripeUsBankAccountBlockDetails',
  networkCode?: Maybe<Scalars['String']>,
  reason?: Maybe<Scalars['String']>,
}

/** Status details about a Stripe US bank account */
export interface StripeUsBankAccountStatusDetails {
  __typename?: 'StripeUsBankAccountStatusDetails',
  blocked?: Maybe<StripeUsBankAccountBlockDetails>,
}

export interface StripeVerificationDocument {
  __typename?: 'StripeVerificationDocument',
  back?: Maybe<Scalars['String']>,
  front?: Maybe<Scalars['String']>,
  details?: Maybe<Scalars['String']>,
  detailsCode?: Maybe<Scalars['String']>,
}

export interface SubleaseContextInput {
  /** The addendum to add to the end of the lease */
  addendumText?: Maybe<Scalars['String']>,
  /** The deadline that the sublessor may cancel the contract by */
  cancellationDeadlineAt?: Maybe<Scalars['String']>,
  leaseStart?: Maybe<Scalars['Date']>,
  /** The start date of the lease */
  leaseStartsAt?: Maybe<Scalars['Date']>,
  leaseEnd?: Maybe<Scalars['Date']>,
  /** The end date of the lease */
  leaseEndsAt?: Maybe<Scalars['Date']>,
  /** The rent amount in cents */
  rentInCents?: Maybe<Scalars['Int']>,
  /** The security deposit amount in cents */
  securityDepositInCents?: Maybe<Scalars['Int']>,
  /** The sublessee's name */
  sublessee?: Maybe<Scalars['String']>,
  /** The sublessor's name */
  sublessor?: Maybe<Scalars['String']>,
  /** The text to describe utilities payments */
  utilitiesText?: Maybe<Scalars['String']>,
}

export interface SubmitServiceFulfillmentForApprovalInput {
  amountInCents: Scalars['Int'],
  notes?: Maybe<Scalars['String']>,
}

export interface Subscriptions {
  __typename?: 'Subscriptions',
  onDocumentUpdated?: Maybe<PrivateDocument>,
  onNewMessage?: Maybe<Message>,
  onNewThread?: Maybe<Thread>,
  onUserUpdated?: Maybe<Viewer>,
}


export interface SubscriptionsOnDocumentUpdatedArgs {
  uuid: Scalars['UUID']
}

export enum SubstancePolicyTypeEnum {
  ALLOWEDANYWHERE = 'AllowedAnywhere',
  ALLOWEDINROOMONLY = 'AllowedInRoomOnly',
  NOTALLOWED = 'NotAllowed'
}

/** Entity types of products */
export enum TableNames {
  SMART_LOCKS = 'SMART_LOCKS'
}

/** A listing takeover */
export interface Takeover {
  __typename?: 'Takeover',
  agreementExecutedAt?: Maybe<Scalars['Date']>,
  amountInCents?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  eligibilityConfirmationRequestedAt?: Maybe<Scalars['Date']>,
  eligibilityConfirmedAt?: Maybe<Scalars['Date']>,
  expiresAt?: Maybe<Scalars['Date']>,
  leaseContactInfoContext?: Maybe<TakeoverLeaseContactInfoContextType>,
  logisticsContext?: Maybe<TakeoverLogisticsContextType>,
  moveOutDate?: Maybe<Scalars['Date']>,
  operationsContext?: Maybe<TakeoverOperationsContextType>,
  purchasedAt?: Maybe<Scalars['Date']>,
  startDate?: Maybe<Scalars['Date']>,
  startsAt?: Maybe<Scalars['Date']>,
  status?: Maybe<TakeoverStatuses>,
  tenantMovesOutAt?: Maybe<Scalars['Date']>,
  unitContext?: Maybe<TakeoverUnitContextType>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  viewingContext?: Maybe<TakeoverViewingContextType>,
  account?: Maybe<PrivateAccount>,
  addressUnit?: Maybe<ManagedAddressUnit>,
  agreement?: Maybe<PrivateDocument>,
  assignee?: Maybe<PrivateAccount>,
  debitTransaction?: Maybe<Transaction>,
  debitTransactions?: Maybe<Array<Maybe<Transaction>>>,
  lease?: Maybe<PrivateLease>,
  listing?: Maybe<PrivateListing>,
  orders?: Maybe<Array<Maybe<PrivateOrder>>>,
  paymentSchedule?: Maybe<PaymentSchedule>,
  takeoverProposal?: Maybe<TakeoverProposal>,
  takeoverRequest?: Maybe<TakeoverRequest>,
  thread?: Maybe<Thread>,
}

/** A connection to a list of items. */
export interface TakeoverConnection {
  __typename?: 'TakeoverConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TakeoverEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface TakeoverEdge {
  __typename?: 'TakeoverEdge',
  /** The item at the end of the edge */
  node?: Maybe<Takeover>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** Takeover lease contact info context */
export interface TakeoverLeaseContactInfoContextInputType {
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
}

/** Takeover lease contact info context */
export interface TakeoverLeaseContactInfoContextType {
  __typename?: 'TakeoverLeaseContactInfoContextType',
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  notes?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
}

export interface TakeoverLogisticsContextInputType {
  electricityInDollars?: Maybe<Scalars['Int']>,
  internetInDollars?: Maybe<Scalars['Int']>,
  naturalGasInDollars?: Maybe<Scalars['Int']>,
  waterInDollars?: Maybe<Scalars['Int']>,
}

/** Takeover logistics context */
export interface TakeoverLogisticsContextType {
  __typename?: 'TakeoverLogisticsContextType',
  electricityInDollars?: Maybe<Scalars['Int']>,
  internetInDollars?: Maybe<Scalars['Int']>,
  naturalGasInDollars?: Maybe<Scalars['Int']>,
  waterInDollars?: Maybe<Scalars['Int']>,
}

/** Takeover internal operations context */
export interface TakeoverOperationsContextType {
  __typename?: 'TakeoverOperationsContextType',
  didReviewAndUploadPhotosFromCleaners?: Maybe<Scalars['Boolean']>,
  didScheduleInitialCleaning?: Maybe<Scalars['Boolean']>,
  isBuildingPortalAccountForFlipCreated?: Maybe<Scalars['Boolean']>,
  isInitial30DayApplicationRequestSent?: Maybe<Scalars['Boolean']>,
}

export enum TakeoverPayRentMethods {
  CHECK = 'CHECK',
  ONLINE = 'ONLINE'
}

/** A takeover proposal */
export interface TakeoverProposal {
  __typename?: 'TakeoverProposal',
  acceptedAt?: Maybe<Scalars['Date']>,
  amountInCents?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  expiresAt?: Maybe<Scalars['Date']>,
  /** Minimum days until takeover starts from the agreement execution date */
  minDaysUntilTakeover?: Maybe<Scalars['Int']>,
  minDaysUntilTakeoverFromAgreementExecution?: Maybe<Scalars['Int']>,
  rejectedAt?: Maybe<Scalars['Date']>,
  sentAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  author?: Maybe<Account>,
  takeover?: Maybe<Takeover>,
  takeoverRequest?: Maybe<TakeoverRequest>,
}

/** A takeover request */
export interface TakeoverRequest {
  __typename?: 'TakeoverRequest',
  availabilityType?: Maybe<TakeoverRequestAvailabilityTypes>,
  bedroomCount?: Maybe<Scalars['Int']>,
  cancelledAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  furnishingType?: Maybe<ListingFurnishingTypes>,
  leaseEndDate?: Maybe<Scalars['Date']>,
  note?: Maybe<Scalars['String']>,
  rejectedAt?: Maybe<Scalars['Date']>,
  rejectionNote?: Maybe<Scalars['String']>,
  rejectionReason?: Maybe<TakeoverRequestRejectionReasons>,
  rentInCents?: Maybe<Scalars['Int']>,
  status?: Maybe<TakeoverRequestStatuses>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  addressUnit?: Maybe<AddressUnit>,
  listing?: Maybe<PrivateListing>,
  takeover?: Maybe<Takeover>,
  takeoverProposals?: Maybe<Array<Maybe<TakeoverProposal>>>,
  user?: Maybe<Viewer>,
}

/** The availability types. */
export enum TakeoverRequestAvailabilityTypes {
  /** The availability is an entire place. */
  ENTIRE_PLACE = 'ENTIRE_PLACE',
  /** The availability is a private room. */
  PRIVATE_ROOM = 'PRIVATE_ROOM'
}

export enum TakeoverRequestRejectionReasons {
  /** The lease cannot be extended with the landlord after it ends. */
  CANT_EXTEND_WITH_LANDLORD = 'CANT_EXTEND_WITH_LANDLORD',
  NO_NEW_REQUESTS = 'NO_NEW_REQUESTS',
  /** We can't take over the lease for an uncategorized reason. */
  OTHER = 'OTHER',
  OUTSIDE_INSTANT_MARKET = 'OUTSIDE_INSTANT_MARKET',
  OVER_MAX_ACTIVE_UNITS_IN_BUILDING = 'OVER_MAX_ACTIVE_UNITS_IN_BUILDING',
  /** We aren't accepting leases with this many bedrooms. */
  OVER_MAX_BEDROOM_COUNT = 'OVER_MAX_BEDROOM_COUNT',
  /** We aren't accepting leases this far from city center. */
  OVER_MAX_DISTANCE_FROM_CITY_CENTER = 'OVER_MAX_DISTANCE_FROM_CITY_CENTER',
  /** We aren't accepting leases this far from public transport options. */
  OVER_MAX_DISTANCE_FROM_PUBLIC_TRANSPORT = 'OVER_MAX_DISTANCE_FROM_PUBLIC_TRANSPORT',
  /** We aren't accepting leases this long. */
  OVER_MAX_LEASE_LENGTH = 'OVER_MAX_LEASE_LENGTH',
  OVER_MAX_RENT = 'OVER_MAX_RENT',
  /** This landlord prohibits us operating in their building. */
  PROHIBITED_BY_LANDLORD = 'PROHIBITED_BY_LANDLORD',
  /** We aren't accepting leases this short. */
  UNDER_MIN_LEASE_LENGTH = 'UNDER_MIN_LEASE_LENGTH',
  UNDER_MIN_RENT = 'UNDER_MIN_RENT',
  UNIT_NOT_ENTIRE_PLACE = 'UNIT_NOT_ENTIRE_PLACE'
}

export enum TakeoverRequestStatuses {
  /** The takeover request is accepted */
  ACCEPTED = 'ACCEPTED',
  /** The request is pending action by the requesting user */
  AWAITING_REPLY = 'AWAITING_REPLY',
  /** The requesting user cancelled the takeover request */
  CANCELLED = 'CANCELLED',
  /** The takeover request is being evaluated by the Caretaker team */
  EVALUATING = 'EVALUATING',
  EXPIRED = 'EXPIRED',
  /** The takeover request is rejected */
  REJECTED = 'REJECTED',
  /** The takeover request has been initiated but has not been processed */
  REQUESTED = 'REQUESTED'
}

export interface TakeoversFilterInput {
  smart?: Maybe<SearchStringInput>,
  status?: Maybe<TakeoverStatusInput>,
}

export enum TakeoverStatuses {
  ACTIVE = 'ACTIVE',
  CANCELLED_BY_FLIP = 'CANCELLED_BY_FLIP',
  CANCELLED_BY_USER = 'CANCELLED_BY_USER',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  REJECTED_BY_BUILDING = 'REJECTED_BY_BUILDING',
  VOIDED_BY_USER = 'VOIDED_BY_USER'
}

export interface TakeoverStatusInput {
  eq?: Maybe<TakeoverStatuses>,
  in?: Maybe<Array<TakeoverStatuses>>,
}

export interface TakeoverUnitContextInputType {
  payRentMethod?: Maybe<TakeoverPayRentMethods>,
  unitConditionNotes?: Maybe<Scalars['String']>,
}

/** Takeover unit context */
export interface TakeoverUnitContextType {
  __typename?: 'TakeoverUnitContextType',
  furnishingType?: Maybe<ListingFurnishingTypes>,
  payRentMethod?: Maybe<TakeoverPayRentMethods>,
  unitConditionNotes?: Maybe<Scalars['String']>,
}

export interface TakeoverViewingContextInputType {
  accessInstructions?: Maybe<Scalars['String']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  petDetails?: Maybe<Scalars['String']>,
  weekdayViewingWindow?: Maybe<TakeoverViewingContextViewingWindows>,
  weekendViewingWindow?: Maybe<TakeoverViewingContextViewingWindows>,
}

/** Takeover viewing context */
export interface TakeoverViewingContextType {
  __typename?: 'TakeoverViewingContextType',
  accessInstructions?: Maybe<Scalars['String']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  petDetails?: Maybe<Scalars['String']>,
  weekdayViewingWindow?: Maybe<TakeoverViewingContextViewingWindows>,
  weekendViewingWindow?: Maybe<TakeoverViewingContextViewingWindows>,
}

export enum TakeoverViewingContextViewingWindows {
  /** From 10am to 5pm. */
  DAY = 'DAY',
  /** From 5pm to 10pm. */
  EVENING = 'EVENING'
}

/** A thread */
export interface Thread {
  __typename?: 'Thread',
  /** The related users conversation data */
  conversationUsers?: Maybe<Array<Maybe<ConversationUser>>>,
  createdAt?: Maybe<Scalars['Date']>,
  isViewerAuthorized?: Maybe<Scalars['Boolean']>,
  supportRequestedAt?: Maybe<Scalars['Date']>,
  token?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  viewerProxyPhoneNumber?: Maybe<Scalars['String']>,
  accounts?: Maybe<Array<Maybe<Account>>>,
  application?: Maybe<PrivateApplication>,
  flip?: Maybe<Flip>,
  /** The listing associated with this thread */
  listing?: Maybe<Listing>,
  messages?: Maybe<MessageConnection>,
  takeover?: Maybe<Takeover>,
  users?: Maybe<Array<Maybe<Account>>>,
}


/** A thread */
export interface ThreadMessagesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  type_in?: Maybe<Array<Maybe<MessageTypes>>>
}

/** A thread action */
export interface ThreadAction {
  __typename?: 'ThreadAction',
  type: ThreadActionTypes,
  uuid: Scalars['UUID'],
  /** An addressunit that might be associated with this action. */
  addressUnit?: Maybe<AddressUnit>,
  /** A flip that might be associated with this action. */
  flip?: Maybe<Flip>,
  /** A lease that might be associated with this action. */
  lease?: Maybe<PrivateLease>,
  /** A profile that might be associated with this action. */
  profile?: Maybe<FlipProfile>,
  /** A rent payment that might be associated with this action. */
  rentPayment?: Maybe<RentPayment>,
}

export enum ThreadActionTypes {
  ADD_CREDIT_SCORE = 'ADD_CREDIT_SCORE',
  ADD_EMPLOYMENT = 'ADD_EMPLOYMENT',
  REQUEST_BOOKING = 'REQUEST_BOOKING',
  SETUP_VIEWINGS = 'SETUP_VIEWINGS'
}

/** A connection to a list of items. */
export interface ThreadConnection {
  __typename?: 'ThreadConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ThreadEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface ThreadEdge {
  __typename?: 'ThreadEdge',
  /** The item at the end of the edge */
  node?: Maybe<Thread>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}


export interface TimeSlot {
  __typename?: 'TimeSlot',
  dayOfWeek: Scalars['Int'],
  endsAt: Scalars['Time'],
  eligibleEntityTypes?: Maybe<Array<Maybe<CalendarTimeSlotEntityTypes>>>,
  startsAt: Scalars['Time'],
  uuid: Scalars['UUID'],
}

export interface TopupBalanceInput {
  amountInCents?: Maybe<Scalars['UUID']>,
  defaultTopupSourceId: Scalars['String'],
}

export interface Transaction {
  __typename?: 'Transaction',
  amountInCents?: Maybe<Scalars['Int']>,
  availableAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
  currency?: Maybe<Scalars['String']>,
  entityType?: Maybe<Scalars['String']>,
  expiredAt?: Maybe<Scalars['Date']>,
  note?: Maybe<Scalars['String']>,
  processorFeeInCents?: Maybe<Scalars['Int']>,
  referenceId?: Maybe<Scalars['String']>,
  refundedAt?: Maybe<Scalars['Date']>,
  reversedAt?: Maybe<Scalars['Date']>,
  status?: Maybe<TransactionStatuses>,
  succeededAt?: Maybe<Scalars['Date']>,
  type?: Maybe<TransactionTypes>,
  uuid: Scalars['UUID'],
  /** The account associated with this transaction */
  account?: Maybe<Account>,
  /** The claim associated with this transaction */
  claim?: Maybe<ClaimType>,
  /** The credit report associated with this transaction */
  creditReport?: Maybe<PrivateCreditReport>,
  /** The invoice associated with this transaction */
  invoice?: Maybe<PrivateCreditReport>,
  /** The issued card associated with this transaction */
  issuedCard?: Maybe<IssuedCard>,
  /** The listing boost associated with this transaction */
  listingBoost?: Maybe<ListingBoost>,
  /** The payout transaction associated with this transaction */
  payout?: Maybe<Transaction>,
  processorCharge?: Maybe<ChargeType>,
  processorPayout?: Maybe<PayoutType>,
  processorRefund?: Maybe<RefundType>,
  processorTransfer?: Maybe<TransferType>,
  /** The refund transactions associated with this transaction */
  refunds?: Maybe<Array<Maybe<Transaction>>>,
  /** The reversal transactions associated with this transaction */
  reversals?: Maybe<Array<Maybe<Transaction>>>,
  /** The rent payment associated with this transaction */
  rentPayment?: Maybe<RentPayment>,
  /** The security deposit associated with this transaction */
  securityDeposit?: Maybe<SecurityDepositType>,
  /** The service payment associated with this transaction */
  serviceRequest?: Maybe<ServiceRequest>,
  /** The user associated with this transaction */
  user?: Maybe<FlipProfile>,
}

/** A connection to a list of items. */
export interface TransactionConnection {
  __typename?: 'TransactionConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TransactionEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface TransactionEdge {
  __typename?: 'TransactionEdge',
  /** The item at the end of the edge */
  node?: Maybe<Transaction>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface TransactionEntityTypeInput {
  eq?: Maybe<TransactionEntityTypes>,
  in?: Maybe<Array<TransactionEntityTypes>>,
}

/** Entity types of a transaction */
export enum TransactionEntityTypes {
  CLAIM = 'CLAIM',
  CREDIT_REPORT = 'CREDIT_REPORT',
  DEPOSIT = 'DEPOSIT',
  INVOICE = 'INVOICE',
  ISSUED_CARD = 'ISSUED_CARD',
  LISTING_BOOST = 'LISTING_BOOST',
  PROPERTY_MANAGER_CONTRACT = 'PROPERTY_MANAGER_CONTRACT',
  RENT_PAYMENT = 'RENT_PAYMENT',
  SECURITY_DEPOSIT = 'SECURITY_DEPOSIT',
  SERVICE_REQUEST = 'SERVICE_REQUEST',
  STANDALONE_RENT_CHECK = 'STANDALONE_RENT_CHECK',
  TAKEOVER = 'TAKEOVER',
  VERIFICATION = 'VERIFICATION'
}

export interface TransactionsFilterInput {
  createdAt?: Maybe<DateInputType>,
  entityType?: Maybe<TransactionEntityTypeInput>,
  status?: Maybe<TransactionStatusInput>,
  succeededAt?: Maybe<DateInputType>,
  type?: Maybe<TransactionTypeInput>,
}

/** Statuses of a transaction */
export enum TransactionStatuses {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  CANCELED = 'CANCELED',
  IN_TRANSIT = 'IN_TRANSIT',
  PAID = 'PAID'
}

export interface TransactionStatusInput {
  eq?: Maybe<TransactionStatuses>,
  in?: Maybe<Array<TransactionStatuses>>,
}

export interface TransactionTypeInput {
  eq?: Maybe<TransactionTypes>,
  in?: Maybe<Array<TransactionTypes>>,
}

/** Types of a transaction */
export enum TransactionTypes {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  PAYOUT = 'PAYOUT',
  REFUND = 'REFUND',
  REVERSAL = 'REVERSAL'
}

/** A Stripe transfer */
export interface TransferType {
  __typename?: 'TransferType',
  id?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  amountReversed?: Maybe<Scalars['Int']>,
  created?: Maybe<Scalars['Date']>,
  currency?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  reversed?: Maybe<Scalars['Boolean']>,
}

export interface UpdateAccessInstructionInput {
  accessorEntityType?: Maybe<AccessInstructionAccessorEntityTypes>,
  accessorEntityUuid?: Maybe<Scalars['UUID']>,
  notes?: Maybe<Scalars['String']>,
}

export interface UpdateAccountAddressUnitInputType {
  addressUuid: Scalars['UUID'],
  unitName?: Maybe<Scalars['String']>,
  tag?: Maybe<Scalars['String']>,
}

export interface UpdateAccountInputType {
  bio?: Maybe<Scalars['String']>,
  dateOfBirth?: Maybe<Scalars['Date']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  hasSignedFcraAgreement?: Maybe<Scalars['Boolean']>,
  lastIosNotificationPrompt?: Maybe<Scalars['Date']>,
  lastName?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  photoUrl?: Maybe<Scalars['String']>,
  preferredTransportationMethod?: Maybe<AccountTransportationMethods>,
}

export interface UpdateAccountSettingsInputType {
  receiveEmailNotifications?: Maybe<Scalars['Boolean']>,
  receiveSmsNotifications?: Maybe<Scalars['Boolean']>,
}

export interface UpdateAddressUnitInput {
  unitType?: Maybe<AddressUnitUnitTypes>,
}

export interface UpdateBalanceInput {
  defaultTopupSourceId?: Maybe<Scalars['String']>,
}

export interface UpdateClaimInputType {
  /** The amount being claimed. */
  amountInCents?: Maybe<Scalars['Int']>,
  /** A description of what this claim is for. */
  description?: Maybe<Scalars['String']>,
}

export interface UpdateConditionReportInput {
  description?: Maybe<Scalars['String']>,
  reportType?: Maybe<ConditionReportTypes>,
  requiredServiceType: ConditionReportRequiredServiceTypes,
}

export interface UpdateDepositForFlipInputType {
  depositInCents: Scalars['Int'],
}

export interface UpdateDeviceInputType {
  deviceToken?: Maybe<Scalars['String']>,
  identifier?: Maybe<Scalars['String']>,
  type?: Maybe<DeviceTypes>,
}

export interface UpdateDocumentInputType {
  displayName?: Maybe<Scalars['String']>,
  filename?: Maybe<Scalars['String']>,
}

export interface UpdateIncomeSourceInput {
  active?: Maybe<Scalars['Boolean']>,
  annualIncome?: Maybe<Scalars['Float']>,
  bonus?: Maybe<Scalars['Float']>,
  displayCompany?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  days?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  type?: Maybe<Scalars['String']>,
}

export interface UpdateLeaseInputType {
  /** Context for check payments */
  checksContext?: Maybe<ChecksContextInputType>,
  /** The uuid of the address unit that checks should be made out to */
  checksPayableToAddressUnitUuid?: Maybe<Scalars['UUID']>,
  /** The delta in days between charge day and due date */
  deltaPaymentDaysFromDueDate?: Maybe<Scalars['Int']>,
  /** The uuid of a custom document */
  documentUuid?: Maybe<Scalars['UUID']>,
  /** The lease end date */
  endDate?: Maybe<Scalars['Date']>,
  /** The rent amount in cents */
  rentInCents?: Maybe<Scalars['Int']>,
  /** The security deposit amount in cents */
  securityDepositInCents?: Maybe<Scalars['Int']>,
  /** The lease start date */
  startDate?: Maybe<Scalars['Date']>,
  /** The upfront rent amount in cents */
  upfrontRentInCents?: Maybe<Scalars['Int']>,
}

export interface UpdateLeasesSettingInput {
  isAutopayRequired?: Maybe<Scalars['Boolean']>,
}

export interface UpdateListingAnswerInput {
  text: Scalars['String'],
}

export interface UpdateListingInputType {
  addressUnitUuid?: Maybe<Scalars['UUID']>,
  apartmentNumber?: Maybe<Scalars['String']>,
  areaCode?: Maybe<Scalars['String']>,
  bathrooms?: Maybe<Scalars['Float']>,
  bedrooms?: Maybe<Scalars['Int']>,
  city?: Maybe<Scalars['String']>,
  defaultSecurityDepositInCents?: Maybe<Scalars['Int']>,
  defaultUpfrontRentInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  depositInCents?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  directContact?: Maybe<Scalars['Boolean']>,
  earliestMoveInDate?: Maybe<Scalars['Date']>,
  /** Used for Caretaker team members to manually add extended viewing notes */
  extendedViewingNotes?: Maybe<Scalars['String']>,
  furnishingType?: Maybe<ListingFurnishingTypes>,
  /** Used for Caretaker team members to manually review listings with high quality photos */
  hasQualityPhotos?: Maybe<Scalars['Boolean']>,
  headline?: Maybe<Scalars['String']>,
  isLeaseExtendableWithLandlord?: Maybe<Scalars['Boolean']>,
  /** DEPRECATED: Dead. */
  isRepostable?: Maybe<Scalars['Boolean']>,
  /** Used for Caretaker team members to manually associate a keycafe key */
  keycafeKeyId?: Maybe<Scalars['Int']>,
  latitude?: Maybe<Scalars['Float']>,
  leaseEndDate?: Maybe<Scalars['Date']>,
  leaseType?: Maybe<ListingLeaseTypes>,
  listingType?: Maybe<Scalars['String']>,
  longitude?: Maybe<Scalars['Float']>,
  /** DEPRECATED: Dead. */
  managerEmail?: Maybe<Scalars['String']>,
  maxLeaseTerm?: Maybe<Scalars['Int']>,
  minLeaseTerm?: Maybe<Scalars['Int']>,
  neighborhood?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  priceInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  pricingType?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  propertyType?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  referralEmail?: Maybe<Scalars['String']>,
  rentInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Dead. */
  requiresDeposit?: Maybe<Scalars['Boolean']>,
  /** DEPRECATED: Dead. */
  rewardInCents?: Maybe<Scalars['Int']>,
  /** DEPRECATED: Moved to `defaultSecurityDepositInCents`. */
  securityDepositInCents?: Maybe<Scalars['Int']>,
  shouldRequestLandlordApproval?: Maybe<Scalars['Boolean']>,
  state?: Maybe<Scalars['String']>,
  streetAddress?: Maybe<Scalars['String']>,
  /** DEPRECATED: Dead. */
  subleaseReviewedAt?: Maybe<Scalars['Date']>,
  totalRooms?: Maybe<Scalars['Int']>,
  /** Used for Caretaker team members to manually add viewing notes */
  viewingNotes?: Maybe<Scalars['String']>,
  amenityUuids?: Maybe<Array<Scalars['UUID']>>,
  archivedAt?: Maybe<Scalars['Date']>,
  photos?: Maybe<Array<Maybe<ListingPhotoInputType>>>,
}

export interface UpdateListingQuestionInput {
  prompt: Scalars['String'],
}

export interface UpdateMoveInput {
  startsAt?: Maybe<Scalars['Date']>,
}

export interface UpdateOrganizationInput {
  email?: Maybe<Scalars['String']>,
  hqAddressUnitUuid?: Maybe<Scalars['UUID']>,
  name?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
  logoPhotoUuid?: Maybe<Scalars['String']>,
}

export interface UpdateOrganizationPersonInput {
  title?: Maybe<Scalars['String']>,
  workAddressUnitUuid?: Maybe<Scalars['UUID']>,
}

export interface UpdatePaymentScheduleInput {
  paymentMethodId: Scalars['String'],
}

export interface UpdatePaymentSubscriptionInput {
  paymentMethodId?: Maybe<Scalars['String']>,
  plan?: Maybe<PaymentSubscriptionPlans>,
  quantity?: Maybe<Scalars['Int']>,
}

export interface UpdatePersonInput {
  bio?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
}

export interface UpdatePhotoInputType {
  adjustment?: Maybe<PhotoAdjustmentInputType>,
  height?: Maybe<Scalars['Float']>,
  isPrimary?: Maybe<Scalars['Boolean']>,
  tagUuids?: Maybe<Array<Scalars['UUID']>>,
  position?: Maybe<Scalars['Int']>,
  width?: Maybe<Scalars['Float']>,
}

export interface UpdatePropertyManagerContractInput {
  isSubManagementContract?: Maybe<Scalars['Boolean']>,
  parentPropertyManagerContractUuid?: Maybe<Scalars['UUID']>,
  primaryContactOrganizationPersonUuid?: Maybe<Scalars['UUID']>,
}

export interface UpdateRentalPolicyInputType {
  isAllowed?: Maybe<Scalars['Boolean']>,
  isRecommended?: Maybe<Scalars['Boolean']>,
}

export interface UpdateReviewInputType {
  headline?: Maybe<Scalars['String']>,
  rating?: Maybe<Scalars['Float']>,
  text?: Maybe<Scalars['String']>,
}

export interface UpdateSavedSearchSubscriptionType {
  uuid?: Maybe<Scalars['String']>,
  isSubscribed: Scalars['Boolean'],
  shouldSendDailyEmail?: Maybe<Scalars['Boolean']>,
  shouldSendPushNotifications?: Maybe<Scalars['Boolean']>,
}

export interface UpdateSavedSearchType {
  isSubscribed: Scalars['Boolean'],
  shouldSendDailyEmail?: Maybe<Scalars['Boolean']>,
  shouldSendPushNotifications?: Maybe<Scalars['Boolean']>,
}

export interface UpdateSecurityDepositForFlipInputType {
  securityDepositAmountInCents: Scalars['Int'],
}

export interface UpdateServicePreferenceInput {
  notes?: Maybe<Scalars['String']>,
  propertyManagerContractUuids?: Maybe<Array<Maybe<Scalars['UUID']>>>,
  serviceType?: Maybe<ServiceTypes>,
}

export interface UpdateServiceProviderInput {
  serviceType?: Maybe<ServiceTypes>,
  /** The bounding box that this service provider services */
  serviceAreaBoundingBox?: Maybe<GeoPolygonInput>,
}

export interface UpdateSpendingThresholdInput {
  issueThresholdType?: Maybe<SpendingThresholdTypes>,
  issueThresholdAmount?: Maybe<Scalars['Int']>,
  annualThresholdType?: Maybe<SpendingThresholdTypes>,
  annualThresholdAmount?: Maybe<Scalars['Int']>,
}

export interface UpdateTakeoverInputType {
  keyboxLockShippingAddressUnitUuid?: Maybe<Scalars['UUID']>,
  leaseContactInfoContext?: Maybe<TakeoverLeaseContactInfoContextInputType>,
  logisticsContext?: Maybe<TakeoverLogisticsContextInputType>,
  startsAt?: Maybe<Scalars['Date']>,
  tenantMovesOutAt?: Maybe<Scalars['Date']>,
  unitContext?: Maybe<TakeoverUnitContextInputType>,
  viewingContext?: Maybe<TakeoverViewingContextInputType>,
}

export interface UpdateUserAddressUnitInputType {
  addressUuid: Scalars['UUID'],
  unitName?: Maybe<Scalars['String']>,
  tag?: Maybe<Scalars['String']>,
}

export interface UpdateUserInputType {
  address?: Maybe<Scalars['String']>,
  apartmentNumber?: Maybe<Scalars['String']>,
  areaCode?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  dateOfBirth?: Maybe<Scalars['Date']>,
  defaultPayoutPlatform?: Maybe<Scalars['String']>,
  educationLevel?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  hasSignedFcraAgreement?: Maybe<Scalars['Boolean']>,
  lastIosNotificationPrompt?: Maybe<Scalars['Date']>,
  lastName?: Maybe<Scalars['String']>,
  needsGuarantor?: Maybe<Scalars['Boolean']>,
  phoneNumber?: Maybe<Scalars['String']>,
  photoUrl?: Maybe<Scalars['String']>,
  preferredTransportationMethod?: Maybe<AccountTransportationMethods>,
  residencePayment?: Maybe<Scalars['Int']>,
  residenceStatus?: Maybe<Scalars['String']>,
  smokes?: Maybe<Scalars['Boolean']>,
  state?: Maybe<Scalars['String']>,
  totalDependants?: Maybe<Scalars['Int']>,
  totalSavingsInvestments?: Maybe<Scalars['Int']>,
  yearsRenting?: Maybe<Scalars['Int']>,
}

export interface UpdateUserSettingsInputType {
  receiveEmailNotifications?: Maybe<Scalars['Boolean']>,
  receiveSmsNotifications?: Maybe<Scalars['Boolean']>,
}

export interface UpdateVoteInputType {
  /** Sentiment associated to the entity */
  sentiment: Scalars['Int'],
}

/** A user upload */
export interface Upload {
  __typename?: 'Upload',
  createdAt: Scalars['Date'],
  filename?: Maybe<Scalars['String']>,
  fileType?: Maybe<Scalars['String']>,
  tag: UploadTags,
  updatedAt: Scalars['Date'],
  url: Scalars['String'],
  uuid: Scalars['UUID'],
  /** The document type of this upload */
  documentType?: Maybe<DocumentType>,
}

/** A connection to a list of items. */
export interface UploadConnection {
  __typename?: 'UploadConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UploadEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface UploadEdge {
  __typename?: 'UploadEdge',
  /** The item at the end of the edge */
  node?: Maybe<Upload>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** Possible tags for an upload */
export enum UploadTags {
  BANK_STATEMENT = 'BANK_STATEMENT',
  CLAIM_EVIDENCE = 'CLAIM_EVIDENCE',
  CUSTOM = 'CUSTOM',
  MASTER_LEASE = 'MASTER_LEASE',
  OFFER_LETTER = 'OFFER_LETTER',
  PAYSTUB = 'PAYSTUB',
  RENTAL_FORM = 'RENTAL_FORM',
  TAKEOVER_AGREEMENTT = 'TAKEOVER_AGREEMENTT',
  TAX_RETURN = 'TAX_RETURN'
}

/** A utility */
export interface Utility {
  __typename?: 'Utility',
  name: Scalars['String'],
  uuid: Scalars['UUID'],
}

/** A utility account */
export interface UtilityAccount {
  __typename?: 'UtilityAccount',
  uuid: Scalars['UUID'],
  /** The account that owns this utility account */
  account?: Maybe<Account>,
  /** The platform account associated with this utility account */
  platformAccount?: Maybe<PlatformAccount>,
  /** The utility provider for this utility account */
  utilityProvider?: Maybe<UtilityProvider>,
}

/** A utility connection */
export interface UtilityConnection {
  __typename?: 'UtilityConnection',
  uuid: Scalars['UUID'],
  /** The building associated with this utility connection */
  building?: Maybe<Address>,
  /** The utility for this utility connection */
  utility?: Maybe<Utility>,
  /** The utility provider for this utility connection */
  utilityProvider?: Maybe<UtilityProvider>,
}

/** A utility */
export interface UtilityInputType {
  /** Utility name */
  name: Scalars['String'],
  /** Person responsible for paying utility */
  payee: Scalars['String'],
  amountInCents: Scalars['Int'],
}

/** A utility provider */
export interface UtilityProvider {
  __typename?: 'UtilityProvider',
  uuid: Scalars['UUID'],
  /** The organization details for the utility provider */
  organization?: Maybe<Organization>,
  /** The utilities this utility provider provides */
  utilities?: Maybe<Array<Maybe<Utility>>>,
}

/** A connection to a list of items. */
export interface UtilityProviderConnection {
  __typename?: 'UtilityProviderConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UtilityProviderEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface UtilityProviderEdge {
  __typename?: 'UtilityProviderEdge',
  /** The item at the end of the edge */
  node?: Maybe<UtilityProvider>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface UtilityProvidersFilterType {
  createdAt?: Maybe<DateInputType>,
  name?: Maybe<SearchStringInput>,
  smart?: Maybe<SearchStringInput>,
  updatedAt?: Maybe<DateInputType>,
}

export enum UtilityProvidersSortByEnum {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export enum UtilityProvidersSortDirectionEnum {
  /** Ascending */
  ASC = 'ASC',
  /** Descending */
  DESC = 'DESC'
}

/** A utility service */
export interface UtilityService {
  __typename?: 'UtilityService',
  endsAt?: Maybe<Scalars['Date']>,
  startsAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The address unit associated with this utility service */
  addressUnit?: Maybe<AddressUnit>,
  /** The utility account that owns this utility service */
  utilityAccount?: Maybe<UtilityAccount>,
  /** The utility connection for this utility service */
  utilityConnection?: Maybe<UtilityConnection>,
}


export interface UuidInput {
  eq?: Maybe<Scalars['UUID']>,
  in?: Maybe<Array<Scalars['UUID']>>,
}

export interface ValidatePropertyManagerContractEligibilityInput {
  addressUnitUuid: Scalars['UUID'],
  endsAt?: Maybe<Scalars['Date']>,
  propertyManagerUuid: Scalars['UUID'],
  startsAt?: Maybe<Scalars['Date']>,
}

/** A verification */
export interface Verification {
  __typename?: 'Verification',
  createdAt?: Maybe<Scalars['Date']>,
  failedAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  verifiedAt?: Maybe<Scalars['Date']>,
  verifiedEntityType?: Maybe<Scalars['String']>,
  /** The verification method used in this verification */
  verificationMethod?: Maybe<VerificationMethod>,
  verificationRequests?: Maybe<Array<Maybe<VerificationRequest>>>,
}

/** A connection to a list of items. */
export interface VerificationConnection {
  __typename?: 'VerificationConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VerificationEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface VerificationEdge {
  __typename?: 'VerificationEdge',
  /** The item at the end of the edge */
  node?: Maybe<Verification>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

/** A verification method */
export interface VerificationMethod {
  __typename?: 'VerificationMethod',
  createdAt: Scalars['Date'],
  description?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  slug: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  /** The document types needed for this verification method */
  documentTypes?: Maybe<Array<Maybe<VerificationMethodDocumentType>>>,
}

/** A connection to a list of items. */
export interface VerificationMethodConnection {
  __typename?: 'VerificationMethodConnection',
  /** Information to aid in pagination. */
  pageInfo: PageInfo,
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VerificationMethodEdge>>>,
  total?: Maybe<Scalars['Int']>,
}

/** A document type for a verification method */
export interface VerificationMethodDocumentType {
  __typename?: 'VerificationMethodDocumentType',
  createdAt: Scalars['Date'],
  description?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  slug: Scalars['String'],
  updatedAt: Scalars['Date'],
  uuid: Scalars['UUID'],
  requiredCount?: Maybe<Scalars['Int']>,
}

/** An edge in a connection. */
export interface VerificationMethodEdge {
  __typename?: 'VerificationMethodEdge',
  /** The item at the end of the edge */
  node?: Maybe<VerificationMethod>,
  /** A cursor for use in pagination */
  cursor: Scalars['String'],
}

export interface VerificationMethodsFilterType {
  createdAt?: Maybe<DateInputType>,
  forQualificationType?: Maybe<QualificationTypes>,
  name?: Maybe<SearchStringInput>,
  smart?: Maybe<SearchStringInput>,
  updatedAt?: Maybe<DateInputType>,
}

export enum VerificationMethodsSortByEnum {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum VerificationMethodsSortDirectionEnum {
  /** Ascending */
  ASC = 'ASC',
  /** Descending */
  DESC = 'DESC'
}

export interface VerificationRequest {
  __typename?: 'VerificationRequest',
  failedAt?: Maybe<Scalars['Date']>,
  processingAt?: Maybe<Scalars['Date']>,
  processor: VerificationRequestProcessors,
  succeededAt?: Maybe<Scalars['Date']>,
  token: Scalars['String'],
  uuid: Scalars['UUID'],
  nextAction?: Maybe<NextActionType>,
  url?: Maybe<Scalars['String']>,
}

export enum VerificationRequestProcessors {
  BLOCKSCORE = 'BLOCKSCORE',
  LOB = 'LOB',
  ONFIDO = 'ONFIDO',
  STRIPE = 'STRIPE'
}

export interface VerificationsFilterInput {
  verifiedEntityType?: Maybe<VerificationVerifiedEntityTypes>,
}

/** Possible entity types that can be verified */
export enum VerificationVerifiedEntityTypes {
  ACCOUNT = 'ACCOUNT',
  LISTING = 'LISTING'
}

/** The logged in account. */
export interface Viewer {
  __typename?: 'Viewer',
  activatedAt?: Maybe<Scalars['Date']>,
  activationToken?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  apartmentNumber?: Maybe<Scalars['String']>,
  apiToken?: Maybe<Scalars['String']>,
  approvedRent?: Maybe<Scalars['Int']>,
  areaCode?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  completedPrequal?: Maybe<Scalars['Boolean']>,
  confirmedTerms?: Maybe<Scalars['Boolean']>,
  createdAt?: Maybe<Scalars['Date']>,
  dateActivated?: Maybe<Scalars['Date']>,
  dateJoined?: Maybe<Scalars['Date']>,
  dateOfBirth?: Maybe<Scalars['Date']>,
  dateOfBirthString?: Maybe<Scalars['String']>,
  defaultPayoutPlatform?: Maybe<Scalars['String']>,
  defaultPhotoUrl?: Maybe<Scalars['String']>,
  educationLevel?: Maybe<Scalars['String']>,
  educationLevelString?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  facebookId?: Maybe<Scalars['String']>,
  facebookToken?: Maybe<Scalars['String']>,
  facebookTokenExpiration?: Maybe<Scalars['Date']>,
  firstName?: Maybe<Scalars['String']>,
  formattedPhoneNumber?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  group?: Maybe<Scalars['Int']>,
  hasConnectedFacebook?: Maybe<Scalars['Boolean']>,
  hasConnectedLinkedIn?: Maybe<Scalars['Boolean']>,
  hasConnectedPlaid?: Maybe<Scalars['Boolean']>,
  hasConnectedStripe?: Maybe<Scalars['Boolean']>,
  hasEmail?: Maybe<Scalars['Boolean']>,
  hasEsigningAvailable?: Maybe<Scalars['Boolean']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  hasPhoneNumber?: Maybe<Scalars['Boolean']>,
  hasSignedFcraAgreement?: Maybe<Scalars['Boolean']>,
  identityVerificationStatus?: Maybe<AccountIdentityVerificationStatuses>,
  isActive?: Maybe<Scalars['Boolean']>,
  isEmailVerified?: Maybe<Scalars['Boolean']>,
  isPhoneNumberVerified?: Maybe<Scalars['Boolean']>,
  isPrequalified?: Maybe<Scalars['Boolean']>,
  isStripeVerified?: Maybe<Scalars['Boolean']>,
  lastIosNotificationPrompt?: Maybe<Scalars['Date']>,
  lastName?: Maybe<Scalars['String']>,
  lastSeen?: Maybe<Scalars['Date']>,
  linkedinId?: Maybe<Scalars['String']>,
  linkedinToken?: Maybe<Scalars['String']>,
  linkedinTokenExpiration?: Maybe<Scalars['Date']>,
  locationString?: Maybe<Scalars['String']>,
  needsGuarantor?: Maybe<Scalars['Boolean']>,
  onfidoApplicantId?: Maybe<Scalars['String']>,
  paypalEmail?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  photoUrl?: Maybe<Scalars['String']>,
  plaidLastSynced?: Maybe<Scalars['Date']>,
  preferredTransportationMethod?: Maybe<AccountTransportationMethods>,
  projectedYearlyIncome?: Maybe<Scalars['Int']>,
  pubnubAuthKey?: Maybe<Scalars['String']>,
  registeredAt?: Maybe<Scalars['Date']>,
  /** The rental policy account-level default for this user */
  rentalPolicy?: Maybe<RentalPolicy>,
  resetToken?: Maybe<Scalars['String']>,
  residenceStatus?: Maybe<Scalars['String']>,
  residenceStatusString?: Maybe<Scalars['String']>,
  role?: Maybe<Scalars['Int']>,
  score?: Maybe<Scalars['Int']>,
  smokes?: Maybe<Scalars['Boolean']>,
  state?: Maybe<Scalars['String']>,
  status: AccountStatuses,
  submittedPrequalAt?: Maybe<Scalars['Date']>,
  token?: Maybe<Scalars['String']>,
  totalDependants?: Maybe<Scalars['Int']>,
  totalSavingsInvestments?: Maybe<Scalars['Int']>,
  unreadConversations?: Maybe<Scalars['Int']>,
  unreadMessages?: Maybe<Scalars['Int']>,
  unreadNotificationCount?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  verifiedIncome?: Maybe<Scalars['Boolean']>,
  yearsRenting?: Maybe<Scalars['Int']>,
  accountAddressUnits?: Maybe<Array<Maybe<AccountAddressUnit>>>,
  accountSettings?: Maybe<AccountSettings>,
  activeCreditReport?: Maybe<PrivateCreditReport>,
  /** The active payment subscription for this viewer. */
  activePaymentSubscription?: Maybe<PaymentSubscription>,
  addressUnits?: Maybe<Array<Maybe<AccountAddressUnit>>>,
  application?: Maybe<PrivateApplication>,
  applications?: Maybe<PrivateApplicationConnection>,
  balance?: Maybe<Balance>,
  balanceTransaction?: Maybe<BalanceTransaction>,
  bookmark?: Maybe<Listing>,
  bookmarks?: Maybe<ListingConnection>,
  conditionInspection?: Maybe<ConditionInspection>,
  conditionReport?: Maybe<ConditionReport>,
  creditReport?: Maybe<PrivateCreditReport>,
  creditReports?: Maybe<Array<Maybe<PrivateCreditReport>>>,
  conversation?: Maybe<Conversation>,
  conversations?: Maybe<ConversationConnection>,
  devices?: Maybe<Array<Maybe<Device>>>,
  document?: Maybe<PrivateDocument>,
  documents?: Maybe<PrivateDocumentConnection>,
  flip?: Maybe<Flip>,
  flips?: Maybe<FlipConnection>,
  incomeSource?: Maybe<PrivateIncomeSource>,
  incomeSources?: Maybe<Array<Maybe<PrivateIncomeSource>>>,
  issue?: Maybe<Issue>,
  issues?: Maybe<IssueConnection>,
  issuedCardAuthorization?: Maybe<IssuedCardAuthorization>,
  lease?: Maybe<PrivateLease>,
  leases?: Maybe<PrivateLeaseConnection>,
  leaseInvite?: Maybe<PrivateLeaseInvite>,
  /** The account-level leases setting associated with this viewer. */
  leasesSetting?: Maybe<LeasesSetting>,
  listing?: Maybe<PrivateListing>,
  listings?: Maybe<PrivateListingConnection>,
  /** A single listing question. */
  listingQuestion?: Maybe<PrivateListingQuestion>,
  /** The full list of listing questions relevant to your account. */
  listingQuestions?: Maybe<PrivateListingQuestionConnection>,
  notification?: Maybe<Notification>,
  notifications?: Maybe<NotificationConnection>,
  notificationFeed?: Maybe<Array<Maybe<NotificationFeedItem>>>,
  readNotifications?: Maybe<NotificationConnection>,
  unreadNotifications?: Maybe<NotificationConnection>,
  order?: Maybe<PrivateOrder>,
  /** The full list of orders on this account. */
  orders?: Maybe<PrivateOrderConnection>,
  /** The organization associated with this viewer. */
  organization?: Maybe<PrivateOrganization>,
  paymentMethod?: Maybe<StripePaymentMethod>,
  paymentMethods?: Maybe<Array<Maybe<StripePaymentMethod>>>,
  /** Current payment subscription for this viewer. */
  paymentSubscription?: Maybe<PaymentSubscription>,
  /** All payment subscriptions for this viewer. */
  paymentSubscriptions?: Maybe<Array<Maybe<PaymentSubscription>>>,
  payoutAccount?: Maybe<StripeExternalAccount>,
  payoutAccounts?: Maybe<Array<Maybe<StripeExternalAccount>>>,
  photo?: Maybe<Photo>,
  propertyManagerContract?: Maybe<PrivatePropertyManagerContract>,
  propertyManagerContracts?: Maybe<PrivatePropertyManagerContractConnection>,
  referral?: Maybe<Referral>,
  referrals?: Maybe<ReferralConnection>,
  referrer?: Maybe<Referral>,
  rental?: Maybe<Flip>,
  rentals?: Maybe<FlipConnection>,
  rentPayment?: Maybe<RentPayment>,
  /** A list of rent payments associated with this viewer. */
  rentPayments?: Maybe<RentPaymentConnection>,
  review?: Maybe<Review>,
  reviews?: Maybe<ReviewConnection>,
  /** The account-level maintenance setting associated with this viewer. */
  maintenanceSetting?: Maybe<MaintenanceSetting>,
  /** A saved searches associated with this viewer. */
  savedSearch?: Maybe<SavedSearch>,
  /** A list of saved searches associated with this viewer. */
  savedSearches?: Maybe<Array<Maybe<SavedSearch>>>,
  manageSearchables?: Maybe<SearchableConnection>,
  securityDeposit?: Maybe<SecurityDepositType>,
  securityDeposits?: Maybe<SecurityDepositTypeConnection>,
  /** Service dispatches associated with this viewer. */
  serviceDispatch?: Maybe<ServiceDispatch>,
  /** Service fulfillments associated with this viewer. */
  serviceFulfillment?: Maybe<ServiceFulfillment>,
  /** Service preference for this viewer. */
  servicePreference?: Maybe<ServicePreference>,
  /** Service preferences for this viewer. */
  servicePreferences?: Maybe<Array<Maybe<ServicePreference>>>,
  stripeCustomerAccount?: Maybe<StripeCustomer>,
  stripeConnectAccount?: Maybe<StripeConnectAccount>,
  stripeConnectAccountBalance?: Maybe<StripeBalance>,
  /** A single takeover associated with this viewer. */
  takeover?: Maybe<Takeover>,
  /** Takeovers associated with this viewer. */
  takeovers?: Maybe<TakeoverConnection>,
  /** A single takeover proposal associated with this viewer. */
  takeoverProposal?: Maybe<TakeoverProposal>,
  thread?: Maybe<Thread>,
  threads?: Maybe<ThreadConnection>,
  /** A single takeover request associated with this viewer. */
  takeoverRequest?: Maybe<TakeoverRequest>,
  /** A single transaction associated with this viewer. */
  transaction?: Maybe<Transaction>,
  /** A list of transactions associated with this viewer. */
  transactions?: Maybe<TransactionConnection>,
  upload?: Maybe<Upload>,
  uploads?: Maybe<UploadConnection>,
  userAddressUnits?: Maybe<Array<Maybe<AccountAddressUnit>>>,
  userSettings?: Maybe<AccountSettings>,
  verifications?: Maybe<VerificationConnection>,
  /** A single viewing associated with a listing owned by this viewer. */
  viewing?: Maybe<PrivateViewing>,
  /** All viewings for all listings owned by this viewer. */
  viewings?: Maybe<PrivateViewingConnection>,
}


/** The logged in account. */
export interface ViewerApplicationArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerApplicationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ApplicationsFilterInput>
}


/** The logged in account. */
export interface ViewerBalanceTransactionArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerBookmarkArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerBookmarksArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerConditionInspectionArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerConditionReportArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerCreditReportArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerConversationArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerConversationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerDocumentArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerDocumentsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerFlipArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerFlipsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  isArchived?: Maybe<Scalars['Boolean']>,
  status_in?: Maybe<Array<Maybe<FlipStatuses>>>
}


/** The logged in account. */
export interface ViewerIncomeSourceArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerIssueArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerIssuesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<IssuesFilterInput>
}


/** The logged in account. */
export interface ViewerIssuedCardAuthorizationArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerLeaseArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerLeasesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<LeasesFilterInput>,
  isCurrent?: Maybe<Scalars['Boolean']>,
  leaseType?: Maybe<LeaseTypes>
}


/** The logged in account. */
export interface ViewerLeaseInviteArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerListingArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  status_in?: Maybe<Array<Maybe<ListingPrivateStatuses>>>,
  filter?: Maybe<PrivateListingsFilterInput>
}


/** The logged in account. */
export interface ViewerListingQuestionArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerListingQuestionsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ListingQuestionsFilterInput>
}


/** The logged in account. */
export interface ViewerNotificationArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerNotificationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  isUnread?: Maybe<Scalars['Boolean']>,
  type_in?: Maybe<Array<Maybe<NotificationTypes>>>
}


/** The logged in account. */
export interface ViewerReadNotificationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerUnreadNotificationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerOrderArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerOrdersArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<OrdersFilterInput>
}


/** The logged in account. */
export interface ViewerPaymentMethodArgs {
  paymentMethodId: Scalars['String']
}


/** The logged in account. */
export interface ViewerPayoutAccountArgs {
  accountId: Scalars['String']
}


/** The logged in account. */
export interface ViewerPropertyManagerContractArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerPropertyManagerContractsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<PrivatePropertyManagerContractsFilterInput>
}


/** The logged in account. */
export interface ViewerReferralArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerReferralsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerRentalArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerRentalsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  isArchived?: Maybe<Scalars['Boolean']>
}


/** The logged in account. */
export interface ViewerRentPaymentArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerRentPaymentsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<RentPaymentsFilterInput>
}


/** The logged in account. */
export interface ViewerReviewArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerSavedSearchArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerManageSearchablesArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<SearchableFilterInput>
}


/** The logged in account. */
export interface ViewerSecurityDepositArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerSecurityDepositsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerServiceDispatchArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerServiceFulfillmentArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerServicePreferenceArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerTakeoverArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerTakeoversArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  status_in?: Maybe<Array<Maybe<TakeoverStatuses>>>
}


/** The logged in account. */
export interface ViewerTakeoverProposalArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerThreadArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerThreadsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** The logged in account. */
export interface ViewerTakeoverRequestArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerTransactionArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerTransactionsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<TransactionsFilterInput>
}


/** The logged in account. */
export interface ViewerUploadArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerUploadsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  tag_in?: Maybe<Array<Maybe<UploadTags>>>
}


/** The logged in account. */
export interface ViewerVerificationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<VerificationsFilterInput>
}


/** The logged in account. */
export interface ViewerViewingArgs {
  uuid: Scalars['UUID']
}


/** The logged in account. */
export interface ViewerViewingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<ViewingsFilterInput>
}

export interface Viewing {
  __typename?: 'Viewing',
  createdAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
}

/** An account's viewing profile */
export interface Viewingaccount {
  __typename?: 'Viewingaccount',
  averageResponseTime?: Maybe<Scalars['Int']>,
  averageResponseTimeFormatted?: Maybe<Scalars['String']>,
  authorBio?: Maybe<Scalars['String']>,
  authorRole?: Maybe<Scalars['String']>,
  authorSocialLink?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['Date']>,
  defaultPhotoUrl?: Maybe<Scalars['String']>,
  educationLevel?: Maybe<Scalars['String']>,
  educationLevelString?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  hasConnectedFacebook?: Maybe<Scalars['Boolean']>,
  hasConnectedLinkedIn?: Maybe<Scalars['Boolean']>,
  hasEmail?: Maybe<Scalars['Boolean']>,
  hasPets?: Maybe<Scalars['Boolean']>,
  hasPhoneNumber?: Maybe<Scalars['Boolean']>,
  lastName?: Maybe<Scalars['String']>,
  lastSeen?: Maybe<Scalars['Date']>,
  locationString?: Maybe<Scalars['String']>,
  photoUrl?: Maybe<Scalars['String']>,
  smokes?: Maybe<Scalars['Boolean']>,
  uuid: Scalars['UUID'],
  viewerHasReviewed?: Maybe<Scalars['Boolean']>,
  yearsRenting?: Maybe<Scalars['Int']>,
  photo?: Maybe<Photo>,
  listings?: Maybe<ListingConnection>,
  /** The organization associated with this profile */
  organization?: Maybe<Organization>,
  /** A list of reviews for this user. */
  reviews?: Maybe<ReviewConnection>,
  activeCreditReport?: Maybe<ViewingCreditReport>,
  incomeSources?: Maybe<Array<Maybe<ViewingIncomeSource>>>,
  verifications?: Maybe<VerificationConnection>,
}


/** An account's viewing profile */
export interface ViewingaccountListingsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** An account's viewing profile */
export interface ViewingaccountReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}


/** An account's viewing profile */
export interface ViewingaccountVerificationsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter?: Maybe<VerificationsFilterInput>
}

export interface ViewingAddressUnit {
  __typename?: 'ViewingAddressUnit',
  createdAt?: Maybe<Scalars['Date']>,
  isRoot?: Maybe<Scalars['Boolean']>,
  name?: Maybe<Scalars['String']>,
  unitType?: Maybe<AddressUnitUnitTypes>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  accessInstruction?: Maybe<PrivateAccessInstruction>,
  /** The active landlord contract for this address unit */
  activeLandlordContract?: Maybe<LandlordContract>,
  /** The active property manager contracts for this address unit */
  activePropertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  address?: Maybe<Address>,
  /** The amenities of the address unit */
  addressUnitAmenities?: Maybe<Array<Maybe<AddressUnitAmenityType>>>,
  /** The rooms of the address unit */
  addressUnitRooms?: Maybe<Array<Maybe<AddressUnitRoom>>>,
  /** The building for this address unit */
  building?: Maybe<Building>,
  /** The calendar associated with this address unit */
  calendar?: Maybe<Calendar>,
  keybox?: Maybe<SmartLock>,
  smartLock?: Maybe<SmartLock>,
  /** The list of landlord contracts for this address unit */
  landlordContracts?: Maybe<Array<Maybe<LandlordContract>>>,
  /** The list of property manager contracts for this address unit */
  propertyManagerContracts?: Maybe<Array<Maybe<PropertyManagerContract>>>,
  /** A list of reviews for this unit. */
  reviews?: Maybe<ReviewConnection>,
  /** The current viewer's property manager contract for this address unit */
  viewerPropertyManagerContract?: Maybe<PropertyManagerContract>,
}


export interface ViewingAddressUnitReviewsArgs {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>
}

export interface ViewingCreditReport {
  __typename?: 'ViewingCreditReport',
  uuid: Scalars['UUID'],
  creditScore?: Maybe<Scalars['Int']>,
  hasCriminalRecords?: Maybe<Scalars['Boolean']>,
  hasEvictionRecords?: Maybe<Scalars['Boolean']>,
}

/** An income source */
export interface ViewingIncomeSource {
  __typename?: 'ViewingIncomeSource',
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  uuid: Scalars['UUID'],
  annualIncome?: Maybe<Scalars['Float']>,
  verifiedAt?: Maybe<Scalars['Date']>,
}

export interface ViewingRoleInput {
  eq?: Maybe<ViewingRoles>,
  in?: Maybe<Array<ViewingRoles>>,
}

/** Roles in the context of a viewing */
export enum ViewingRoles {
  HOST = 'HOST',
  VIEWER = 'VIEWER'
}

export interface ViewingsFilterInput {
  endsAt?: Maybe<DateInputType>,
  role?: Maybe<ViewingRoleInput>,
}

export interface ViewportType {
  __typename?: 'ViewportType',
  northeast?: Maybe<CoordinatePairType>,
  southwest?: Maybe<CoordinatePairType>,
}

export interface VoteEntityType {
  type: VoteEntityTypes,
  uuid: Scalars['UUID'],
}

export enum VoteEntityTypes {
  ADDRESSUNIT_AMENITY = 'ADDRESSUNIT_AMENITY',
  BUILDING_AMENITY = 'BUILDING_AMENITY',
  CONTENT_ANSWER = 'CONTENT_ANSWER'
}

/** Votes for an entity */
export interface VoteType {
  __typename?: 'VoteType',
  uuid: Scalars['UUID'],
  createdAt?: Maybe<Scalars['Date']>,
  updatedAt?: Maybe<Scalars['Date']>,
  /** The sentiment of this vote */
  sentiment?: Maybe<Scalars['Int']>,
  /** The entity this vote is related to */
  entityType?: Maybe<VoteEntityTypes>,
  /** The content answer this vote is related to */
  contentAnswer?: Maybe<ContentAnswer>,
}
