import { Component, Children } from 'react';
import PropTypes from 'prop-types';

export default class WithContext extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    onInjectScript: PropTypes.func,
  };

  static childContextTypes = {
    injectScript: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onInjectScript: () => {},
  };

  getChildContext() {
    return {
      injectScript: this.props.onInjectScript,
    };
  }

  render() {
    return Children.only(this.props.children);
  }
}
