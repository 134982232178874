import _ from 'lodash';

interface Globals {
  ADSENSE_CLIENT_ID: string;
  ALGOLIA: {
    APP_ID: string;
    KEY: string;
  };
  API_CORE_DOMAIN: string;
  API_PATH: string;
  BUGSNAG_KEY: string;
  CARD_PROCESSING_FEE_PERCENTAGE: number;
  CARD_PROCESSING_FLAT_FEE_IN_CENTS: number;
  COOKIE_DOMAIN: string;
  DOMAIN: string;
  ENV: string;
  FACEBOOK_APP_KEY: string;
  FACEBOOK_PIXEL_KEY: string;
  FRONT: {
    LANDLORD_CHAT_ID: string;
  };
  GOOGLE_PLACES_KEY: string;
  HELLOSIGN_CLIENT_ID: string;
  MAPBOX_KEY: string;
  NGROK_PATH: string;
  STRIPE_KEY: string;
  VERSION: string;
  WALKSCORE_KEY: string;
  WEB_ADMIN_DOMAIN: string;
  WEB_APARTMENT_DOMAIN: string;
  WEB_CORE_DOMAIN: string;
  WEB_MANAGE_DOMAIN: string;
}

const globals: Globals = {
  ADSENSE_CLIENT_ID: '',
  ALGOLIA: {
    APP_ID: '',
    KEY: '',
  },
  API_CORE_DOMAIN: '',
  API_PATH: '',
  BUGSNAG_KEY: '',
  CARD_PROCESSING_FEE_PERCENTAGE: 2.9,
  CARD_PROCESSING_FLAT_FEE_IN_CENTS: 30,
  COOKIE_DOMAIN: '',
  DOMAIN: '',
  ENV: '',
  FACEBOOK_APP_KEY: '',
  FACEBOOK_PIXEL_KEY: '',
  FRONT: {
    LANDLORD_CHAT_ID: '',
  },
  GOOGLE_PLACES_KEY: '',
  HELLOSIGN_CLIENT_ID: '',
  MAPBOX_KEY: '',
  NGROK_PATH: '',
  STRIPE_KEY: '',
  VERSION: '',
  WALKSCORE_KEY: '',
  WEB_ADMIN_DOMAIN: '',
  WEB_APARTMENT_DOMAIN: '',
  WEB_CORE_DOMAIN: '',
  WEB_MANAGE_DOMAIN: '',
};

// @ts-ignore This is Webpack magic, need to set it up with Typescript
if (CAN_USE_DOM) {
  _.merge(globals, (window as any).GLOBALS as Globals);
} else if (process.env.GLOBALS) {
  _.merge(globals, JSON.parse(process.env.GLOBALS));
}

export default globals;
