import _ from 'lodash';

export function compose(...funcs) {
  return _.flowRight(funcs);
}

// import _ from 'lodash';
// import { ComponentClass, ComponentType, ReactElement } from 'react';

// type GenericHOC<T> = (ComposableComponent: ComponentType<T>) => ((props: T) => ReactElement<T>) | ComponentClass<T>;

// export function compose<T>(...funcs: GenericHOC<any>[]) {
//   return _.flowRight(funcs) as ((ComposedComponent: ComponentType<any>) => ComponentType<T>);
// }
