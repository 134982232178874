import hoistStatics from 'hoist-non-react-statics';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import React, { Component, ComponentType } from 'react';
import isEqual from 'react-fast-compare';

function withStyles(...styles: { [className: string]: string }[]) {
  return function WithStylesHOC<T>(ComposedComponent: ComponentType<T>) {
    const displayName = `withStyles(${ComposedComponent.displayName || ComposedComponent.name || 'Component'})`;

    class WithStyles extends Component<T> {
      static displayName = displayName;
      static contextType = StyleContext;
      removeCss?: () => void;

      constructor(props: T, context) {
        super(props, context);
        this.removeCss = context.insertCss(...styles);
      }

      shouldComponentUpdate(nextProps: T) {
        if (this.props.children || (nextProps as any).children) return true;

        return !isEqual(this.props, nextProps);
      }

      componentWillUnmount() {
        if (this.removeCss) {
          setTimeout(this.removeCss, 0);
        }
      }

      render() {
        return <ComposedComponent {...this.props as T} />;
      }
    }

    return hoistStatics(WithStyles, ComposedComponent);
  };
}

export default withStyles;
