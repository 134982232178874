import { canUseDOM } from 'exenv';
import React from 'react';
import { Redirect as RouterRedirect } from 'react-router-dom';

interface Props {
  to: string;
}

function Redirect(props: Props) {
  if (props.to.indexOf('http') !== -1 && canUseDOM) {
    window.location.replace(props.to);
    return null;
  }

  return (
    <RouterRedirect to={props.to} />
  );
}

export default Redirect;
