import React, { Component, ReactNode } from 'react';

const defaultValue = () => 0;
const { Provider, Consumer } = React.createContext(defaultValue);

interface ProviderProps {
  children: ReactNode;
  getUniqueId: () => number;
  resetUniqueId?: () => void;
}

interface HolderProps {
  children: (value: number) => ReactNode;
  getUniqueId: (() => number) | null;
}

export class UniqueIdProvider extends Component<ProviderProps> {
  constructor(props: ProviderProps) {
    super(props);

    if (props.resetUniqueId) props.resetUniqueId();
  }

  render() {
    return (
      <Provider value={this.props.getUniqueId}>
        {this.props.children}
      </Provider>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
class IdHolder extends Component<HolderProps> {
  uniqueId: number;

  constructor(props: HolderProps) {
    super(props);

    this.uniqueId = props.getUniqueId ? props.getUniqueId() : 0;
  }

  render() {
    return this.props.children(this.uniqueId);
  }
}

export const UniqueIdConsumer = (props: {
  children: (value: number) => ReactNode;
}) => (
  <Consumer>
    {value => (
      <IdHolder getUniqueId={value}>
        {props.children}
      </IdHolder>
    )}
  </Consumer>
);
