// @flow

import React from 'react';
import bugsnagReact from '@bugsnag/plugin-react';

import Globals from '~web-apartment/lib/common/globals';

let client = {
  app: {},
  // eslint-disable-next-line no-unused-vars
  getPlugin: (arg?: string) => ({
    errorHandler: () => {},
    requestHandler: () => {},
  }),
  notify: (err: Error, context) => console.log(err), // eslint-disable-line
  // eslint-disable-next-line no-unused-vars
  use: (arg: *, arg2?: *) => {},
  user: {},
};
// $FlowIgnore - Global
if (!CAN_USE_DOM) {
  if (Globals.BUGSNAG_KEY) {
    const bugsnag = require('@bugsnag/js'); // eslint-disable-line global-require
    client = bugsnag({
      apiKey: Globals.BUGSNAG_KEY,
      appType: 'server',
      appVersion: Globals.VERSION,
      logger: null,
      releaseStage: Globals.ENV,
    });
  }
} else {
  client = window.bugsnagClient;
}

// Patch for Storybook
if (client) client.use(bugsnagReact, React);
// eslint-disable-next-line flowtype/no-weak-types
const exportedClient = (client: Object);
export default exportedClient;
