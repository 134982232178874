// @flow
import type { Node } from 'react';

import PropTypes from 'prop-types';
import { canUseDOM } from 'exenv';
import { Component } from 'react';

type Props = {
  userAgent?: string,
  children: Node,
};

export default class DeviceProvider extends Component<Props> {
  static childContextTypes = {
    userAgent: PropTypes.string.isRequired,
  };

  constructor(props: Props) {
    super(props);

    if (props.userAgent) {
      this.userAgent = props.userAgent;
    } else {
      this.userAgent = this.getUserAgent();
    }
  }

  getChildContext() {
    return {
      userAgent: this.userAgent,
    };
  }

  render() {
    return this.props.children;
  }

  getUserAgent = () => {
    if (canUseDOM) return window.navigator.userAgent;

    return '';
  };

  userAgent = '';
}
