// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._nrBWEZ{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}@media screen and (min-width:1127px){._nrBWEZ{min-height:100vh}}._nrBWEZ ._zaNxmG{height:100px;width:100%}", ""]);
// Exports
exports.locals = {
	"dashboard-stage-view": "_nrBWEZ",
	"dashboard-stage-view__header-placeholder": "_zaNxmG"
};
module.exports = exports;
