import hoistStatics from 'hoist-non-react-statics';
import React, { ComponentType } from 'react';
import { useCookies } from 'react-cookie';

export interface AuthProps {
  isLoggedIn: boolean;
}

function withAuth<T>(ComposedComponent: ComponentType<T & AuthProps>) {
  function WithAuth(props: T) {
    const [cookies] = useCookies(['token']);

    return (
      <ComposedComponent
        {...props}
        isLoggedIn={!!cookies.token}
      />
    );
  }

  WithAuth.ComposedComponent = ComposedComponent;
  WithAuth.displayName = `withAuth(${ComposedComponent.displayName || ComposedComponent.name || 'Component'})`;

  return hoistStatics(WithAuth, ComposedComponent);
}

export default withAuth;
