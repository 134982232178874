export enum Sizes {
  XLarge = 'XLARGE',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  XSmall = 'XSMALL',
}

export enum FlexBehaviors {
  Grow = 'GROW',
  None = 'NONE',
}

export enum Tags {
  Div = 'DIV',
  Section = 'SECTION',
}
