import _ from 'lodash';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Globals from '~web-core/lib/common/globals';

import { StripeAccountLinkTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import withCreateStripeAccountLink, { CreateStripeAccountLinkProps } from '~tools/react/graphql/mutations/accounts/withCreateStripeAccountLink';

import Redirect from '~tools/react/components/utility/Redirect';

import DashboardStage from '~web-apartment/lib/common/stages/DashboardStage';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';
import Spinner from '~web-apartment/lib/common/components/Spinner';
import Card from '~web-apartment/lib/common/components/Card';
import Center from '~tools/react/components/Center';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

type InputProps = RouteComponentProps<{ accountLinkType: string }>;

type Props =
  InputProps &
  AuthProps &
  CreateStripeAccountLinkProps;

interface State {
  accountLinkUrl?: string;
}

class StripeConnectRedirect extends PureComponent<Props, State> {
  state: State = {};

  async componentDidMount() {
    if (!this.props.isLoggedIn) return;

    const accountLinkUrl = await this.props.createStripeAccountLink({
      returnUrl: this.props.location.state && this.props.location.state.from ? `${Globals.WEB_APARTMENT_DOMAIN}${this.props.location.state.from}` : `${Globals.WEB_APARTMENT_DOMAIN}/account/payouts`,
      refreshUrl: `${Globals.WEB_APARTMENT_DOMAIN}/outbound/stripe/${this.props.match.params.accountLinkType}`,
      type: StripeAccountLinkTypes[`${_.toUpper(this.props.match.params.accountLinkType)}`],
    });

    this.setState({ accountLinkUrl });
  }

  render() {
    if (!this.props.isLoggedIn || !this.state.accountLinkUrl) {
      return (
        <DashboardStage>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
          <WidthConstraintView
            hasPaddingOnMobile={false}
            size={WidthConstraintView.enums.Sizes.Large}>
            <Card width={Card.enums.Widths.Full}>
              <Card.CardSection>
                <Center>
                  <Spinner />
                </Center>
              </Card.CardSection>
            </Card>
          </WidthConstraintView>
        </DashboardStage>
      );
    }

    return (
      <Redirect to={this.state.accountLinkUrl} />
    );
  }
}

export default compose(
  withAuth,
  withCreateStripeAccountLink,
)(StripeConnectRedirect);
