/* eslint-disable import/first */
import bugsnag from '~web-apartment/lib/common/utils/bugsnag';

if (window.APP_LOADED) throw new Error('App already loaded.');
window.APP_LOADED = true;

import React from 'react';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import isoFetch from 'isomorphic-fetch';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';

import Globals from '~web-apartment/lib/common/globals';

import routes from '~web-apartment/lib/common/routes/routes';

import DeviceProvider from '~web-apartment/lib/common/app/DeviceProvider';
import RouteRenderer from '~web-apartment/lib/common/routes/RouteRenderer';
import WithContext from '~web-apartment/lib/common/app/WithContext';
import { UniqueIdProvider } from '~tools/react/hocs/withUniqueId';

import ApolloProvider from './Apollo';

if (
  isoFetch &&
  Globals.ENV !== 'production' &&
  Globals.ENV !== 'staging') {
  console.log('Scripts loaded.');
}

const ErrorBoundary = bugsnag.getPlugin('react');

let uniqueId = 0;
const getUniqueId = () => {
  uniqueId += 1;
  return uniqueId;
};

let INITIAL_LOAD = true;
const insertCss = (...styles) => {
  if (INITIAL_LOAD) {
    return () => null;
  }

  const removeCss = styles.map(style => style._insertCss()); // eslint-disable-line no-underscore-dangle
  return () => removeCss.forEach(dispose => dispose());
};

loadableReady(async () => {
  await hydrate(
    <ErrorBoundary>
      <HelmetProvider>
        <ApolloProvider>
          <BrowserRouter>
            <WithContext>
              <DeviceProvider>
                <CookiesProvider>
                  <UniqueIdProvider getUniqueId={getUniqueId}>
                    <StyleContext.Provider value={{ insertCss }}>
                      <RouteRenderer routes={routes} />
                    </StyleContext.Provider>
                  </UniqueIdProvider>
                </CookiesProvider>
              </DeviceProvider>
            </WithContext>
          </BrowserRouter>
        </ApolloProvider>
      </HelmetProvider>
    </ErrorBoundary>,
    document.getElementById('react-app'),
  );
  INITIAL_LOAD = false;
});
